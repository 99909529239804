<!-- <div class="loginContainer">
  <div class="logoView">
    <div class="logo">
      <img src="assets/Images/Logo@2x.png"> 
    </div>
  </div>

  <div class="loginForm">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title text-center welcome">Welcome back!<br>Please login to your account.</h5>
        <form [formGroup]="LoginForm" class="login" (ngSubmit)="submit()">
          <p>
            <mat-form-field fxFlex>
              <input type="text" matInput placeholder="User" formControlName="user">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <input type="password" matInput placeholder="Password" formControlName="password">
            </mat-form-field>
          </p>
          <p>
            <label class="forgotpassword" (click)="showForgetPasswordMsg()">Forgot password</label>
          </p>
          <button type="submit" [disabled]="!LoginForm.valid" class="loginButton" mat-button>LOGIN</button>
        </form>
      </div>
    </div>
  </div>

</div> -->

<!-- New changes -->
<img
  src="assets/Images/logo.png"
  class="logo-Img"
  style="position: absolute; left: 5vw; top: 2vh"
/>

<div class="span12 bgClass">
  <div class="container-fluid">
    <div class="row my-auto">
      <section id="home">
        <div
          class="row align-items-center m-3 m-lg-5 m-xl-5"
          style="width: 100%"
        >
          <div
            id="foo1"
            class="col-sm-6 col-md-6 col-lg-6 col-xl-6 mx-auto my-auto"
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img src="assets/Images/logo-eft.png" width="20%" height="10%" />

            <!-- <h1
              style="
                white-space: pre-wrap;
                word-wrap: break-word;
                text-align: center;
                font-family: cursive;
                font-weight: bold;
                color: #131373;
                font-size: 60px;
              "
            >
              {{ "EFT" }}
            </h1> -->
            <br />
            <h4
              style="
                white-space: pre-wrap;
                word-wrap: break-word;
                text-align: center;
                font-family: cursive;
                font-weight: bold;
                color: #131373;
                font-size: 40px;
              "
            >
              {{ "Enhanced Framework for Testing" }}
            </h4>
            <!-- <img src="assets/Images/Logo@2x.png" class=" mx-auto imageSize"> -->
          </div>

          <div
            class="col-12 col-xs-12 col-sm-10 col-md-4 col-lg-4 col-xl-4 mx-auto justify-content-end"
          >
            <div
              class="card my-auto"
              style="
                border-radius: 30px;
                box-shadow: 0 4px 8px 0 rgba(56, 46, 240, 0.2),
                  0 6px 20px 0 rgba(71, 38, 238, 0.19);
              "
            >
              <div class="card-body p-0">
                <p class="card-title text-center welcome mb-5 mt-2">
                  Welcome back!<br />Please login to your account.
                </p>
                <form
                  [formGroup]="LoginForm"
                  class="px-xs-2 px-sm-4 px-md-5 px-lg-5 px-xl-5 login2"
                  (ngSubmit)="submit()"
                >
                  <p>
                    <mat-form-field fxFlex class="formwidth formlabel formcss">
                      <input
                        type="text"
                        matInput
                        placeholder="User"
                        formControlName="user"
                      />

                      <mat-hint
                        class="text-danger"
                        *ngIf="
                          LoginForm.get('user').touched &&
                          LoginForm.get('user').errors?.required
                        "
                        >User Name is required</mat-hint
                      >
                    </mat-form-field>
                  </p>
                  <p>
                    <mat-form-field class="formwidth formlabel formcss">
                      <input
                        [type]="hidden ? 'text' : 'password'"
                        matInput
                        placeholder="Password"
                        formControlName="password"
                      />
                      <a
                        type="text"
                        mat-icon-button
                        matSuffix
                        (click)="hidden = !hidden"
                      >
                        <mat-icon>{{
                          hidden ? "visibility_off" : "visibility"
                        }}</mat-icon>
                      </a>

                      <mat-hint
                        class="text-danger"
                        *ngIf="
                          LoginForm.get('password').touched &&
                          LoginForm.get('password').errors?.required
                        "
                        >Password is required</mat-hint
                      >
                    </mat-form-field>
                  </p>
                  <br />
                  <p class="pb-4">
                    <label
                      class="forgotpassword"
                      (click)="showForgetPasswordMsg()"
                      >Forgot password</label
                    >
                  </p>
                  <button
                    type="submit"
                    [disabled]="LoginForm.invalid"
                    [ngClass]="{
                      loginButton: !LoginForm.invalid,
                      loginButtondisabled: LoginForm.invalid
                    }"
                  >
                    LOGIN
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<!-- <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <h5 class="card-title text-center">Welcome back!<br>Please login to your account.</h5>
            <form class="form-signin">
              <div class="form-label-group">
                <input type="email" id="inputEmail" class="form-control" placeholder="Username" required autofocus>
                <label for="inputuser">Username</label>
              </div>

              <div class="form-label-group">
                <input type="password" id="inputPassword" class="form-control" placeholder="Password" required>
                <label for="inputPassword">Password</label>
              </div>

              <div class="custom-control custom-checkbox ">
                <input type="checkbox" class="custom-control-input" id="customCheck1">
                <label class="custom-control-label" for="customCheck1">Remember me</label>
              </div>
             <div class="form-label-group ">
                
                <label for="frgtpaswrd" >Forgot password</label>
              </div>
             
              <hr class="my-4">
              <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Login</button>
             
              
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> -->
