<!-- "LOAD":{
        "PORJECTPATH":"/home/ragu/Sample_Project",
        "LOADSETNAME":"rtfload.load",
        "OLDRNAME":"ragu6.oldr",
        "SYSTEMPASS":"Grape@123",
        "CPUID":"B",
        "SELECTIVELOAD":false
    }, -->
<form [formGroup]="conf">
  <div class="form-group">
    <!-- MAKETPFPATH -->
    <div class="row">
      <!-- <label class="col-2 col-form-label">PROJECT PATH</label>
            <div class="col-4">
                <input type="text" class="form-control" formControlName="PORJECTPATH" placeholder="PROJECT PATH">
            </div>
            <label class="col-2 col-form-label">LOADSETNAME</label>
            <div class="col-4">
                <input type="text" class="form-control" formControlName="LOADSETNAME" placeholder="LOADSET NAME">
            </div> -->
      <label class="col-3 col-form-label" *ngIf="this.conf.get('MAKETPFPATH')"
        >MAKETPF FILE PATH:
        <div *ngIf="newKeys.includes('MAKETPFPATH')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div class="col-8" *ngIf="this.conf.get('MAKETPFPATH')">
        <input
          type="text"
          class="form-control"
          pattern="^(/[^/ ]*)+/?$"
          formControlName="MAKETPFPATH"
          placeholder="E.g. /home/<Linux_Id>/<Project_Name>/build/maketpf_debug.cfg"
        />
        <!-- <label class="col-form-label">{{conf.controls.MAKETPFPATH.errorMessage!=null?conf.controls.MAKETPFPATH.errorMessage:'No Error'}}</la'bel> -->
      </div>
    </div>

    <!-- LOADPATH -->
    <div class="row" *ngIf="this.conf.get('LOADPATH')">
      <label class="col-3 col-form-label"
        >LOAD FILE PATH:
        <div *ngIf="newKeys.includes('LOADPATH')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div class="col-8">
        <input
          type="text"
          class="form-control"
          pattern="^(/[^/ ]*)+/?$"
          formControlName="LOADPATH"
          placeholder="E.g. /home/<Linux_Id>/<Project_Name>/load/<Loadset_Name>.load"
        />
      </div>
    </div>

    <!-- OLDRNAME -->
    <div class="row" *ngIf="this.conf.get('OLDRNAME')">
      <label class="col-3 col-form-label"
        >OLDR NAME
        <div *ngIf="newKeys.includes('OLDRNAME')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div class="col-4">
        <input
          type="text"
          class="form-control"
          formControlName="OLDRNAME"
          placeholder="E.g. <Oldr_Name>.oldr"
        />
      </div>
    </div>

    <!-- SYSTEMPASS -->
    <div class="row" *ngIf="this.conf.get('SYSTEMPASS')">
      <label class="col-3 col-form-label"
        >SYSTEM PASSWORD
        <div *ngIf="newKeys.includes('SYSTEMPASS')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div class="col-4">
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          class="form-control"
          formControlName="SYSTEMPASS"
          placeholder="SYSTEM PASSWORD"
        />
      </div>
      <div class="col-4 mt-2">
        <mat-icon
          class="addHover"
          matSuffix
          (click)="togglePasswordVisibility()"
        >
          {{ showPassword ? "visibility_off" : "visibility" }}false
        </mat-icon>
      </div>
    </div>

    <div class="row">
      <!-- CPUID -->
      <label class="col-3 col-form-label" *ngIf="this.conf.get('CPUID')"
        >CPUID
        <div *ngIf="newKeys.includes('CPUID')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div class="col-4" *ngIf="this.conf.get('CPUID')">
        <input
          type="text"
          class="form-control"
          formControlName="CPUID"
          placeholder="CPUID"
        />
      </div>

      <!-- SELECTIVELOAD -->
      <div
        class="col-3 form-check form-check-inline"
        *ngIf="this.conf.get('SELECTIVELOAD')"
      >
        <input
          class="form-check-input"
          formControlName="SELECTIVELOAD"
          type="checkbox"
        />
        <label class="form-check-label"
          >SELECTIVELOAD
          <div *ngIf="newKeys.includes('SELECTIVELOAD')">
            <span class="text-center notes"
              ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
              field</span
            >
          </div>
        </label>
      </div>
    </div>
  </div>
</form>
