<form [formGroup]="conf">
  <div class="form-group row">
    <!-- <label class="col-2 col-form-label">
      TRACE OPTIONS
      <div *ngIf="newKeys.includes('MACRO_GROUPS')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
    </label> -->
    <div class="col-12 px-4 mb-4">
      <div class="row">
        <div class="col-3">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="ALL"
              type="checkbox"
              (change)="selectAll($event)"
            />
            <label class="form-check-label">ALL</label>
          </div>
        </div>
      </div>
      <div class="row" formGroupName="MACRO_GROUPS" *ngIf="!conf.value.ALL">
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('CREATE')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="CREATE"
              type="checkbox"
            />
            <label class="form-check-label">CREATE MACRO</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('ENTER/BACK')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="ENTER/BACK"
              type="checkbox"
            />
            <label class="form-check-label">ENTER/BACK</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('FILE')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="FILE"
              type="checkbox"
            />
            <label class="form-check-label">FILE MACRO</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('FIND')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="FIND"
              type="checkbox"
            />
            <label class="form-check-label">FIND MACRO</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('GDS')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="GDS"
              type="checkbox"
            />
            <label class="form-check-label">GDS MACROS</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('POOL')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="POOL"
              type="checkbox"
            />
            <label class="form-check-label">POOL FILE MACROS</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('RTTAPE')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="RTTAPE"
              type="checkbox"
            />
            <label class="form-check-label">RTTAPE</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('SEND')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="SEND"
              type="checkbox"
            />
            <label class="form-check-label">MESG SEND TYPE MACROS</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('SER/SNP')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="SER/SNP"
              type="checkbox"
            />
            <label class="form-check-label">SER/SNP FILE MACROS</label>
          </div>
        </div>
        <div
          class="col-3 mb-2"
          *ngIf="this.conf.get('MACRO_GROUPS').get('STORAGE')"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="STORAGE"
              type="checkbox"
            />
            <label class="form-check-label">STORAGE MACROS</label>
          </div>
        </div>
        <div class="col-3 mb-2">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="TPFDF"
              type="checkbox"
            />
            <label class="form-check-label">TPFDF MACRO</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
