<div class="container simulation-container">
  <h1 class="simulation-container__heading">API Lite</h1>
  <!-- <h2 class="simulation-container__sub-heading">Request</h2> -->
  <div class="simulation-container_content">
    <div class="m-2 d-flex flex-row row1">
      <div class="col-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Request Type</mat-label>
          <mat-select [(value)]="defaultMethod">
            <mat-option *ngFor="let method of requestMethods" [value]="method">
              {{ method }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>URL</mat-label>
          <input
            type="url"
            matInput
            placeholder="Enter request URL"
            #end="ngModel"
            placeholder="Enter URL Address.."
            [(ngModel)]="requestURL"
          />
        </mat-form-field>
      </div>
      <div class="col-2">
        <button
          type="button"
          class="btn sendButton"
          [disabled]="!requestURL"
          (click)="sendRequest()"
        >
          Send
        </button>
      </div>
    </div>

    <div class="tabSection">
      <mat-tab-group
        (selectedTabChange)="tabChange($event)"
        [selectedIndex]="tabIndex"
        animationDuration="1000ms"
      >
        <mat-tab label="Headers" class="header-tab">
          <div class="row header-container">
            <div class="col-5">
              <h4>Key</h4>
            </div>
            <div class="col-5">
              <h4>Value</h4>
            </div>
            <div class="col-2"></div>
          </div>

          <div
            *ngFor="let header of headers; let headerIndex = index"
            class="row"
          >
            <div class="col-5">
              <mat-form-field class="w-100">
                <input
                  matInput
                  [disabled]="
                    header.key === 'Content-Type' || header.key === 'observe'
                  "
                  [(ngModel)]="header.key"
                />
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field class="w-100">
                <input
                  matInput
                  [disabled]="
                    header.key === 'Content-Type' || header.key === 'observe'
                  "
                  [(ngModel)]="header.value"
                />
              </mat-form-field>
            </div>
            <div class="col-2">
              <button
                mat-button
                [hidden]="
                  header.key === 'Content-Type' || header.key === 'observe'
                "
                (click)="removeKeyValue(headerIndex, 'Headers')"
              >
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>
            </div>
          </div>

          <div class="header-tab__add-header">
            <button
              mat-button
              class="btn btn-primary header-tab__add-header-button"
              [disabled]="isDisbaled('Headers')"
              (click)="addKeyValue('Headers')"
            >
              <span>ADD Header Data</span>
            </button>
          </div>
        </mat-tab>

        <mat-tab label="Body" class="body-tab" *ngIf="defaultMethod !== 'GET'">
          <mat-tab-group
            mat-align-tabs="center"
            #bodyTabs
            [selectedIndex]="bodyTabIndex"
            (selectedTabChange)="bodyTabChange($event)"
            class="body-mat-group"
          >
            <mat-tab label="RawData">
              <div class="row p-2 justify-content-center">
                <div col-2></div>
                <div class="col-8">
                  <mat-form-field class="w-100 align-items-center">
                    <mat-label>Raw Data</mat-label>
                    <textarea
                      (input)="validateJsonData($event.target.value)"
                      matInput
                      cols="35"
                      rows="8"
                      placeholder="Enter Raw JSON Data"
                      [(ngModel)]="rawData"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="FormData">
              <div class="row body-container">
                <div class="col-4">
                  <h4>Key</h4>
                </div>
                <div class="col-4">
                  <h4>Value</h4>
                </div>
                <div class="col-3">
                  <h4>Data Type (Value)</h4>
                </div>
                <div class="col-1">
                  <h4></h4>
                </div>
              </div>

              <div
                *ngFor="let item of requestBody; let bodyIndex = index"
                class="row"
              >
                <div class="col-4">
                  <mat-form-field class="w-100">
                    <input
                      matInput
                      class="requestBodyItem"
                      [(ngModel)]="item.key"
                    />
                  </mat-form-field>
                </div>
                <div class="col-4">
                  <mat-form-field
                    *ngIf="requestBodyTypes[bodyIndex] !== 'Boolean'"
                    class="w-100"
                  >
                    <input
                      [type]="requestBodyTypes[bodyIndex].toLowerCase()"
                      matInput
                      [disabled]="requestBodyTypes[bodyIndex] === ''"
                      [(ngModel)]="item.value"
                      #value="ngModel"
                    />
                  </mat-form-field>
                  <mat-form-field
                    appearance="fill"
                    *ngIf="requestBodyTypes[bodyIndex] === 'Boolean'"
                    class="w-100"
                  >
                    <mat-label>Value</mat-label>
                    <mat-select
                      [disabled]="requestBodyTypes[bodyIndex] === ''"
                      [(value)]="item.value"
                    >
                      <mat-option value="true">True</mat-option>
                      <mat-option value="false">False</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field appearance="legacy" class="w-100">
                    <mat-label>Type of Value</mat-label>
                    <mat-select [(value)]="requestBodyTypes[bodyIndex]">
                      <mat-option *ngFor="let type of dataTypes" [value]="type">
                        {{ type }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-1">
                  <button
                    mat-button
                    (click)="removeKeyValue(bodyIndex, 'Body')"
                  >
                    <mat-icon class="delete-icon">delete</mat-icon>
                  </button>
                </div>
              </div>

              <div class="body-tab__add-body">
                <button
                  mat-button
                  class="btn btn-primary body-tab__add-body-button"
                  [disabled]="isDisbaled('Body')"
                  (click)="addKeyValue('Body')"
                >
                  <span>Add Body Data</span>
                </button>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <h2 *ngIf="errorMessage" class="simulation-container__response-heading">
    Error :
  </h2>
  <div
    class="w-100 response-section-details d-flex flex-row justify-content-start"
  >
    <pre class="response-section-details__status">{{ errorMessage }}</pre>
  </div>
  <div
    class="col-md-12 row"
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <h2 *ngIf="responseData" class="simulation-container__response-heading">
      Response
    </h2>
    <div
      *ngIf="responseTime"
      class="w-30 response-section-details d-flex flex-row justify-content-end"
    >
      <pre class="response-section-details__status m-0">
Status: {{ responseStatus }}</pre
      >
      <pre class="response-section-details__time m-0">
Time: {{ responseTime }} ms</pre
      >
    </div>
  </div>
  <div
    *ngIf="responseData"
    class="w-100 response-section-div d-flex align-items-center"
    style="background-color: rgb(0, 0, 0)"
  >
    <pre class="response-section__data w-100">{{
      responseJsonData | json
    }}</pre>
  </div>
</div>
