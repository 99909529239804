<div *ngIf="access" class="freezedMainContainer">
    <!-- <div class="freezedViewContainer" *ngIf="reportData !={}">
        <div class="view" *ngIf="reportData['liveData']">
            <div class="liveRun">
                <div class="liveRunView">
                    <div class="liveRunTitle">
                        <span>Live</span>
                    </div>
                    <div class="liveRunDateDetails" *ngIf="reportData['liveData']['startDate']">
                        <div class="liveRunStartTime">
                            <span>{{reportData['liveData']['startDate'] | date:'dd MMM yyyy'}} - </span>
                        </div>
                        <div class="liveRunEndTime">
                            <span>IN PROGRESS</span>
                        </div>
                    </div>
                </div>
                <div class="liveRunDetailsView">
                    <div class="liveScripts">
                        <span>Scripts({{reportData['liveData']['scripts']}})</span>
                    </div>
                    <div class="livePrograms">
                        <span>Programs({{reportData['liveData']['programs']}})</span>
                    </div>
                    <div class="liveDumps">
                        <span>Dump({{reportData['liveData']['dumps']}})</span>
                    </div>
                    <div class="liveRunTime">
                        <span>Run time </span>
                        <span style="font-weight: 100;">{{reportData['liveData']['runTime'] | date:'hh:mm:ss'}}</span>
                    </div>
                </div>
            </div>
            <button class="redirectLive" (click)="LoadFreezedRunData('live',reportData['liveData'])">
                <mat-icon class="arrowIcon">arrow_forward
                </mat-icon>
            </button>
        </div>
        <div class="freezedReports">
            <div class="dateWiseFreezedReports" *ngFor="let freezedData of reportData['freezedData']">
                <div class="freezedDate">
                    <span>{{freezedData['month']}}</span>
                </div>
                <div class="freezedReportsView" *ngFor="let reportData of freezedData['data']">
                    <div class="reportRuns">
                        <div class="runView">
                            <div class="runTitle">
                                <span>Live Run</span>
                            </div>
                            <div class="runDateDetails">
                                <div class="runStartTime">
                                    <span>{{reportData['freezeId']}} </span>
                                </div>
                                <div class="runEndTime">
                                    <span>08 Nov 2019</span>
                                </div>

                            </div>
                        </div>
                        <div class="runDetailsView">
                            <div class="runScripts">
                                <span>Scripts({{reportData['scripts']}})</span>
                            </div>
                            <div class="runPrograms">
                                <span>Programs({{reportData['programs']}})</span>
                            </div>
                            <div class="runDumps">
                                <span>Dump({{reportData['dumps']}})</span>
                            </div>
                            <div class="runTime" *ngIf="reportData['runTime']">
                                <span>Run time </span>
                                <span style="font-weight: 100;">{{reportData['runTime'] | date:'hh:mm:ss'}}</span>
                            </div>
                        </div>
                    </div>
                    <button class="viewScripts" (click)="LoadFreezedRunData(reportData['freezeId'],reportData)">
                        <mat-icon class="arrowIcon">arrow_forward
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div> -->
    <div class="freezedViewContainer p-3">
        <section>
            <div class="example-label  " style=" font-weight: 600;">Report Details</div>
       
            <div class="example-button-row">
                <button mat-raised-button color="primary" (click)="moveToReports()">Go to Report</button>
            </div>
        </section>
    </div>
</div>

<div *ngIf="!access" class="outerContainer d-flex justify-content-center">
    <div class=" container rounded card">
      <div class="card-body">
        <h2>We are sorry...</h2>
        <br>
          <p class="text-muted">The page you're trying to access has resticted access.</p>
          <p class="text-muted">Please refer to your system administrator</p>
      </div>
  </div>
</div>