import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { ScriptsRoutingModule } from "./scripts-routing.module";
import { MatIconModule } from "@angular/material/icon";

import { MatSortModule } from "@angular/material/sort";

import { ScriptsComponent } from "./scripts.component";
import { SearchScriptPipe } from "./search-script.pipe";
import { SharedModule } from "../_services/shared.module";
import { RunViewModule } from "../run-view/run-view.module";
// import {LoadPrograms} from './LoadPrograms/loadPrograms'
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { MatNativeDateModule } from "@angular/material/core";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ConfigSearchPipe } from "./config-search.pipe";
import { ExecutionCardComponent } from "../components/execution-card/execution-card.component";
import { ScriptsLazyloadDirective } from "./lazyload.directive";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    ScriptsComponent,
    SearchScriptPipe,
    ConfigSearchPipe,
    ExecutionCardComponent,
    ScriptsLazyloadDirective,
  ],
  imports: [
    CommonModule,
    ScriptsRoutingModule,
    MatCheckboxModule,
    MatTabsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    SharedModule,
    MatMenuModule,
    RunViewModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    ScrollingModule,
  ],
  exports: [ExecutionCardComponent]
})
export class ScriptsModule { }
