import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NavLayoutComponent } from "./nav-layout/nav-layout.component";
import { AuthguardGuard } from "./_services/authguard.guard";
import { WelcomeComponent } from "./components/welcome/welcome.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "",
    component: NavLayoutComponent,
    canActivate: [AuthguardGuard],
    children: [
      { path: "welcome", component: WelcomeComponent }, // Assuming you have a WelcomeComponent
      {
        path: ":option",
        children: [
          { path: "", redirectTo: "scripts", pathMatch: "full" }, // Default to scripts
          {
            path: "scripts",
            loadChildren: () =>
              import("./scripts/scripts.module").then((m) => m.ScriptsModule),
            // canActivate: [AuthguardGuard],
          },
          {
            path: "simulation",
            loadChildren: () =>
              import("./simulation/simulation.module").then(
                (m) => m.SimulationModule
              ),
            // canActivate: [AuthguardGuard],
          },
          // { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
          {
            path: "reports",
            loadChildren: () =>
              import("./report/report.module").then((m) => m.ReportModule),
            // canActivate: [AuthguardGuard],
          },
          // { path: 'scriptsvspgms', loadChildren: () => import('./scriptsvspgms/scriptsvspgms.module').then(m => m.ScriptsvspgmsModule), canActivate: [AuthguardGuard] },
          {
            path: "pgmvsscript",
            loadChildren: () =>
              import("./pgm-script/pgm-script.module").then(
                (m) => m.PgmScriptModule
              ),
            // canActivate: [AuthguardGuard],
          },
          {
            path: "libraryManagement",
            loadChildren: () =>
              import("./library-management/library-management.module").then(
                (m) => m.LibraryManagementModule
              ),
            // canActivate: [AuthguardGuard],
          },
          {
            path: "networkLog/:name/:id",
            loadChildren: () =>
              import("./network-log/network-log.module").then(
                (m) => m.NetworkLogModule
              ),
            // canActivate: [AuthguardGuard],
          },
          {
            path: "traceData",
            loadChildren: () =>
              import("./trace.data/trace.data.module").then(
                (m) => m.TraceDataModule
              ),
            // canActivate: [AuthguardGuard],
          },
          {
            path: "preLoad",
            loadChildren: () =>
              import("./pre-load/pre-load.module").then((m) => m.PreLoadModule),
            // canActivate: [AuthguardGuard],
          },
          {
            path: "regressionLogs",
            loadChildren: () =>
              import("./tsilog/scripts/scripts.module").then(
                (m) => m.TSIScriptsModule
              ),
            // canActivate: [AuthguardGuard],
          },
          {
            path: "insights",
            loadChildren: () =>
              import("./dashboard/dashboard.module").then(
                (m) => m.DashboardModule
              ),
            // canActivate: [AuthguardGuard],
          },
        ],
      },
      {
        path: "**",
        redirectTo: "welcome",
        pathMatch: "full",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
