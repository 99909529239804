import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  Input,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { saveAs } from "file-saver";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";
import { AppComponent } from "src/app/app.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ShareDataService } from "src/app/_services/share-data.service";

@Component({
  selector: "app-generate-report",
  templateUrl: "./generate-report.component.html",
  styleUrls: ["./generate-report.component.scss"],
})
export class GenerateReportComponent {
  @ViewChildren("matOption") matOptions: QueryList<any>;
  @Input() logs;
  public processing = false;
  public newChanges: any[] = [];
  public allSelected = false;
  selectedSystem: string = "";
  public socketSubscriptionForreportInfo: any;
  subscription: any;

  reportForm: UntypedFormGroup = new UntypedFormGroup({
    selectedReportControl: new UntypedFormControl(""),
  });

  reports = [
    { name: "Summary Report", controller: "summaryReport" },
    { name: "Dump Report", controller: "dumpReport" },
    { name: "Run Time", controller: "runTime" },
    { name: "PLOG", controller: "plog" },
  ];

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private socket: SocketioClientServices,
    private sharedServices: ShareDataService,
    private httpAdapter: HttpAdapterService
  ) { }

  ngOnInit() {
    this.socketListener();
    this.socketForSystem();
  }

  socketForSystem() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        this.selectedSystem != data["selectedSystem"]
      ) {
        this.selectedSystem = data["selectedSystem"];
      }
    });
  }

  socketListener() {
    this.socketSubscriptionForreportInfo = this.socket
      .listeningFunction("reportInfo")
      .subscribe((data) => {
        const index = this.newChanges.findIndex(
          (item) => item._id === data["_id"]
        );
        if (index > -1) {
          this.newChanges[index] = data;
        } else {
          this.newChanges.push(data);
        }
        this.showSnackBar(
          "Report Information has been modified, please click the refresh button to see the new changes",
          "notes"
        );
      });
  }

  ngOnDestroy() {
    this.socketSubscriptionForreportInfo.unsubscribe();
  }

  get someChecked() {
    return this.logs.some((x) => x.checked);
  }

  get isButtonDisabled() {
    return (
      !this.someChecked || !this.reportForm.get("selectedReportControl").value
    );
  }

  get buttonClasses() {
    return {
      downloadButton: !this.isButtonDisabled,
      downloadButtondisabled: this.isButtonDisabled,
    };
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.logs.forEach((log) => (log.checked = this.allSelected));
  }
  getSelectedCount() {
    return this.logs.filter((log) => log.checked).length
  }
  downloadReports() {
    const scriptIds = this.logs
      .filter((log) => log.checked)
      .map((log) => log.scriptId);

    const params = {
      url: `reports/${this.reportForm.value.selectedReportControl}`,
      data: { ids: scriptIds, details: "COMPLETED", isPlog: true },
      system: this.selectedSystem,
    };

    this.httpAdapter.httpPostFileGet(params, (err, result) => {
      console.log('err, result', err, result)
      if (!err) {
        const today = new Date();
        const blob = new Blob([result], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(
          blob,
          `${this.reportForm.value.selectedReportControl.toUpperCase()}__${today.getDate()}--${today.toLocaleString(
            "default",
            { month: "long" }
          )}--${today.getFullYear()}--${today.getHours()}HH--${today.getMinutes()}`
        );
        // this.reportForm.reset();
        // this.logs.forEach((log) => (log.checked = false));
        // this.allSelected = false;
      } else {
        console.error("Error", err);
      }
    });
  }

  private showSnackBar(message: string, action: string) {
    new AppComponent(this._snackBar).showSnackBar(message, action);
  }
}
