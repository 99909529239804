import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: "app-input-disp",
  templateUrl: "./input-disp.component.html",
  styleUrls: ["./input-disp.component.scss"],
})
export class InputDispComponent implements OnInit {
  readonly = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<String>, private clipboard: Clipboard) { }

  @ViewChild("scrollViewport")
  private cdkVirtualScrollViewport;

  ngOnInit() { }
  editFile() {
    this.readonly = false;
  }
  stopEdit() {
    this.readonly = true;
  }

  copyScriptFile(value) {
    this.clipboard.copy(value.join(""));
  }
  trackByFn(index, item) {
    return index
  }
}