import { Component, OnInit, ViewEncapsulation, inject } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AppComponent } from "./../../app.component";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { UserService } from "./../../_services/user.service";
import { ShareDataService } from "src/app/_services/share-data.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  // encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  cookieService = inject(CookieService);

  LoginForm: UntypedFormGroup = new UntypedFormGroup({
    user: new UntypedFormControl("", Validators.required),
    password: new UntypedFormControl("", Validators.required),
  });

  private httpAdapter: HttpAdapterService;
  private subscription: any;
  sharedData: {};

  constructor(
    public router: Router,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    public dialog: MatDialog,
    private userService: UserService,
    private sharedServices: ShareDataService
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }
  hidden = false;

  ngOnInit() {
    this.socketListener();
  }

  socketListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      this.sharedData = data;
    });
  }

  /**
   * @Jira PM-933
   */

  submit() {
    const self = this;
    if (self.LoginForm.valid) {
      const params = {
        url: "login",
        data: { ...self.LoginForm.value, user: self.LoginForm.value.user.toLowerCase() },
      };

      self.httpAdapter
        .postData(params)
        .subscribe((response: HttpResponse<any>) => {
          if (response.status == 200) {
            this.cookieService.set(
              "virusGHitter",
              response.headers.get("X-Auth-Token")
            );
            self.router.navigate(["/welcome"]);
            new AppComponent(self._snackBar).showSnackBar(
              "Login successfull",
              "success"
            );
          } else {
            new AppComponent(self._snackBar).showSnackBar(
              response.body.message,
              "error"
            );
          }
        }),
        (error) => {
          console.error("Error occurred:", error);
          new AppComponent(self._snackBar).showSnackBar("API Error", "error");
        };
    }
  }


  showForgetPasswordMsg() {
    var self = this;
    const showDialogue = self.dialog.open(DialogueComponent, {
      direction: "ltr",
      panelClass: "my-forgot-password-class",
      minWidth: "350px",
      // position: { 'top': "10px" },
      data: {
        Title: "Forgot password",
        Content: "Please email your admin to change the password",
      },
    });
    showDialogue.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
