import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberFormatter",
})
export class NumberFormatterPipe implements PipeTransform {
  transform(value: number): string {
    if (value < 1000) {
      return value.toString(); // Less than a thousand, return as is
    } else if (value >= 1000 && value < 1000000) {
      return (value / 1000).toFixed(1) + "K"; // More than a thousand, display in thousands (K)
    } else if (value >= 1000000 && value < 10000000) {
      return (value / 1000000).toFixed(1) + "M"; // More than a million, display in millions (M)
    } else if (value >= 10000000) {
      return (value / 10000000).toFixed(1) + "Cr"; // More than a crore, display in crores (Cr)
    } else {
      return value.toString();
    }
  }
}
