// import { LoadRequestsComponent } from "./load-requests/load-requests/load-requests.component";
import { SendRequestComponent } from "./send-request/send-request/send-request.component";
import { SimulationComponent } from "./simulation.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
const routes: Routes = [
  {
    path: "",
    component: SimulationComponent,
    children: [
      { path: "", redirectTo: "checkStatus", pathMatch: "full" },
      { path: "checkStatus", component: SendRequestComponent },
      // { path: "load", component: LoadRequestsComponent },
      // { path: 'scripts', loadChildren: () => import('./../scripts/scripts.module').then(m => m.ScriptsModule) },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SimulationRoutingModule { }
