import { Directive, Output, EventEmitter, ElementRef, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[networkLazyload]'
})
export class NetworkLazyloadDirective implements OnInit, OnDestroy {

  private observer: IntersectionObserver;

  @Output() reachedBottom = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.reachedBottom.emit();
      }
    });

    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
