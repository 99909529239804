<div style="display: flex; justify-content: space-between; align-items: center">
  <h2 mat-dialog-title>PLOG Configuration</h2>
</div>
<mat-dialog-content
  class="mat-typography"
  style="
    border-top: 1px solid black;
    font-family: 'Roboto', sans-serif;
    width: 30vw;
    height: 70vh;
  "
>
  <div class="container scrollable-container">
    <div class="row m-0">
      <div
        class="mt-3 mb-1 p-3 rounded-0 border w-100 border-muted inputContainer"
      >
        <mat-list>
          <span style="white-space: pre-line; color: balck">{{
            data["value"]
          }}</span>
        </mat-list>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
