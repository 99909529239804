import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ValidateViewRoutingModule } from "./validate-view-routing.module";
import { ValidateViewComponent } from "./validate-view.component";
import { ExecLogComponent } from "./exec-log/exec-log.component";
import { MappedViewComponent } from "./mapped-view/mapped-view.component";
import { SearchTransactionPipe } from "./search-transaction.pipe";
import { SummaryReportComponent } from "./summary-report/summary-report.component";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule } from "@angular/forms";
import { MatSortModule } from "@angular/material/sort";
import { SharedModule } from "src/app/_services/shared.module";
import { StatusfilterPipe } from "./statusfilter.pipe";
import { HexPipe } from "./hex.pipe";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MsConvertorPipe } from "./summary-report/ms-convertor.pipe";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { LazyloadDirective } from './exec-log/lazyload.directive';
import { SubscriptcontainerComponent } from "../components/subscriptcontainer/subscriptcontainer.component";
import { SubScriptHidingDirective } from "./exec-log/subscriptHiding.directive";

// import { OutputComparisonComponent } from './output-comparison/output-comparison.component';

@NgModule({
  declarations: [
    ValidateViewComponent,
    ExecLogComponent,
    MappedViewComponent,
    SearchTransactionPipe,
    SummaryReportComponent,
    StatusfilterPipe,
    HexPipe,
    MsConvertorPipe,
    LazyloadDirective,
    SubscriptcontainerComponent,
    SubScriptHidingDirective
  ],
  exports: [SubscriptcontainerComponent],
  imports: [
    CommonModule,
    ValidateViewRoutingModule,
    MatCheckboxModule,
    MatTabsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    SharedModule,
    ScrollingModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    //
  ],
})
export class ValidateViewModule { }
