import { IrtfDispComponent } from "./../irtf-disp/irtf-disp.component";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { HttpClient } from "@angular/common/http";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ShareDataService } from "src/app/_services/share-data.service";

@Component({
  selector: "app-irtf-selection",
  templateUrl: "./irtf-selection.component.html",
  styleUrls: ["./irtf-selection.component.scss"],
})
export class IrtfSelectionComponent implements OnInit {
  //variable declared to check whether file has been selected/not
  itemSelected: boolean = false;
  httpAdapter: HttpAdapterService;
  enableUpload = false;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  file;

  public archiveScriptStatus: boolean = false;
  public showArchiveAllButton: boolean = true;
  public deleteScriptsArrayId = [];
  public disableArchiveFile: boolean = true;
  private selectedSystem: string = "";

  errorMessage = [];
  successMessage = null;
  subscription: any;
  constructor(
    public http: HttpClient,
    public dialogRef: MatDialogRef<IrtfSelectionComponent>,
    public dialog: MatDialog,
    private sharedServices: ShareDataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  ngOnInit(): void {
    this.socketListener();
  }

  socketListener() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        data["selectedSystem"] != this.selectedSystem
      )
        this.selectedSystem = data["selectedSystem"];
    });
  }

  onChange(event) {
    this.file = event.target.files;
    this.onUpload();
  }

  onUpload() {
    var self = this;
    let irtfFileName = self.file[0].name.split(".")[0];
    let FileExtension = self.file[0].name.split(".")[1];
    let FileNames = [];
    self.errorMessage = [];

    if (irtfFileName.toString().split("").length > 7) {
      self.errorMessage.push(
        `* More than 7 characters in the file name - <b>${irtfFileName}</b>`
      );
    }

    /** Numbers should not be permitted. ERORR validaton is currently not needed. */

    // if ( irtfFileName.toString().match(/[0-9]/g) != null) {
    //   self.errorMessage.push(`* Numbers should not be permitted - <b>${irtfFileName}</b>`);
    // }

    /** RegEx for Checking Number -- irtfFileName.toString().match(/[0-9]/g) */

    if (FileExtension != "irtf" && FileExtension != "txt") {
      self.errorMessage.push(
        `* File Upload Failed due to Invalid file type - <b>${FileExtension}</b>`
      );
    }
    if (self.errorMessage.length == 0) {
      self.errorMessage = null;
      var params = {
        system: this.selectedSystem,
        url: "irtfFile/getIrtfFileList",
      };
      self.httpAdapter.httpPost(params, (error, response) => {
        if (error) {
          console.log("Error in Uploading File", error);
        } else {
          var filesList = response.body.data;
          console.log("filesList", filesList);
          filesList.forEach((file) => {
            FileNames.push(file["irtfFileName"]);
          });
          var fileStatus = FileNames.includes(irtfFileName.toUpperCase());
          if (fileStatus) {
            var replace;
            const showDialogue = self.dialog.open(DialogueComponent, {
              direction: "ltr",
              minWidth: "350px",
              position: { top: "10px" },
              panelClass: "my-alert-message-class",
              data: {
                Title: "Confirmation",
                Content:
                  "iRTF File alredy Exsist! The file will be replaced on your action",
                isConfirm: true,
              },
            });
            showDialogue.afterClosed().subscribe((result) => {
              console.log(`Dialog result: ${result}`);
              replace = result;
              if (replace) {
                this.UploadIrtfFile();
                replace
                  ? // window.alert("The " + irtfFileName + " Script has been replaced")
                    self.dialog.open(DialogueComponent, {
                      direction: "ltr",
                      minWidth: "350px",
                      position: { top: "10px" },
                      panelClass: "my-alert-message-class",
                      data: {
                        Title: "Information",
                        Content:
                          "The " + irtfFileName + " Script has been replaced",
                        isConfirm: true,
                        isClose: false,
                      },
                    })
                  : "";
                this.itemSelected = false;
              } else {
                this.itemSelected = false;
              }
            });
          } else {
            this.UploadIrtfFile();
          }
          this.dialogRef.componentInstance.data["value"] = response.body.data;
        }
      });
    }
  }

  UploadIrtfFile() {
    var self = this;
    var params = {
      system: this.selectedSystem,
      url: "irtfFile/uploadFile",
    };
    self.httpAdapter.upload(params, self.file).subscribe(
      (result) => {
        if (result) {
          console.log(result);
          self.successMessage = `Successfully Added `;
          setTimeout(() => {
            self.successMessage = null;
          }, 5000);
          if (result["fileUpload"]) {
            var params = {
              system: this.selectedSystem,
              url: "irtfFile/getIrtfFileList",
            };
            this.httpAdapter.httpPost(params, (error, response) => {
              if (error) {
                // console.log("Error", error)
              } else {
                this.dialogRef.componentInstance.data["value"] =
                  response.body.data;
              }
            });
          } else {
            // window.alert("File upload failed: " + result["uploadMsg"])
            self.dialog.open(DialogueComponent, {
              direction: "ltr",
              minWidth: "350px",
              position: { top: "10px" },
              panelClass: "my-alert-message-class",
              data: {
                Title: "Error Message",
                Content: "File upload failed: " + result["uploadMsg"],
              },
            });
          }
        } else {
          // window.alert("File upload failed")
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            panelClass: "my-alert-message-class",
            data: { Title: "Error Message", Content: "File upload failed" },
          });
        }
      },
      (err) => {
        // window.alert("File upload failed")
        self.dialog.open(DialogueComponent, {
          direction: "ltr",
          minWidth: "350px",
          position: { top: "10px" },
          panelClass: "my-alert-message-class",
          data: { Title: "Error Message", Content: "File upload failed" },
        });
      }
    );
  }

  CheckDialoug() {
    this.itemSelected = true;
  }

  CloseDialoug() {
    this.data["closed"] = true;
    this.dialogRef.close();
  }

  // The function is used to Open Content for the Script file in the Editor
  openContent(event, item) {
    event.stopPropagation();
    this.showIrtfInput(item);
  }

  // The function is used to display the details of the Script file in the OpenContent
  showIrtfInput(fileId) {
    var self = this;

    var irtfFilegetParam = {
      url: "irtfile/viewFile",
      data: {
        ObjectId: fileId,
      },
    };

    self.httpAdapter.httpPost(irtfFilegetParam, function (err, fileResp) {
      if (!err) {
        const irtfTransactions = fileResp.body;
        console.log(irtfTransactions);
        self.dialog.open(IrtfDispComponent, {
          data: { value: irtfTransactions },
        });
      }
    });
  }

  onClick() {
    const fileInput = this.fileInput.nativeElement;
    fileInput.click();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
