import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PgmScriptRoutingModule } from "./pgm-script-routing.module";
import { PgmScriptComponent } from "./pgm-script.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms"; // <-- #1 import module
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { GenerateTreeComponent } from "./generate-tree/generate-tree.component";
import { MatIconModule } from "@angular/material/icon";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { NzSelectModule } from "ng-zorro-antd/select";
@NgModule({
  declarations: [PgmScriptComponent, GenerateTreeComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    RxReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    PgmScriptRoutingModule,
    MatButtonModule,
    MatIconModule,
    NzSelectModule,
  ],
})
export class PgmScriptModule {}
