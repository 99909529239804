import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
  ɵConsole,
  ChangeDetectorRef,
} from "@angular/core";
import * as _ from "underscore";
import { MatSort } from "@angular/material/sort";
import { environment } from "src/environments/environment";
import { MatTableDataSource } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { map, startWith } from "rxjs/operators";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from "file-saver";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";
import { MatDialog } from "@angular/material/dialog";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";

@Component({
  selector: "app-summary-report",
  templateUrl: "./summary-report.component.html",
  styleUrls: [
    "./summary-report.component.scss",
    "./summary-report2.component.scss",
  ],
})
export class SummaryReportComponent implements OnInit {
  @ViewChild("summarySort", { static: false }) sort: MatSort;
  @ViewChild("transactionSort", { static: false }) transSort: MatSort;
  @Input() public filterScript;
  @Input() public subScriptData = [];
  fileUrl;
  @Input() currentScriptIndex;
  programOrMacroData = [];
  public fieldName = "Programs";
  socketSubscriptionForValidateAndSummaryStatus: any;
  @Input() set current(value) {
    this.currentScript = value;
  }
  public summaryReportKeys = environment.summaryReportKeys;
  public currentFeild = {};
  @Input() set summaryReportData(summaryValue) { }

  public currentScript;
  public currentRow = {};
  public summaryReport = [];
  public detailedReport = new MatTableDataSource([]);
  public transactionData = new MatTableDataSource([]);
  public displayedColumns = [
    "TRANSACTION",
    "PHASE 1",
    "PHASE 2",
    "DIFF",
    "PERCENTAGE",
  ];
  public displaySummaryColumns = [];
  public status = false;
  public isShow = false;
  public expandInput: string;
  public scriptDropdownList = [];
  public fulldata: string;
  dropDownHeading = { name: "All", count: 0 };
  myControl = new UntypedFormControl("");
  filteredOptions: Observable<string[]>;
  constructor(
    public http: HttpClient,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public httpAdapter: HttpAdapterService,
    private ref: ChangeDetectorRef,
    private socket: SocketioClientServices
  ) { }

  ngOnInit() {
    this.socketForValidationAndSummaryStatus();
    this.getSummaryReport();
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   console.log(this.filterScript);
  //   return this.filterScript.filter((option) =>
  //     option["scriptName"].toLowerCase().includes(filterValue)
  //   );
  // }

  socketForValidationAndSummaryStatus() {
    const self = this;
    self.socketSubscriptionForValidateAndSummaryStatus = self.socket
      .listeningFunction("validateAndSummaryStatus")
      .subscribe((data) => {
        if ((this.currentScript._id, data["scriptId"])) {
          this.programOrMacroData = [];
          this.getSummaryReport();
        }
      });
  }

  private _filter(value: string) {
    var filter = this.subScriptData.filter((option) => {
      return (
        option["FileName"].toLowerCase().includes(value.toLowerCase()) ||
        option["subScript"].toLowerCase().includes(value.toLowerCase())
      );
    });
    console.log(filter);

    return filter;
  }
  /**
   * A below function is used to filter the report, According to the script.
   * It will group the transactionData According to the subscript name.
   * Finaly it will stored into the summaryReport  (PD-37)
   */
  public formatting(Report, source) {
    var self = this;

    source.map((x, index) => {
      /**
       * x is having the entier object of an array
       */

      let parentName = _.groupBy(x["transactionData"], function (y) {
        return y["SCRIPTINFO"]["FileName"];
      });
      source[index]["transactionData"] = [];
      var count = 0;
      Object.keys(parentName).map((a, parentindex) => {
        let childName = _.groupBy(parentName[a], function (b) {
          return b["SCRIPTINFO"]["subScriptName"];
        });

        let subscript = [];

        Object.keys(childName).map((c, childindex) => {
          childName[c].map((trans, index) => {
            count = count + trans["Difference"];
          });

          subscript.push({ subScriptname: c, arry_to_json: childName[c] });
        });
        console.log("count--->", source[index][Report], "-->", count);
        source[index]["transactionData"].push({
          filename: a,
          arry_to_json: subscript,
        });
      });
      source[index]["TransactionDiff"] = count;
      // self.summaryReport['summaryReport'][0][Report][index]['Percentage'] == 0 ? count : self.summaryReport['summaryReport'][0][Report][index]['Percentage']

      source[index]["TransactionPercentage"] = Math.round(
        Math.abs(count / Math.max(source[index]["PH1"], source[index]["PH2"])) *
        100
      );
    });
  }
  selectedSortKey = "";
  selectedSortMode = "";
  getPGMORMACData(field) {
    this.offset = 0;
    this.detailedReport = new MatTableDataSource([]);
    this.programOrMacroData = [];
    // this.selectedSortKey = "";
    // this.selectedSortMode = "";
    this.getDetailedData(
      field,
      this.selectedScript,
      this.selectedSubScript,
      this.limit,
      0,
      this.selectedSortKey,
      this.selectedSortMode
    );
  }

  public getDetailedData(
    field,
    scriptName,
    subScript,
    limit,
    offset,
    sortKey,
    mode
  ) {
    this.detailedReport = new MatTableDataSource([]);
    var self = this;
    self.fieldName = field.field;
    self.currentFeild = field;
    var params = {
      url: `summaryReport/getPgmOrMacroData?id=${this.currentScript._id}&type=${this.fieldName.toUpperCase() === "PROGRAMS" ? "PGM" : "MAC"
        }&scriptName=${scriptName}&subScript=${subScript}&limit=${limit}&offset=${offset}&sortKey=${sortKey}&dir=${mode}`,
    };
    self.httpAdapter.httpGetNew(params).subscribe(
      (result: any) => {
        this.programOrMacroData = [...this.programOrMacroData, ...result.body];
        self.detailedReport = new MatTableDataSource(this.programOrMacroData);
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value || ""))
        );
      },
      (error) => {
        console.log(error);
      }
    );
    self.fulldata = JSON.stringify(this.programOrMacroData);

    self.displaySummaryColumns = self.currentFeild["keys"];
  }

  highlight(row: any) {
    console.log("Arrow down event", row);

    this.currentRow = row;
  }

  arrowUpEvent(row) {
    // console.log("Arrow down event", row);
    var nextRow =
      this.detailedReport.data[
      this.detailedReport.data.indexOf(this.currentRow) - 1
      ];
    if (nextRow != undefined) {
      this.highlight(nextRow);
    }
  }

  /**
   * The following functionality is used to filter the programs & macros
   */
  // filterMethod(event, ele) {

  //   var self = this;
  //   self.dropDownHeading = { name: event.scriptName, count: event.count };
  //   self.summaryReport["summaryReport"][0] = JSON.parse(self.fulldata);
  //   if (event.scriptName != "ALL") {
  //     self.summaryReport["summaryReport"][0][
  //       self.currentFeild["field"].toUpperCase()
  //     ].forEach(function (transaction, transactionindex) {
  //       let parenetCountph1 = 0;
  //       let parenetCountph2 = 0;
  //       let TransactionDiff = 0;
  //       let difference = 0;

  //       transaction["transactionData"] = transaction["transactionData"].filter(
  //         function (file) {
  //           if (file["filename"] == event.parentScript) {
  //             return (file["arry_to_json"] = file["arry_to_json"].filter(
  //               function (subScript) {
  //                 if (event.scriptName != event.parentScript) {
  //                   if (subScript["subScriptname"] == event.scriptName) {
  //                     subScript["arry_to_json"].map((i) => {
  //                       console.log("i", i);

  //                       parenetCountph1 += i["PH1"];
  //                       parenetCountph2 += i["PH2"];
  //                       TransactionDiff += i["TransactionDiff"];
  //                     });
  //                     difference = Math.abs(parenetCountph1 - parenetCountph2);
  //                     return subScript;
  //                   }
  //                 } else {
  //                   if (file["filename"] == event.scriptName) {
  //                     subScript["arry_to_json"].map((i) => {
  //                       console.log("i", i);
  //                       parenetCountph1 += i["PH1"];
  //                       parenetCountph2 += i["PH2"];
  //                       TransactionDiff += i["TransactionDiff"];
  //                     });
  //                     difference = Math.abs(parenetCountph1 - parenetCountph2);
  //                     return subScript;
  //                   }
  //                 }
  //               }
  //             ));
  //           }
  //         }
  //       );
  //       self.summaryReport["summaryReport"][0][
  //         self.currentFeild["field"].toUpperCase()
  //       ][transactionindex]["PH1"] = parenetCountph1;
  //       self.summaryReport["summaryReport"][0][
  //         self.currentFeild["field"].toUpperCase()
  //       ][transactionindex]["PH2"] = parenetCountph2;
  //       self.summaryReport["summaryReport"][0][
  //         self.currentFeild["field"].toUpperCase()
  //       ][transactionindex]["Difference"] = difference;
  //       self.summaryReport["summaryReport"][0][
  //         self.currentFeild["field"].toUpperCase()
  //       ][transactionindex]["Percentage"] = self.calcPercentage(
  //         parenetCountph2,
  //         parenetCountph1
  //       );
  //       // self.summaryReport['summaryReport'][0][self.currentFeild['field'].toUpperCase()][transactionindex]['TransactionDiff'] = TransactionDiff
  //     });
  //     self.detailedReport = new MatTableDataSource(
  //       JSON.parse(
  //         JSON.stringify(
  //           self.summaryReport["summaryReport"][0][
  //           self.currentFeild["field"].toUpperCase()
  //           ]
  //         )
  //       )
  //     );
  //     this.displaySummaryColumns = self.currentFeild["keys"];
  //   } else {
  //     self.detailedReport = new MatTableDataSource(
  //       JSON.parse(
  //         JSON.stringify(
  //           self.summaryReport["summaryReport"][0][
  //           self.currentFeild["field"].toUpperCase()
  //           ]
  //         )
  //       )
  //     );
  //     this.displaySummaryColumns = self.currentFeild["keys"];
  //   }
  // }
  selectedScript = "";
  selectedSubScript = "";
  filterMethodNew(scriptData, subScriptStatus) {
    if (scriptData === "ALL") {
      this.selectedScript = "";
      this.selectedSubScript = "";
      this.detailedReport = new MatTableDataSource([]);
      this.programOrMacroData = [];
      this.offset = 0;

      this.getDetailedData(
        this.currentFeild,
        "",
        "",
        this.limit,
        0,
        this.selectedSortKey,
        this.selectedSortMode
      );
    } else {
      this.selectedScript = scriptData.FileName;
      this.selectedSubScript = subScriptStatus ? scriptData.subScript : "";
      this.detailedReport = new MatTableDataSource([]);
      this.programOrMacroData = [];
      this.ref.detectChanges();
      this.offset = 0;

      this.getDetailedData(
        this.currentFeild,
        scriptData.FileName,
        subScriptStatus ? scriptData.subScript : "",
        this.limit,
        0,
        this.selectedSortKey,
        this.selectedSortMode
      );
    }
  }
  getMore(event) {
    console.log(event);
  }
  trackByFn(index, item) {
    return item.subScript; // Assuming your item has a unique 'id' property
  }
  calcPercentage(ph1, ph2) {
    if (ph2 != 0) {
      if (isNaN(Math.round((Math.abs(ph2 - ph1) / ph2) * 100))) {
        return 0;
      } else {
        return Math.round((Math.abs(ph2 - ph1) / ph2) * 100);
      }
    } else {
      if (isNaN(Math.round(Math.abs((ph1 - ph2) / ph1) * 100))) {
        return 0;
      } else {
        return Math.round(Math.abs((ph1 - ph2) / ph1) * 100);
      }
    }
  }
  arrowDownEvent(row: object) {
    // console.log("Arrow down event", row);
    var nextRow =
      this.detailedReport.data[
      this.detailedReport.data.indexOf(this.currentRow) + 1
      ];
    if (nextRow != undefined) {
      this.highlight(nextRow);
    }
  }

  public downloadScriptDiff(transaction) {
    var self = this;
    var scripts = {};
    transaction.forEach((data) => {
      if (data["PH1"] !== data["PH2"]) {
        var list = scripts[data["SCRIPTINFO"]["FileName"]];
        if (list) {
          if (!list.includes(data["SCRIPTINFO"]["subScriptName"])) {
            // ✅ only runs if value not in array
            list.push(data["SCRIPTINFO"]["subScriptName"]);
          }
        } else {
          scripts[data["SCRIPTINFO"]["FileName"]] = [
            data["SCRIPTINFO"]["subScriptName"],
          ];
        }
      }
    });
    console.log("scripts--->", scripts);
    var params = {
      url: "scriptGen/generateScript",
      data: {
        generateScript: scripts,
        summaryReportId: this.currentScript._id,
      },
    };
    console.log("params--->", params);

    self.httpAdapter.httpPost(params, function (err, result) {
      if (err) {
        console.log("Error", err);
      } else {
        if (result.status == 200) {
          console.log("Download scripts 👉️ \r", result.body.message);
          const today = new Date();
          const blob = new Blob([result.body.message], { type: "text" });
          saveAs(
            blob,
            self.selectedProgram["name"] +
            "_" +
            self.selectedProgram["type"] +
            "_" +
            today.getDate().toString() +
            "--" +
            today.toLocaleString("default", { month: "long" }) +
            "--" +
            today.getFullYear().toString() +
            "--" +
            today.getHours().toString() +
            "HH -- " +
            today.getMinutes().toString() +
            ".input"
          );
        } else {
          console.log("Error Message 👉️ \r", result.body.message);
          self.dialog.open(DialogueComponent, {
            direction: "ltr",
            minWidth: "350px",
            position: { top: "10px" },
            data: { Title: "Error Message", Content: result.body.message },
          });
        }
      }
    });
    // var groupVlaue = _.groupBy(scripts, function (a) {
    //   return a['subScriptName'];
    // });
    // Object.keys(groupVlaue).map((data, index) => {
    //   concat += '\n***' + data + '\r\n';
    //   groupVlaue[data].map((tran, index) => {
    //     concat += tran['TRANSACTION'] + '\r\n';
    //   });
    // });
  }

  selectedProgram = {};

  public getTransactionDetails(selectedPgm, index) {
    var self = this;
    this.isShow = false;
    var transactionData = [];
    console.log(selectedPgm, index, this.currentRow);
    self.selectedProgram = selectedPgm["program"]
      ? { name: selectedPgm["program"], type: "PROGRAM" }
      : { name: selectedPgm["MACRO"], type: "MACRO" };
    self.selectedProgram["TransactionDiff"] = selectedPgm["TransactionDiff"];

    let params = {
      url: `summaryReport/getTransactionDataForPerfomance?id=${selectedPgm._id}&scriptId=${this.currentScriptIndex}`,
    };
    this.httpAdapter.httpGetNew(params).subscribe((res) => {
      const TransData: any = res.body;

      // this.currentRow["transactionData"] = TransData;
      // this.currentRow["transactionData"] = TransData.map((x) => {
      //   x["arry_to_json"].map((y) => {
      //     console.log("---", y["arry_to_json"]);
      //     transactionData = transactionData.concat(y["arry_to_json"]);
      //   });
      // });
      // let PH1Array = [];
      // let PH2Array = [];
      // let scripts = [];
      // let concat = '';
      // transactionData.forEach((data) => {
      //   if (data["PH1"] !== data["PH2"]) {
      //   }
      //    const PH1Data = data["SCRIPTINFO"]["subScriptName"] + " | " + data["SCRIPTINFO"]["FileName"] + " | " + data['TRANSACTION'] + " | " + data["TRANSACTIONid"] + " | " + data["PH1"]
      //    const PH2Data = data["SCRIPTINFO"]["subScriptName"] + " | " + data["SCRIPTINFO"]["FileName"] + " | " + data['TRANSACTION'] + " | " + data["TRANSACTIONid"] + " | " + data["PH2"]
      //    PH1Array.push(PH1Data); PH2Array.push(PH2Data)
      // })
      //  const diffCheck1 = PH1Array.filter(x => !PH2Array.includes(x)); // elements in PH1Check but not in PH2Check
      //  const diffCheck2 = PH2Array.filter(x => !PH1Array.includes(x)); // elements in PH2Check but not in PH1Check
      //  const entries = diffCheck1.map(x => x.split(" | ")[2])
      self.transactionData = new MatTableDataSource(
        JSON.parse(JSON.stringify(TransData))
      );
      //  self.transactionData = new MatTableDataSource(JSON.parse(JSON.stringify(ele['transactionData'])))
      //  const data = JSON.stringify(diffCheck1) + '\n' + JSON.stringify(diffCheck2)+'\n\n\n'+"Entries Alone"+"\n"+"------------------------------"+"\n"+JSON.stringify(entries);
      //  const blob = new Blob([concat], { type: "text/csv" });
      //  saveAs(blob, selectedPgm["program"] + "__Scripts.txt")
    });
  }

  public close() {
    this.isShow = true;
  }

  sortSummaryData(event) {
    this.selectedSortKey = event.direction === "" ? "" : event.active;
    this.selectedSortMode = event.direction;
    this.detailedReport = new MatTableDataSource([]);
    this.programOrMacroData = [];
    this.ref.detectChanges();
    this.offset = 0;

    this.getDetailedData(
      this.currentFeild,
      this.selectedScript,
      this.selectedSubScript,
      this.limit,
      0,
      this.selectedSortKey,
      this.selectedSortMode
    );

    // if (event.direction != "") {
    //   this.detailedReport.data = JSON.parse(
    //     JSON.stringify(
    //       this.detailedReport.sortData(this.detailedReport.data, this.sort)
    //     )
    //   );
    // } else {
    //   this.detailedReport.data = JSON.parse(
    //     JSON.stringify(
    //       this.summaryReport["summaryReport"][0][
    //       this.currentFeild["field"].toUpperCase()
    //       ]
    //     )
    //   );
    // }
  }

  sortTransactionData(event) {
    if (event.direction != "") {
      this.transactionData.data = JSON.parse(
        JSON.stringify(
          this.transactionData.sortData(
            this.transactionData.data,
            this.transSort
          )
        )
      );
    } else {
      this.transactionData.data = JSON.parse(
        JSON.stringify(this.transactionData.data)
      );
    }
  }

  expandInputView() {
    var self = this;
    console.log("Coming to expand");
    self.expandInput = "block";
  }

  /**Function to get the list of SummaryReport Data
   *API Call to fecth the runStatus and Program/Macro Count
   */

  /**To find milliSecond function */
  // milliSecond(value){
  //   let number = value / 1000;
  //   return number.toString().split('.')[1]
  // }
  limit = 100;
  offset = 0;
  public getSummaryReport() {
    var self = this;
    if (self.currentScript.progress != null) {
      var params = {
        url: `scripts/summaryReport/${this.currentScript._id}`,
        data: this.currentScript,
      };
      self.httpAdapter.httpGetNew(params).subscribe((result) => {
        self.summaryReport = result["body"]["summaryReport"];

        if (!_.isEmpty(self.summaryReport) && self.summaryReportKeys) {
          self.detailedReport.sort = self.sort;
          self.transactionData.sort = self.transSort;
          this.offset = 0;
          self.getDetailedData(
            self.summaryReportKeys[0],
            "",
            "",
            this.limit,
            0,
            "",
            ""
          );
        }
      });
    }
  }
}
