import { LoadingComponent } from "../../../components/loading/loading.component";
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import * as _ from "underscore";
import { HttpClient } from "@angular/common/http";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { AppComponent } from "src/app/app.component";
import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from "@angular/cdk/scrolling";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ShareDataService } from "src/app/_services/share-data.service";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";

interface dropDownList {
  scriptName: string;
  parentScript: string;
  subScript: boolean;
  count: number;
}
@Component({
  selector: "app-tsi-mapped-view",
  templateUrl: "./mapped-view.component.html",
  styleUrls: ["./mapped-view.component.scss", "./mapped-view2.component.scss"],
})
export class MappedViewComponent implements OnInit {
  public test = 1;
  public mappedData = {};
  public filterableMappedData = {};
  public searchFilter = "";
  public selectedSubscript = [];
  public noOfSelectedSubscript = 0;
  public httpAdapter: HttpAdapterService;
  scriptDropdownList: dropDownList[] = [];
  dropDownHeading = { name: "All", count: 0, scriptName: "", subScript: "" };

  @Input() currentScript;
  @Input() sharedData;
  socketSubscriptionForValidateAndSummaryStatus: any;
  @Input() set trnxData(value) {
    this.all = true;
    this.masked = false;
    this.comparison = false;
    this.error = false;
    this.dump = false;
    this.selectedSubscript = [];
    this.noOfSelectedSubscript = 0;
  }
  changedTransaction = [];
  public masked = false;
  public all = true;
  public comparison = false;
  public comparisonCount = 0;
  public error = false;
  public errorCount = 0;
  public dump = false;
  public dumpCount = 0;
  public statusFilterValue = "null";
  public backUpdateData: string;
  public currentProperty = "diffWithoutMaskHtml";
  public currentPropertyStatus = "diffWithoutMask_status";
  public scrollButton = true;
  filterableMappedDataNew = [];
  @Output() validationStatusEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  constructor(
    public http: HttpClient,
    private _snackBar: MatSnackBar,
    private sharedServices: ShareDataService,
    public socket: SocketioClientServices
  ) {
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  DownArrow = true;
  UpArrow = false;

  // HostListener events list

  @HostListener("window:wheel")
  scrolling() {
    this.scrollButtonListener();
  }

  @HostListener("window:mousedown")
  onMouseDown() {
    this.scrollButtonListener();
  }

  @HostListener("window:mouseup")
  onMouseUp() {
    this.scrollButtonListener();
  }

  @HostListener("window:keyup")
  onKeyUp() {
    this.scrollButtonListener();
  }

  @HostListener("window:keydown")
  onKeyDown() {
    this.scrollButtonListener();
  }

  @HostListener("window:keypress")
  onKeyPress() {
    this.scrollButtonListener();
  }

  scrollButtonListener() {
    this.virtualScroll.elementScrolled().subscribe((event) => {
      if (this.virtualScroll.measureScrollOffset("bottom") > 100) {
        this.DownArrow = true;
      } else {
        this.DownArrow = false;
      }
      if (this.virtualScroll.measureScrollOffset("top") > 100) {
        this.UpArrow = true;
      } else {
        this.UpArrow = false;
      }
    });
  }
  subscription;
  @Input() executionId;

  ngOnInit(): void {
    this.socketForValidationAndSummaryStatus();
    this.getValidationScriptsAndSubScripts(this.executionId);
    this.getValidationCounts(this.executionId, "", "", "");
    this.getValidationData(
      this.executionId,
      "",
      "",
      this.limit,
      this.offset,
      this.searchFilter
    );

    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      this.sharedData = data;
      // if (this.executionId != this.sharedData["currentScriptIndex"]) {
      //   this.executionId = this.sharedData["currentScriptIndex"];
      //   this.getValidationData(this.executionId);
      // }
    });
  }

  socketForValidationAndSummaryStatus() {
    const self = this;
    self.socketSubscriptionForValidateAndSummaryStatus = self.socket
      .listeningFunction("validateAndSummaryStatus")
      .subscribe((data) => {
        if (self.executionId == data["scriptId"]) {
          // self.filterableMappedDataNew=[]
          // self.mappedData={}
          self.totalCount = 0;
          // self.offset = 0;
          self.searchFilter = "";
          self.getValidationScriptsAndSubScripts(self.executionId);
          self.getValidationCounts(self.executionId, "", "", "");
          self.getValidationData(
            self.executionId,
            "",
            "",
            self.limit,
            self.offset,
            self.searchFilter
          );
        }
      });
  }

  ngOnDestroy() {
    this.socketSubscriptionForValidateAndSummaryStatus.unsubscribe();
    this.subscription.unsubscribe();
  }
  public changeView() {
    var self = this;
    self.masked = !self.masked;
    if (self.comparison) {
      self.filter();
    }

    self.currentProperty = self.masked
      ? "diffWithMaskHtml"
      : "diffWithoutMaskHtml";
    self.currentPropertyStatus = self.masked
      ? "diffWithMask_status"
      : "diffWithoutMask_status";

    if (this.filterObject != undefined)
      this.countCalsulation(this.filterObject);
  }

  public updateStatus(transactionId, status) {
    const self = this;
    const updateStatus = { transactionId: transactionId, status: status };

    // Find the index of the existing transaction with the same transactionId
    const index = self.changedTransaction.findIndex(
      (transaction) => transaction.transactionId === transactionId
    );

    // If it exists, remove it
    if (index !== -1) {
      self.changedTransaction.splice(index, 1);
    }
    // Push the new updateStatus object to the array
    else self.changedTransaction.push(updateStatus);
  }

  scrollTop() {
    this.virtualScroll.scrollToIndex(0, "smooth");
    setTimeout(() => {
      this.UpArrow = false;
      this.DownArrow = true;
    }, 500);
  }

  scrollDown() {
    this.virtualScroll.scrollToIndex(4999, "smooth");
    this.virtualScroll.scrollTo({
      bottom: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      this.DownArrow = false;
      this.UpArrow = true;
    }, 500);
  }

  statusFilter() {
    var self = this;
    console.log(self.mappedData);

    if (self.statusFilterValue != "null") {
      var temp = JSON.parse(JSON.stringify(self.mappedData));
      if (self.statusFilterValue == "true") {
        self.mappedData["scriptData"].forEach(function (element, scriptIndex) {
          temp["scriptData"][scriptIndex]["subScriptData"] = JSON.parse(
            JSON.stringify(
              element["subScriptData"].filter(function (ele, index) {
                return ele["transaction_status"];
              })
            )
          );
        });
        // console.log(temp)
      } else {
        self.mappedData["scriptData"].forEach(function (element, scriptIndex) {
          temp["scriptData"][scriptIndex]["subScriptData"] = JSON.parse(
            JSON.stringify(
              element["subScriptData"].filter(function (ele, index) {
                return !ele["transaction_status"];
              })
            )
          );
        });
      }
      self.filterableMappedData = JSON.parse(JSON.stringify(temp));
      this.arrangingSubScript(self.filterableMappedData, false);
    } else {
      self.filterableMappedData = JSON.parse(JSON.stringify(self.mappedData));
      this.arrangingSubScript(self.filterableMappedData, false);
    }
  }
  filterObject: any;
  filterMethod(event) {
    this.filterObject = event;
    // this.dropDownHeading = { name: event.scriptName, count: event.count };
    this.filterableMappedData = JSON.parse(this.backUpdateData);
    if (event.scriptName != "All") {
      this.filterableMappedData["scriptData"][0]["subScriptData"] =
        this.filterableMappedData["scriptData"][0]["subScriptData"].filter(
          (e) => {
            return e.filename == event.parentScript;
          }
        );
      if (event.scriptName != event.parentScript) {
        this.filterableMappedData["scriptData"][0]["subScriptData"][0][
          "array_of_json"
        ] = [
            ...this.filterableMappedData["scriptData"][0]["subScriptData"][0][
              "array_of_json"
            ].filter((e) => {
              return e.subScriptname == event.scriptName;
            }),
          ];
      }
      this.countCalsulation(event);
    }
  }

  countCalsulation(event) {
    this.filterableMappedData["scriptData"][0]["subScriptData"].map((x) => {
      if (event.scriptName == event.parentScript) {
        this.comparisonCount = 0;
        this.errorCount = 0;
        this.dumpCount = 0;
        x.array_of_json.map((y) => {
          y.array_of_json.map((z) => {
            if (this.masked) {
              if (z.diffWithMask != null && z.diffWithMask_status) {
                this.comparisonCount++;
              }
              if (z.errorMatch != null) {
                this.errorCount++;
              }

              if (
                _.has(z["PH1"]["message"]["msgData"], "AB") ||
                _.has(z["PH2"]["message"]["msgData"], "AB")
              ) {
                this.dumpCount++;
              }
            } else {
              if (z.diffWithoutMask != null && z.diffWithoutMask_status) {
                this.comparisonCount++;
              }
              if (z.errorMatch != null) {
                this.errorCount++;
              }
              console.log(z["PH1"]);
              if (
                _.has(z["PH1"]["message"]["msgData"], "AB") ||
                _.has(z["PH2"]["message"]["msgData"], "AB")
              ) {
                this.dumpCount++;
              }
            }
          });
        });
      } else {
        this.comparisonCount = 0;
        this.errorCount = 0;
        this.dumpCount = 0;
        x.array_of_json.map((y) => {
          y.array_of_json.map((z) => {
            if (this.masked) {
              if (z.diffWithMask != null && z.diffWithMask_status) {
                this.comparisonCount++;
              }
              if (z.errorMatch != null) {
                this.errorCount++;
              }
              if (
                _.has(z["PH1"]["message"]["msgData"], "AB") ||
                _.has(z["PH2"]["message"]["msgData"], "AB")
              ) {
                this.dumpCount++;
              }
            } else {
              if (z.diffWithoutMask != null && z.diffWithoutMask_status) {
                this.comparisonCount++;
              }
              if (z.errorMatch != null) {
                this.errorCount++;
              }
              console.log(z["PH1"]);
              if (
                _.has(z["PH1"]["message"]["msgData"], "AB") ||
                _.has(z["PH2"]["message"]["msgData"], "AB")
              ) {
                this.dumpCount++;
              }
            }
          });
        });
      }
    });
  }

  arrangingSubScript(filtered, dropdownChange) {
    var self = this;
    var data = _.groupBy(
      self.filterableMappedData["scriptData"][0]["subScriptData"],
      function (a) {
        return a["PH1"]["message"]["scriptInfo"]["FileName"];
      }
    );
    if (dropdownChange) self.scriptDropdownList = [];

    self.filterableMappedData["scriptData"][0]["subScriptData"] = [];
    let availabeCount = 0;
    Object.keys(data).map((x) => {
      availabeCount = 0;

      var subS = _.groupBy(data[x], function (a) {
        availabeCount++;
        return a["PH1"]["message"]["scriptInfo"]["subScriptName"];
      });
      if (dropdownChange)
        self.scriptDropdownList.push({
          scriptName: x,
          parentScript: x,
          subScript: false,
          count: availabeCount,
        });

      var subscript = [];

      Object.keys(subS).map((y) => {
        availabeCount = 0;
        var subS2 = _.groupBy(subS[y], function (group2) {
          availabeCount++;
          return group2["PH1"]["message"]["scriptInfo"]["subScriptName"];
        });

        if (dropdownChange) {
          if (y != "No Subscript")
            self.scriptDropdownList.push({
              scriptName: y,
              parentScript: x,
              subScript: true,
              count: availabeCount,
            });
        }
        subscript.push({ subScriptname: y, array_of_json: subS[y] });
      });
      self.filterableMappedData["scriptData"][0]["subScriptData"].push({
        filename: x,
        array_of_json: subscript,
      });
    });
    this.backUpdateData = JSON.stringify(self.filterableMappedData);
  }

  public processDiff() {
    const self = this;

    if (
      self.mappedData["scriptData"] &&
      self.mappedData["scriptData"][0]["subScriptData"].length
    ) {
      const subScriptData = self.mappedData["scriptData"][0]["subScriptData"];

      const highlightErrors = (errorMatch, output) => {
        if (errorMatch) {
          errorMatch.forEach((error) => {
            if (error.status) {
              const startIndex = output.indexOf(error.errorMessage);
              if (startIndex !== -1) {
                const endIndex = startIndex + error.errorMessage.length;
                output = `${output.slice(0, startIndex)}<span class='highlight'>${error.errorMessage}</span>${output.slice(endIndex)}`;
              }
            }
          });
        }
        return output;
      };

      const formatDiffHtml = (diffArray) => {
        const diffHtml = { PH1: "", PH2: "" };
        diffArray.forEach(({ added, removed, value }) => {
          if (added) {
            diffHtml.PH2 += `<span class='added'>${value}</span>`;
          } else if (removed) {
            diffHtml.PH1 += `<span class='removed'>${value}</span>`;
          } else {
            diffHtml.PH1 += value;
            diffHtml.PH2 += value;
          }
        });
        return diffHtml;
      };

      const subScriptMap = new Map();

      subScriptData.forEach((element, index) => {
        element.ExpandInput = false;

        if (element.ph1DumpStatus || element.ph2DumpStatus) {
          element.transaction_status = element.transaction_status || false;
        }

        if (element.diffWithoutMask) {
          element.diffWithoutMaskHtml = formatDiffHtml(element.diffWithoutMask);
          element.Validationstatus = true;
        }

        if (element.diffWithMask) {
          element.diffWithMaskHtml = formatDiffHtml(element.diffWithMask);
          element.Validationstatus = true;
        }

        if (element.errorMatch) {
          element.errorHtml = {
            PH1: {
              output: element.PH1.message.msgData.MO?.data.output,
            },
            PH2: {
              output: element.PH2.message.msgData.MO?.data.output,
            },
          };
          element.Validationstatus = false;

          element.errorHtml.PH1.output = highlightErrors(element.errorMatch.PH1, element.errorHtml.PH1.output);
          element.errorHtml.PH2.output = highlightErrors(element.errorMatch.PH2, element.errorHtml.PH2.output);
        }

        subScriptMap.set(index, element);
      });

      self.filterableMappedDataNew = [
        ...self.filterableMappedDataNew,
        ...Array.from(subScriptMap.values()),
      ];
    }

    this.validationLoading = false;

    self.filterableMappedData = JSON.parse(JSON.stringify(self.mappedData));
  }

  public filter() {
    console.log("inside filter");
    var self = this;

    if (self.comparison == true || self.error == true || self.dump == true) {
      self.all = false;
    }
    if (!self.comparison && !self.error && !self.dump) {
      self.all = true;
    }

    if (self.all) {
      self.filterableMappedData = JSON.parse(JSON.stringify(self.mappedData));
      self.arrangingSubScript(self.filterableMappedData, false);
      console.log(self.filterObject);
      if (self.filterObject != undefined) self.filterMethod(self.filterObject);
    } else if (!self.all && self.comparison && !self.error && !self.dump) {
      if (self.masked) {
        self.mappedData["scriptData"].forEach(function (element, index) {
          if (self.filterableMappedData["scriptData"][index] == undefined) {
            self.filterableMappedData["scriptData"][index] = {};
          }
          self.filterableMappedData["scriptData"][index]["subScriptData"] =
            JSON.parse(
              JSON.stringify(
                self.mappedData["scriptData"][index]["subScriptData"].filter(
                  function (ele, index) {
                    if (self.dropDownHeading.name == "All")
                      return (
                        ele.diffWithMask != null && ele.diffWithMask_status
                      );
                    if (self.dropDownHeading.name != "All") {
                      if (
                        ele.PH1.message.scriptInfo.FileName ==
                        self.dropDownHeading.name
                      ) {
                        return (
                          ele.diffWithMask != null &&
                          ele.diffWithMask_status &&
                          ele.PH1.message.scriptInfo.FileName ==
                          self.dropDownHeading.name
                        );
                      } else {
                        return (
                          ele.diffWithMask != null &&
                          ele.diffWithMask_status &&
                          ele.PH1.message.scriptInfo.subScriptName ==
                          self.dropDownHeading.name
                        );
                      }
                    }
                  }
                )
              )
            );
        });
      } else if (!self.masked) {
        self.mappedData["scriptData"].forEach(function (element, index) {
          if (self.filterableMappedData["scriptData"][index] == undefined) {
            self.filterableMappedData["scriptData"][index] = {};
          }
          self.filterableMappedData["scriptData"][index]["subScriptData"] =
            JSON.parse(
              JSON.stringify(
                self.mappedData["scriptData"][index]["subScriptData"].filter(
                  function (ele, index) {
                    if (self.dropDownHeading.name == "All")
                      return (
                        ele.diffWithoutMask != null &&
                        ele.diffWithoutMask_status
                      );

                    if (self.dropDownHeading.name != "All") {
                      if (
                        ele.PH1.message.scriptInfo.FileName ==
                        self.dropDownHeading.name
                      ) {
                        return (
                          ele.diffWithoutMask != null &&
                          ele.diffWithoutMask_status &&
                          ele.PH1.message.scriptInfo.FileName ==
                          self.dropDownHeading.name
                        );
                      } else {
                        return (
                          ele.diffWithoutMask != null &&
                          ele.diffWithoutMask_status &&
                          ele.PH1.message.scriptInfo.subScriptName ==
                          self.dropDownHeading.name
                        );
                      }
                    }
                  }
                )
              )
            );
        });
      }
    } else if (!self.all && self.error && !self.comparison && !self.dump) {
      self.mappedData["scriptData"].forEach(function (element, index) {
        if (self.filterableMappedData["scriptData"][index] == undefined) {
          self.filterableMappedData["scriptData"][index] = {};
        }
        self.filterableMappedData["scriptData"][index]["subScriptData"] =
          JSON.parse(
            JSON.stringify(
              self.mappedData["scriptData"][index]["subScriptData"].filter(
                function (ele, index) {
                  if (self.dropDownHeading.name == "All")
                    return ele.errorMatch != null;
                  if (self.dropDownHeading.name != "All") {
                    if (
                      ele.PH1.message.scriptInfo.FileName ==
                      self.dropDownHeading.name
                    ) {
                      return (
                        ele.errorMatch != null &&
                        ele.PH1.message.scriptInfo.FileName ==
                        self.dropDownHeading.name
                      );
                    } else {
                      return (
                        ele.errorMatch != null &&
                        ele.PH1.message.scriptInfo.subScriptName ==
                        self.dropDownHeading.name
                      );
                    }
                  }
                }
              )
            )
          );
      });
    } else if (!self.all && !self.error && !self.comparison && self.dump) {
      self.mappedData["scriptData"].forEach(function (element, index) {
        if (self.filterableMappedData["scriptData"][index] == undefined) {
          self.filterableMappedData["scriptData"][index] = {};
        }
        self.filterableMappedData["scriptData"][index]["subScriptData"] =
          JSON.parse(
            JSON.stringify(
              self.mappedData["scriptData"][index]["subScriptData"].filter(
                function (ele, index) {
                  if (self.dropDownHeading.name == "All")
                    if (
                      _.has(ele["PH1"]["message"]["msgData"], "AB") ||
                      _.has(ele["PH2"]["message"]["msgData"], "AB")
                    ) {
                      return ele;
                    }

                  if (self.dropDownHeading.name != "All") {
                    if (
                      ele.PH1.message.scriptInfo.FileName ==
                      self.dropDownHeading.name
                    ) {
                      if (
                        _.has(ele["PH1"]["message"]["msgData"], "AB") ||
                        _.has(ele["PH2"]["message"]["msgData"], "AB")
                      ) {
                        return (
                          ele &&
                          ele.PH1.message.scriptInfo.FileName ==
                          self.dropDownHeading.name
                        );
                      }
                    } else {
                      if (
                        _.has(ele["PH1"]["message"]["msgData"], "AB") ||
                        _.has(ele["PH2"]["message"]["msgData"], "AB")
                      ) {
                        return (
                          ele &&
                          ele.PH1.message.scriptInfo.subScriptName ==
                          self.dropDownHeading.name
                        );
                      }
                    }
                  }
                }
              )
            )
          );
      });
    } else if (!self.all && self.comparison && self.error && !self.dump) {
      self.mappedData["scriptData"].forEach(function (element, index) {
        if (self.filterableMappedData["scriptData"][index] == undefined) {
          self.filterableMappedData["scriptData"][index] = {};
        }

        if (self.masked) {
          self.filterableMappedData["scriptData"][index]["subScriptData"] =
            JSON.parse(
              JSON.stringify(
                self.mappedData["scriptData"][index]["subScriptData"].filter(
                  function (ele, index) {
                    if (self.dropDownHeading.name == "All")
                      return (
                        (ele.diffWithMask != null && ele.diffWithMask_status) ||
                        ele.errorMatch != null
                      );

                    if (self.dropDownHeading.name != "All") {
                      if (
                        ele.PH1.message.scriptInfo.FileName ==
                        self.dropDownHeading.name
                      ) {
                        return (
                          (ele.diffWithMask != null &&
                            ele.diffWithMask_status &&
                            ele.PH1.message.scriptInfo.FileName ==
                            self.dropDownHeading.name) ||
                          (ele.errorMatch != null &&
                            ele.PH1.message.scriptInfo.FileName ==
                            self.dropDownHeading.name)
                        );
                      } else {
                        return (
                          (ele.diffWithMask != null &&
                            ele.diffWithMask_status &&
                            ele.PH1.message.scriptInfo.subScriptName ==
                            self.dropDownHeading.name) ||
                          (ele.errorMatch != null &&
                            ele.PH1.message.scriptInfo.subScriptName ==
                            self.dropDownHeading.name)
                        );
                      }
                    }
                  }
                )
              )
            );
        }
        if (!self.masked) {
          self.filterableMappedData["scriptData"][index]["subScriptData"] =
            JSON.parse(
              JSON.stringify(
                self.mappedData["scriptData"][index]["subScriptData"].filter(
                  function (ele, index) {
                    if (self.dropDownHeading.name == "All")
                      return (
                        (ele.diffWithoutMask != null &&
                          ele.diffWithoutMask_status) ||
                        ele.errorMatch != null
                      );

                    if (self.dropDownHeading.name != "All") {
                      if (
                        ele.PH1.message.scriptInfo.FileName ==
                        self.dropDownHeading.name
                      ) {
                        return (
                          (ele.diffWithoutMask != null &&
                            ele.diffWithoutMask_status &&
                            ele.PH1.message.scriptInfo.FileName ==
                            self.dropDownHeading.name) ||
                          (ele.errorMatch != null &&
                            ele.PH1.message.scriptInfo.FileName ==
                            self.dropDownHeading.name)
                        );
                      } else {
                        return (
                          (ele.diffWithoutMask != null &&
                            ele.diffWithoutMask_status &&
                            ele.PH1.message.scriptInfo.subScriptName ==
                            self.dropDownHeading.name) ||
                          (ele.errorMatch != null &&
                            ele.PH1.message.scriptInfo.subScriptName ==
                            self.dropDownHeading.name)
                        );
                      }
                    }
                  }
                )
              )
            );
        }

        // console.log(self.filterableMappedData["scriptData"][index]["subScriptData"]);
      });
    } else if (!self.all && self.comparison && !self.error && self.dump) {
      self.mappedData["scriptData"].forEach(function (element, index) {
        if (self.filterableMappedData["scriptData"][index] == undefined) {
          self.filterableMappedData["scriptData"][index] = {};
        }
        if (self.masked) {
          self.filterableMappedData["scriptData"][index]["subScriptData"] =
            JSON.parse(
              JSON.stringify(
                self.mappedData["scriptData"][index]["subScriptData"].filter(
                  function (ele, index) {
                    let FileName = ele.PH1.message.scriptInfo.FileName;
                    let subScriptName =
                      ele.PH1.message.scriptInfo.subScriptName;
                    if (self.dropDownHeading.name == "All") {
                      return (
                        _.has(ele["PH1"], "AB") ||
                        _.has(ele["PH2"], "AB") ||
                        (ele.diffWithMask != null && ele.diffWithMask_status)
                      );
                    } else {
                      if (FileName == self.dropDownHeading.name) {
                        return (
                          (_.has(ele["PH1"], "AB") &&
                            FileName == self.dropDownHeading.name) ||
                          (_.has(ele["PH2"], "AB") &&
                            FileName == self.dropDownHeading.name) ||
                          (ele.diffWithMask != null &&
                            ele.diffWithMask_status &&
                            FileName == self.dropDownHeading.name)
                        );
                      } else {
                        return (
                          (_.has(ele["PH1"], "AB") &&
                            subScriptName == self.dropDownHeading.name) ||
                          (_.has(ele["PH2"], "AB") &&
                            subScriptName == self.dropDownHeading.name) ||
                          (ele.diffWithMask != null &&
                            ele.diffWithMask_status &&
                            subScriptName == self.dropDownHeading.name)
                        );
                      }
                    }
                  }
                )
              )
            );
        }
        if (!self.masked) {
          self.filterableMappedData["scriptData"][index]["subScriptData"] =
            JSON.parse(
              JSON.stringify(
                self.mappedData["scriptData"][index]["subScriptData"].filter(
                  function (ele, index) {
                    let FileName = ele.PH1.message.scriptInfo.FileName;
                    let subScriptName =
                      ele.PH1.message.scriptInfo.subScriptName;
                    if (self.dropDownHeading.name == "All") {
                      return (
                        _.has(ele["PH1"], "AB") ||
                        _.has(ele["PH2"], "AB") ||
                        (ele.diffWithoutMask != null &&
                          ele.diffWithoutMask_status)
                      );
                    } else {
                      if (FileName == self.dropDownHeading.name) {
                        return (
                          (_.has(ele["PH1"], "AB") &&
                            FileName == self.dropDownHeading.name) ||
                          (_.has(ele["PH2"], "AB") &&
                            FileName == self.dropDownHeading.name) ||
                          (ele.diffWithoutMask != null &&
                            ele.diffWithoutMask_status &&
                            FileName == self.dropDownHeading.name)
                        );
                      } else {
                        return (
                          (_.has(ele["PH1"], "AB") &&
                            subScriptName == self.dropDownHeading.name) ||
                          (_.has(ele["PH2"], "AB") &&
                            subScriptName == self.dropDownHeading.name) ||
                          (ele.diffWithoutMask != null &&
                            ele.diffWithoutMask_status &&
                            subScriptName == self.dropDownHeading.name)
                        );
                      }
                    }
                  }
                )
              )
            );
        }
      });
    } else if (!self.all && !self.comparison && self.error && self.dump) {
      self.mappedData["scriptData"].forEach(function (element, index) {
        if (self.filterableMappedData["scriptData"][index] == undefined) {
          self.filterableMappedData["scriptData"][index] = {};
        }
        self.filterableMappedData["scriptData"][index]["subScriptData"] =
          JSON.parse(
            JSON.stringify(
              self.mappedData["scriptData"][index]["subScriptData"].filter(
                function (ele, index) {
                  let FileName = ele.PH1.message.scriptInfo.FileName;
                  let subScriptName = ele.PH1.message.scriptInfo.subScriptName;
                  if (self.dropDownHeading.name == "All") {
                    return (
                      _.has(ele["PH1"], "AB") ||
                      _.has(ele["PH2"], "AB") ||
                      ele.errorMatch != null
                    );
                  } else {
                    if (FileName == self.dropDownHeading.name) {
                      return (
                        (_.has(ele["PH1"], "AB") &&
                          FileName == self.dropDownHeading.name) ||
                        (_.has(ele["PH2"], "AB") &&
                          FileName == self.dropDownHeading.name) ||
                        (ele.errorMatch != null &&
                          FileName == self.dropDownHeading.name)
                      );
                    } else {
                      return (
                        (_.has(ele["PH1"], "AB") &&
                          subScriptName == self.dropDownHeading.name) ||
                        (_.has(ele["PH2"], "AB") &&
                          subScriptName == self.dropDownHeading.name) ||
                        (ele.errorMatch != null &&
                          subScriptName == self.dropDownHeading.name)
                      );
                    }
                  }
                }
              )
            )
          );
      });
    } else if (!self.all && self.comparison && self.error && self.dump) {
      self.mappedData["scriptData"].forEach(function (element, index) {
        if (self.filterableMappedData["scriptData"][index] == undefined) {
          self.filterableMappedData["scriptData"][index] = {};
        }
        if (self.masked) {
          self.filterableMappedData["scriptData"][index]["subScriptData"] =
            JSON.parse(
              JSON.stringify(
                self.mappedData["scriptData"][index]["subScriptData"].filter(
                  function (ele, index) {
                    let FileName = ele.PH1.message.scriptInfo.FileName;
                    let subScriptName =
                      ele.PH1.message.scriptInfo.subScriptName;
                    if (self.dropDownHeading.name == "All") {
                      return (
                        _.has(ele["PH1"], "AB") ||
                        _.has(ele["PH2"], "AB") ||
                        ele.errorMatch != null ||
                        (ele.diffWithMask != null && ele.diffWithMask_status)
                      );
                    } else {
                      if (FileName == self.dropDownHeading.name) {
                        return (
                          (_.has(ele["PH1"], "AB") &&
                            FileName == self.dropDownHeading.name) ||
                          (_.has(ele["PH2"], "AB") &&
                            FileName == self.dropDownHeading.name) ||
                          (ele.errorMatch != null &&
                            FileName == self.dropDownHeading.name) ||
                          (ele.diffWithMask != null &&
                            ele.diffWithMask_status &&
                            FileName == self.dropDownHeading.name)
                        );
                      } else {
                        return (
                          (_.has(ele["PH1"], "AB") &&
                            subScriptName == self.dropDownHeading.name) ||
                          (_.has(ele["PH2"], "AB") &&
                            subScriptName == self.dropDownHeading.name) ||
                          (ele.errorMatch != null &&
                            subScriptName == self.dropDownHeading.name) ||
                          (ele.diffWithMask != null &&
                            ele.diffWithMask_status &&
                            subScriptName == self.dropDownHeading.name)
                        );
                      }
                    }
                  }
                )
              )
            );
        }
        if (!self.masked) {
          self.filterableMappedData["scriptData"][index]["subScriptData"] =
            JSON.parse(
              JSON.stringify(
                self.mappedData["scriptData"][index]["subScriptData"].filter(
                  function (ele, index) {
                    let FileName = ele.PH1.message.scriptInfo.FileName;
                    let subScriptName =
                      ele.PH1.message.scriptInfo.subScriptName;
                    if (self.dropDownHeading.name == "All") {
                      return (
                        _.has(ele["PH1"], "AB") ||
                        _.has(ele["PH2"], "AB") ||
                        ele.errorMatch != null ||
                        (ele.diffWithoutMask != null &&
                          ele.diffWithoutMask_status)
                      );
                    } else {
                      if (FileName == self.dropDownHeading.name) {
                        return (
                          (_.has(ele["PH1"], "AB") &&
                            FileName == self.dropDownHeading.name) ||
                          (_.has(ele["PH2"], "AB") &&
                            FileName == self.dropDownHeading.name) ||
                          (ele.errorMatch != null &&
                            FileName == self.dropDownHeading.name) ||
                          (ele.diffWithoutMask != null &&
                            ele.diffWithoutMask_status &&
                            FileName == self.dropDownHeading.name)
                        );
                      } else {
                        return (
                          (_.has(ele["PH1"], "AB") &&
                            subScriptName == self.dropDownHeading.name) ||
                          (_.has(ele["PH2"], "AB") &&
                            subScriptName == self.dropDownHeading.name) ||
                          (ele.errorMatch != null &&
                            subScriptName == self.dropDownHeading.name) ||
                          (ele.diffWithoutMask != null &&
                            ele.diffWithoutMask_status &&
                            subScriptName == self.dropDownHeading.name)
                        );
                      }
                    }
                  }
                )
              )
            );
        }
      });
    }

    self.filterableMappedData["scriptData"] = JSON.parse(
      JSON.stringify(
        _.reject(
          self.filterableMappedData["scriptData"],
          function (element, index) {
            if (_.isEmpty(element["subScriptData"])) {
              return true;
            } else {
              return false;
            }
          }
        )
      )
    );

    console.log("self.filterableMappedData--->", self.filterableMappedData);
    if (self.filterableMappedData["scriptData"].length > 0 && !self.all)
      self.arrangingSubScript(self.filterableMappedData, false);
  }

  public isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public showSelected() {
    var self = this;
    var selected = self.selectedSubscript.filter((a) => {
      return a;
    });
    self.noOfSelectedSubscript = selected.length;
  }

  public validationSubmit() {
    var self = this;
    let snackBarRef = self._snackBar.openFromComponent(LoadingComponent, {
      data: "Validating the Input transactions",
      panelClass: ["_snackBar"],
    });
    self.mappedData["scriptReviewed"] = true;
    self.filterableMappedData["scriptData"][0]["subScriptData"] =
      self.filterableMappedDataNew;
    var params = {
      url: "scripts/validationsubmit/" + self.currentScript._id,
      data: self.changedTransaction,
    };
    self.httpAdapter.httpPut(params, function (err, res) {
      if (err) {
        console.log(err);
      } else {
        self.mappedData["validationMsg"] = res.body.validationMsg;
        if (res.status == 200) {
          new AppComponent(self._snackBar).showSnackBar(
            res.body.message,
            "success"
          );
          self.validationStatusEmitter.emit(res.body.validationMsg);
          snackBarRef.dismiss();
          self.changedTransaction = [];
        }
      }
    });
  }
  public displayFormatedOutput(data) {
    try {
      // console.log("JSON format",data)
      if (_.isObject(JSON.parse(data))) {
        // console.log("isJson")
        return JSON.stringify(JSON.parse(data), null, 4);
      } else {
        return data.toString();
      }
    } catch (error) {
      return data.toString();
    }
  }

  validationLoading = false;
  public getValidationData(
    id,
    fileName,
    subScript,
    limit,
    offset,
    transactionName
  ) {
    this.validationLoading = true;
    var self = this;

    var params = {
      url: `scripts/validation/${id}?limit=${limit}&offset=${offset}&scriptName=${fileName}&subScript=${subScript}&compareStatus=${this.comparison ? this.comparison : ""
        }&errorStatus=${this.error ? this.error : ""}&dumpStatus=${this.dump ? this.dump : ""
        }&validationStatus=${this.all
          ? this.statusFilterValue != "null"
            ? this.statusFilterValue
            : ""
          : ""
        }&transactionName=${transactionName}`,
    };

    self.httpAdapter.httpGet(
      params,
      function (validationRequestErr, Validation_Result) {
        if (!validationRequestErr) {
          console.log("Validation_Result", Validation_Result);
          self.mappedData =
            Validation_Result.body["validationData"]["validationData"][0];
          self.mappedData["validated"] =
            Validation_Result.body["validationData"]["validated"];
          self.mappedData["scriptReviewed"] =
            Validation_Result.body["validationData"]["scriptReviewed"];
          self.mappedData["validationMsg"] =
            Validation_Result.body["validationData"]["validationMsg"];
          self.filterableMappedData = JSON.stringify(self.mappedData);
          self.offset +=
            self.mappedData["scriptData"][0]["subScriptData"].length;
          self.processDiff();
        }
        self.validationLoading = false
      }
    );
    // }
  }
  tranckBy(index, item) {
    return item.PH1.message.productHeader.fileType;
  }
  getExpandStatus(input) {
    return input.split("\n").length > 1 ? true : false;
  }

  limit = 100;
  offset = 0;

  loadMoreData(transId) {
    // const transId = parseInt(tnxId.substr(2, 6), 16);

    //   this.ph1Offset += this.ph1Limit;
    //   this.ph2Offset += this.ph2Limit;
    if (
      !this.validationLoading && (transId === this.filterableMappedDataNew.length - 50 ||
        transId === this.offset)
    ) {
      this.getValidationData(
        this.executionId,
        this.dropDownHeading.scriptName,
        this.dropDownHeading.subScript,
        this.limit,
        this.offset,
        this.searchFilter
      );
    }
  }
  validationcount;
  totalCount = 0;
  scriptData = [];

  getValidationCounts(id, fileName, scriptName, subScript) {
    const param = {
      url: `validation/getValidationCount?id=${id}&fileName=${scriptName}&subScript=${subScript}`,
    };
    this.httpAdapter.httpGetNew(param).subscribe((data: any) => {
      this.totalCount === 0
        ? (this.totalCount = data.body.total)
        : this.totalCount;
      this.validationcount = data.body;
    });
  }

  getValidationScriptsAndSubScripts(id) {
    const param = {
      url: `validation/getValidationScriptsAndSubScripts?id=${id}`,
    };
    this.httpAdapter.httpGetNew(param).subscribe((data: any) => {
      this.scriptData = data.body;
    });
  }

  filterValidation(event) {
    this.filterableMappedDataNew = [];
    this.dropDownHeading = {
      name: event.FileName,
      count: event.count,
      scriptName: event.scriptName,
      subScript: event.subScript,
    };
    this.offset = 0;
    this.getValidationCounts(
      this.executionId,
      event.FileName,
      event.scriptName,
      event.subScript
    );
    this.getValidationData(
      this.executionId,
      event.scriptName,
      event.subScript,
      this.limit,
      0,
      this.searchFilter
    );
  }
  filterComparision() {
    this.comparison = !this.comparison;

    if (!this.comparison && !this.error && !this.dump) {
      this.all = true;
    } else {
      this.all = false;
    }
    this.filterableMappedDataNew = [];
    this.offset = 0;
    this.getValidationData(
      this.executionId,
      this.dropDownHeading.scriptName,
      this.dropDownHeading.subScript,
      this.limit,
      0,
      this.searchFilter
    );
  }
  filterErrors() {
    this.error = !this.error;

    if (!this.comparison && !this.error && !this.dump) {
      this.all = true;
    } else {
      this.all = false;
    }
    this.filterableMappedDataNew = [];
    this.offset = 0;
    this.getValidationData(
      this.executionId,
      this.dropDownHeading.scriptName,
      this.dropDownHeading.subScript,
      this.limit,
      0,
      this.searchFilter
    );
  }
  filterDumps() {
    this.dump = !this.dump;

    if (!this.comparison && !this.error && !this.dump) {
      this.all = true;
    } else {
      this.all = false;
    }
    this.filterableMappedDataNew = [];
    this.offset = 0;
    this.getValidationData(
      this.executionId,
      this.dropDownHeading.scriptName,
      this.dropDownHeading.subScript,
      this.limit,
      0,
      this.searchFilter
    );
  }

  filterValidationStatus() {
    this.filterableMappedDataNew = [];
    this.offset = 0;
    this.getValidationData(
      this.executionId,
      this.dropDownHeading.scriptName,
      this.dropDownHeading.subScript,
      this.limit,
      0,
      this.searchFilter
    );
  }
  searchTransaction(transactionName) {
    this.filterableMappedDataNew = [];
    this.offset = 0;
    this.getValidationData(
      this.executionId,
      this.dropDownHeading.scriptName,
      this.dropDownHeading.subScript,
      this.limit,
      0,
      transactionName
    );
  }
  onScroll(index: string, event: Event): void {
    const target = event.target as HTMLElement;
    const otherContainer = document.getElementById(index);
    otherContainer.scrollLeft = target.scrollLeft;
    otherContainer.scrollTop = target.scrollTop;
  }
}
