import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'configSearch'
})
export class ConfigSearchPipe implements PipeTransform {

  transform(value: any, searchString: String): any {
    var data = JSON.parse(JSON.stringify(value))

    let requireData = data.filter((config) => config.scriptName.toLowerCase().includes(searchString.toLowerCase()))
    if (requireData.length == 0) { requireData = ['No search result'] }
    return requireData;
  };
}



