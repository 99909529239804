import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, timeout } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpAdapterService {
  public GiBase = "http://localhost:9844/";
  subscription: any;
  constructor(private http: HttpClient) { }

  public httpGet(params, get_clbk) {
    this.http
      .get(`${environment.apiPath}` + params.url, {
        observe: "response",
        withCredentials: true,
      })
      .subscribe(
        (response) => {
          return get_clbk(null, response);
        },
        (err) => {
          return get_clbk(err);
        }
      );
  }
  public httpGetNew(params) {
    return this.http.get(
      params.system
        ? `${environment.apiPath}${params.system}/` + params.url
        : `${environment.apiPath}` + params.url,
      {
        observe: "response",
        withCredentials: true,
      }
    );
  }

  postData(params: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiPath}login`, params.data, {
      observe: "response",
    });
  }

  public httpPostNew(params): any {
    const url = params.system
      ? `${environment.apiPath}${params.system}/` + params.url
      : `${environment.apiPath}` + params.url;
    return this.http.post(url, params.data);
  }

  public httpPost(params, post_clbk) {
    var options = { withCredentials: true };
    if (params.url == "logout") {
      options["responseType"] = "application/text";
    } else {
      options["observe"] = "response";
      options["withCredentials"] = true;
    }
    const url = params.system
      ? `${environment.apiPath}${params.system}/` + params.url
      : `${environment.apiPath}` + params.url;
    this.http.post(url, params.data, options).subscribe(
      (response) => {
        console.log(response);
        return post_clbk(null, response);
      },
      (err) => {
        return post_clbk(err);
      }
    );
  }

  public httpPut(params, put_clbk) {
    this.http
      .put(`${environment.apiPath}` + params.url, params.data, {
        observe: "response",
        withCredentials: true,
      })
      .subscribe(
        (response) => {
          return put_clbk(null, response);
        },
        (err) => {
          return put_clbk(err);
        }
      );
  }

  public httpPostFileGet(params, post_clbk) {
    const url = params.system
      ? `${environment.apiPath}${params.system}/` + params.url
      : `${environment.apiPath}` + params.url;
    console.log("Plog URL", url, params.system);
    this.http
      .post(url, params.data, {
        withCredentials: true,
        responseType: "blob",
      })
      .subscribe(
        (response) => {
          return post_clbk(null, response);
        },
        (err) => {
          return post_clbk(err);
        }
      );
  }

  public httpGiPost(params, post_clbk) {
    var self = this;

    this.http.post(self.GiBase + params.url, params.data).subscribe(
      (response) => {
        return post_clbk(null, response);
      },
      (err) => {
        console.log(err);
        return post_clbk(err);
      }
    );
  }

  public upload(params, file) {
    const formData = new FormData();
    // Create form data
    if (file.length > 0) {
      Object.keys(file).forEach(function (ele, index) {
        formData.append("file" + ele, file[ele], file[ele]["name"]);
      });
    } else {
      formData.append("file", file, file.name);
    }
    const url = params.system
      ? `${environment.apiPath}${params.system}/` + params.url
      : `${environment.apiPath}` + params.url;
    // Store form name as "file" with file data

    // Make http post request over api
    // with formData as req
    return this.http.post(
      url,
      formData
      // ,{
      //   headers:new HttpHeaders({
      //   'userName': localStorage.getItem('userName')
      // }) }
    );
  }

  // public httpJenkinsGet(params,get_clbk){
  //   var self = this;
  //   let headers = new HttpHeaders({
  //     'Access-Control-Allow-Origin':'*',
  //     'Content-Type': 'application/json',
  //     });
  //   this.http.get(self.jenkinsBaseLink+params.url,{headers:headers})
  //   .subscribe(
  //     response=>{
  //       return get_clbk(null,response)
  //     },err=>{
  //       console.log(err)
  //       return get_clbk(err)
  //     }
  //   )
  // }

  /**
   * Reference about HTTP # https://angular.io/guide/http
   */

  public httpSimulationPost(params, post_clbk) {
    const httpOptions = {
      headers: new HttpHeaders(params.headers),
    };
    httpOptions["observe"] = "response";
    this.http.post(params.url, params.data, httpOptions).subscribe(
      (response) => {
        console.log("httpSimulationPost Response ", response);
        return post_clbk(null, response);
      },
      (err) => {
        return post_clbk(err);
      }
    );
  }

  public httpSimulationGet(params, get_clbk) {
    const httpOptions = {
      headers: new HttpHeaders(params.headers),
    };
    httpOptions["observe"] = "response";
    this.http
      .get(params.url, httpOptions)
      .pipe(
        timeout(5000),
        catchError((err) => {
          return throwError("Timeout has occurred");
        })
      )
      .subscribe(
        (response) => {
          console.log("Response in httpSimulationGet --->", response);
          return get_clbk(null, response);
        },
        (err) => {
          return get_clbk(err);
        }
      );
  }

  public httpSimulationPut(params, put_clbk) {
    const httpOptions = {
      headers: new HttpHeaders(params.headers),
    };
    httpOptions["observe"] = "response";
    this.http.put(params.url, params.data, httpOptions).subscribe(
      (response) => {
        console.log("httpSimulationPut Response ", response);
        return put_clbk(null, response);
      },
      (err) => {
        return put_clbk(err);
      }
    );
  }

  public httpSimulationDelete(params, get_clbk) {
    const httpOptions = {
      headers: new HttpHeaders(params.headers),
    };
    httpOptions["observe"] = "response";
    this.http.delete(params.url, httpOptions).subscribe(
      (response) => {
        console.log("Response in httpSimulationGet --->", response);
        return get_clbk(null, response);
      },
      (err) => {
        return get_clbk(err);
      }
    );
  }

  public httpSimulationPatch(params, put_clbk) {
    const httpOptions = {
      headers: new HttpHeaders(params.headers),
    };
    httpOptions["observe"] = "response";
    this.http.patch(params.url, params.data, httpOptions).subscribe(
      (response) => {
        console.log("httpSimulationPut Response ", response);
        return put_clbk(null, response);
      },
      (err) => {
        return put_clbk(err);
      }
    );
  }
}
