<form [formGroup]="PhasesForm">
  <!-- PHASE 1 -->
  <div
    class="row mb-2"
    *ngIf="PhasesForm.get('PHASE1') || PhasesForm.get('PHASE2')"
  >
    <div
      *ngIf="PhasesForm.get('PHASE1')"
      class="form-group col-md-6 d-flex align-items-center"
      formGroupName="PHASE1"
    >
      <label class="col-4 col-form-label">
        PHASE 1: VPARS
        <div *ngIf="newKeys.includes('PHASE1')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div class="col-8">
        <div *ngIf="!phase1?.valid">
          <div class="text-danger" *ngIf="phase1.errors?.required">
            <p>Phase1 is required</p>
          </div>
          <div class="text-danger" *ngIf="phase1.errors?.state">
            <p>Select an option from the dropdown</p>
          </div>
        </div>
        <nz-select
          class="col-12 p-0"
          nzShowSearch
          nzAllowClear
          formControlName="VPARS_NAME"
          nzPlaceHolder="Select a VPARS"
        >
          <nz-option
            *ngFor="let transaction of options; trackBy: trackByFn"
            [nzLabel]="transaction"
            [nzValue]="transaction"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <!-- PHASE 2 -->
    <div
      *ngIf="PhasesForm.get('PHASE2')"
      class="form-group col-md-6 d-flex align-items-center"
      formGroupName="PHASE2"
    >
      <label class="col-4 col-form-label">
        PHASE 2: VPARS
        <div *ngIf="newKeys.includes('PHASE2')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </label>
      <div class="col-8">
        <div *ngIf="!phase2?.valid">
          <div class="text-danger" *ngIf="phase2.errors?.required">
            <p>Phase2 is required</p>
          </div>
          <div class="text-danger" *ngIf="phase2.errors?.state">
            <p>Select an option from the dropdown</p>
          </div>
        </div>
        <nz-select
          class="col-12 p-0"
          nzShowSearch
          nzAllowClear
          formControlName="VPARS_NAME"
          nzPlaceHolder="Select a VPARS"
        >
          <nz-option
            *ngFor="let transaction of options; trackBy: trackByFn"
            [nzLabel]="transaction"
            [nzValue]="transaction"
          ></nz-option>
        </nz-select>
      </div>
    </div>
  </div>
</form>
