import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'underscore';

@Component({
  selector: 'app-subscriptcontainer',
  templateUrl: './subscriptcontainer.component.html',
  styleUrls: ['./subscriptcontainer.component.scss']
})
export class SubscriptcontainerComponent {


  @Input() PHData = [];
  @Input() filteredScript = "";
  @Input() aciveSubScript = "";
  @Input() phase = "";
  @Output() loadMoreDataEmitter = new EventEmitter();
  @Output() loadSubScriptEmitter = new EventEmitter();
  @Output() TraceDataEmitter = new EventEmitter();
  trackByFn(index, item) {
    return index; // Assuming your item has a unique 'id' property
  }
  public isJson(data) {
    try {
      if (_.isObject(JSON.parse(data))) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  public displayFormatedOutput(data) {
    try {
      if (_.isObject(JSON.parse(data))) {
        return JSON.stringify(JSON.parse(data), null, 4);
      } else {
        return data;
      }
    } catch (error) {
      return data;
    }
  }

  loadMoreData(trns) {
    this.loadMoreDataEmitter.emit(trns);

  } loadSubScript(trns) {
    this.loadSubScriptEmitter.emit(trns);

  }

  moveToTrace(trans) {
    this.TraceDataEmitter.emit(trans)
  }
  onScroll(index: string, event: Event): void {
    const target = event.target as HTMLElement;
    const otherContainer = document.getElementById(index);
    otherContainer.scrollLeft = target.scrollLeft;
    otherContainer.scrollTop = target.scrollTop;
  }
}
