import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";
import { AppComponent } from "src/app/app.component";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";

@Component({
  selector: "app-execution-card",
  templateUrl: "./execution-card.component.html",
  styleUrls: ["./execution-card.component.scss"],
})
export class ExecutionCardComponent implements OnInit {
  constructor(
    public socket: SocketioClientServices,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}
  @Input() script;
  @Input() index;
  @Input() sharedData;
  @Input() currentScriptIndex;
  @Input() selectedSystem;
  @Output() saveCheckedScriptEmit = new EventEmitter();
  @Output() deletingSelectedScriptEmit = new EventEmitter();
  @Output() downloadSummaryReportsEmit = new EventEmitter();
  @Output() downloadDumpReportsEmit = new EventEmitter();
  @Output() downloadRunTimeReportsEmit = new EventEmitter();
  @Output() getiRTFLogEmit = new EventEmitter();
  @Output() getNetworkLogEmitter = new EventEmitter();
  @Output() onDemandValidationEmitter = new EventEmitter();
  @Output() getTraceLogEmitter = new EventEmitter();
  @Output() getInputFileEmitter = new EventEmitter();
  @Output() downloadPLOGReportsEmit = new EventEmitter();

  getInputFile(event) {
    this.getInputFileEmitter.emit(event);
  }
  saveCheckedScript(event) {
    this.saveCheckedScriptEmit.emit(event);
  }
  deletingSelectedScript(event) {
    this.deletingSelectedScriptEmit.emit(event);
  }
  downloadSummaryReports(event) {
    this.downloadSummaryReportsEmit.emit(event);
  }
  downloadDumpReports(event) {
    this.downloadDumpReportsEmit.emit(event);
  }
  downloadPLOGReports(event) {
    this.downloadPLOGReportsEmit.emit(event);
  }
  downloadRunTimeReports(event) {
    this.downloadRunTimeReportsEmit.emit(event);
  }
  getNetworkLog(event) {
    this.getNetworkLogEmitter.emit(event);
  }
  getTraceLog(event) {
    this.getTraceLogEmitter.emit(event);
  }

  getiRTFLog(event) {
    this.getiRTFLogEmit.emit(event);
  }

  onDemandValidation(event) {
    this.onDemandValidationEmitter.emit(event);
  }

  public scriptUser(scriptuser) {
    return scriptuser.padEnd(8).toUpperCase();
  }
  ngOnInit() {
    if (this.script["progress"] == "PROGRESS") this.socketFunction();

    this.listenforLogInfo();
    const self = this;
    self.socketSubscriptionForLogStatus = self.socket
      .listeningFunction("logStatus")
      .subscribe((data) => {
        if (this.script["_id"] == data["_id"]) {
          this.script["validationStatus"].state = data["status"];
        }
      });
  }

  socketSubscriptionForRunProgressInfo: any;
  socketSubscriptionForLogInfo: any;
  socketSubscriptionForLogStatus: any;
  socketFunction() {
    var self = this;

    /**
     * The code below is used to update the percentage of the execution process.
     * The user began running the configuration; the function below is used to display the percentage of the execution process.
     */
    {
      /**
       * @jira PD-173
       */
    }
    self.socketSubscriptionForRunProgressInfo = self.socket
      .listeningFunction("runProgressInfo")
      .subscribe((data: any) => {
        if (data["lastRunId"] === this.script["_id"]) {
          this.script = {
            ...this.script,
            percentage: data.percentage,
            timeoutPH1Happened: data.timeoutPH1Happened,
            timeoutPH2Happened: data.timeoutPH2Happened,
            apiErrorPH1Happened: data.apiErrorPH1Happened,
            apiErrorPH2Happened: data.apiErrorPH2Happened,
          };
        }
      });
  }
  listenforLogInfo() {
    var self = this;
    self.socketSubscriptionForLogInfo = self.socket
      .listeningFunction("logInfo")
      .subscribe((data) => {
        const scriptData = data["script"];
        if (self.script["_id"] == scriptData["_id"]) {
          // if (localStorage.getItem("userName").toUpperCase() == scriptData["userName"]) {
          this.script["progress"] = scriptData["progress"];
          this.script["progressMsg"] = scriptData["progressMsg"];
          this.script["endTime"] = scriptData["endTime"];
          this.script["validationStatus"] = scriptData["validationStatus"];
          this.script.timeoutPH1Happened = scriptData.timeoutPH1Happened;
          this.script.timeoutPH2Happened = scriptData.timeoutPH2Happened;
          this.script.apiErrorPH1Happened = scriptData.apiErrorPH1Happened;
          this.script.apiErrorPH2Happened = scriptData.apiErrorPH2Happened;

          if (scriptData["progressMsg"] == "validation inProgress") {
            new AppComponent(self._snackBar).showSnackBar(
              "Validation Process started, For LOG " +
                scriptData["name"].toUpperCase(),
              "success"
            );
          } else if (scriptData["progressMsg"] == "Validation Completed") {
            // Once all process completed it will show the snackbar intimation.
            new AppComponent(self._snackBar).showSnackBar(
              " Prcess has been Completed, For LOG " +
                scriptData["name"].toUpperCase(),
              "success"
            );
          }
        }
      });
  }
  ngOnDestroy() {
    // /this.socketSubscriptionForRunProgressInfo.unsubscribe();
    this.socketSubscriptionForLogStatus.unsubscribe();
  }
}
