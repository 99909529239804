<div
  [ngStyle]="{ color: brand_color, background: brand, }"
  class="col-12 card p-0 m-0 utilization-card-container row"
  *ngIf="!GraphStatus"
>
  <div class="col-2">
    <mat-icon
      style="font-size: 50px; overflow: visible"
      [ngStyle]="{ color: iconColor }"
      >{{ icon }}</mat-icon
    >
  </div>
  <div class="col-10">
    <h5 class="typography" [ngStyle]="{ color: brand_color, }">{{ label }}</h5>
    <h3 class="typography" [ngStyle]="{ color: brand_color, }">{{ value }}</h3>
  </div>
</div>
<div
  [ngStyle]="{ color: brand_color, background: brand, }"
  class="col-12 card p-0 m-0 utilization-card-container row"
  *ngIf="GraphStatus"
>
  <!-- <div class="col-2">
    <mat-icon style="font-size: 50px; overflow: visible">{{ icon }}</mat-icon>
  </div> -->
  <div class="col-12 p-0">
    <!-- <h5 class="typography" [ngStyle]="{ color: brand_color, }">{{ label }}</h5> -->
    <highcharts-chart
      [Highcharts]="Highcharts"
      [options]="chartOption"
      style="width: 100%; height: 30vh; display: block"
    ></highcharts-chart>
  </div>
</div>
