<div class="searchArea">
  <div class="input-group rounded-pill">
    <span>
      <i class="fa fa-search" aria-hidden="true"></i>
    </span>
    <input
      type="text"
      placeholder="Search for transaction"
      [(ngModel)]="searchFilter"
      (input)="searchTransaction($event.target.value)"
    />
  </div>
</div>
<div class="mappedViewContainer h-100" *ngIf="filterableMappedDataNew">
  <div *ngIf="validationcount" class="filterArea">
    <div class="maskUnmaskConatiner">
      <button class="maskUnmask" (click)="changeView()">
        <span *ngIf="!masked">Mask</span><span *ngIf="masked">Unmask</span>
      </button>
    </div>
    <div class="validationFilterConatiner" [ngClass]="{ noBorder: !all }">
      <div class="dropdown">
        <button
          class="btn dropdown-toggle AllButton pl-3 pr-3"
          [ngClass]="{ AllActive: all }"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          [disabled]="!all"
        >
          <span *ngIf="dropDownHeading.name == 'All'">
            {{ dropDownHeading.name | uppercase }} (<span
              *ngIf="mappedData['scriptData']"
              >{{ totalCount }}</span
            >)
          </span>
          <span *ngIf="dropDownHeading.name != 'All'">
            {{ dropDownHeading.name | uppercase }} (<span
              *ngIf="mappedData['scriptData']"
              >{{ dropDownHeading.count }}</span
            >)
          </span>
        </button>
        <div
          class="dropdown-menu dropdown-scroll"
          aria-labelledby="dropdownMenuButton"
        >
          <a
            class="dropdown-item"
            *ngIf="dropDownHeading.name != 'All'"
            (click)="
              filterValidation({
                scriptName: '',
                FileName: 'ALL',
                count: totalCount,
                subScript: ''
              })
            "
          >
            ALL (<span>{{ totalCount }}</span
            >)</a
          >
          <ng-container *ngFor="let scriptInfo of scriptData">
            <a
              class="dropdown-item"
              [ngClass]="{
                  nonsubScriptStyle: true,
                }"
              (click)="
                filterValidation({
                  scriptName: scriptInfo.FileName,
                  FileName: scriptInfo.FileName,
                  count: scriptInfo.count,
                  subScript: ''
                })
              "
              >{{ scriptInfo.FileName | uppercase }} ({{ scriptInfo.count }})</a
            >
            <ng-container
              *ngFor="let subScriptInfo of scriptInfo['subscripts']"
            >
              <a
                *ngIf="subScriptInfo.subScriptName != 'No Subscript'"
                class="dropdown-item"
                [ngClass]="{
                    subScriptStyle: true,
                  }"
                (click)="
                  filterValidation({
                    scriptName: scriptInfo.FileName,
                    FileName: subScriptInfo.subScriptName,
                    count: subScriptInfo.count,
                    subScript: subScriptInfo.subScriptName
                  })
                "
                >***{{ subScriptInfo.subScriptName | uppercase }} ({{
                  subScriptInfo.count
                }})</a
              >
            </ng-container>
          </ng-container>
        </div>
      </div>
      <button
        class="ComparisonButton"
        [ngClass]="{ CompareActive: comparison }"
        (click)="filterComparision()"
        [disabled]="validationLoading || !validationcount['noDifferenceCount']"
      >
        <span style="width: 20px" class="badge badge-pill p-0">
          <span
            style="
              width: 10px;
              background-color: red;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            "
            >&nbsp;&nbsp;
          </span>
          <span
            style="
              width: 10px;
              background-color: green;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            "
            >&nbsp;&nbsp;&nbsp;
          </span></span
        >Comparison (<span *ngIf="masked">{{
          validationcount["noDifferenceCount"]
        }}</span
        ><span *ngIf="!masked">{{ validationcount["noDifferenceCount"] }}</span
        >)
      </button>
      <button
        class="ErrorButton"
        [ngClass]="{ ErrorActive: error }"
        (click)="filterErrors()"
        [disabled]="validationLoading || !validationcount['errorCount']"
      >
        <mat-icon class="iconsWithLabel text-danger">warning</mat-icon>
        Errors ({{ validationcount["errorCount"] }})
      </button>
      <button
        class="DumpButton"
        [ngClass]="{ DumpActive: dump }"
        (click)="filterDumps()"
        [disabled]="validationLoading || !validationcount['dumpCount']"
      >
        <mat-icon class="iconsWithLabel text-secondary">receipt</mat-icon>Dumps
        ({{ validationcount["dumpCount"] }})
      </button>

      <button [disabled]="true" class="DumpButton">
        <mat-icon class="iconsWithLabel text-secondary">receipt</mat-icon>PH1
        ({{ validationcount["ph1DumpCount"] }})
      </button>

      <button [disabled]="true" class="DumpButton">
        <mat-icon class="iconsWithLabel text-secondary">receipt</mat-icon>PH2
        ({{ validationcount["ph2DumpCount"] }})
      </button>
    </div>
    <div class="filterSection">
      <select
        class="passFailFilter"
        [(ngModel)]="statusFilterValue"
        (change)="filterValidationStatus()"
        [ngClass]="{ noDisplay: !all }"
      >
        <option value="null">All</option>
        <option value="true">Pass</option>
        <option value="false">Fail</option>
      </select>
    </div>
  </div>
  <cdk-virtual-scroll-viewport
    [itemSize]="filterableMappedDataNew.length"
    class="transactionDataArea"
    style="position: relative"
  >
    <ng-container
      class="subScriptGroup"
      *ngFor="
        let item0 of filterableMappedDataNew;
        let subScrtiptIndex = index;
        trackBy: tranckBy
      "
    >
      <p
        class="scriptText m-0"
        style="
          position: sticky;
          top: 0px;
          z-index: 10;
          background-color: white;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
        *ngIf="
          item0.scriptInfo.firstFileNameOccurrence || subScrtiptIndex === 0
        "
      >
        SCRIPT : {{ item0.scriptInfo.FileName | uppercase }}
      </p>
      <p
        style="
          position: sticky;
          top: 25px;
          z-index: 10;
          background-color: white;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
        class="scriptText m-0"
        *ngIf="
          item0.scriptInfo.subScriptName != 'No Subscript' &&
          item0.scriptInfo.firstSubScriptNameOccurrence
        "
      >
        ***{{ item0.scriptInfo.subScriptName | uppercase }}
      </p>
      <div
        appLazyload
        (reachedBottom)="loadMoreData(subScrtiptIndex + 1)"
        class="transactionGroup"
      >
        <div>
          <div class="transaction">
            <div class="inputArea">
              <div class="transactionStaticDetails">
                <div class="transactionNumberAndIcons">
                  <div class="transactionNumber">
                    {{ item0["PH1"]["message"]["productHeader"]["fileType"] }}
                  </div>
                  <div class="iconsArea">
                    <div
                      class="iconsHolder"
                      *ngIf="
                        item0['diffWithMask_status'] ||
                        (item0['diffWithoutMask_status'] && !masked) ||
                        item0['errorMatch_status'] ||
                        item0['ph1DumpStatus'] ||
                        item0['ph2DumpStatus']
                      "
                    >
                      <span
                        style="width: 20px; overflow: hidden"
                        class="badge badge-pill p-0"
                        *ngIf="item0[currentPropertyStatus]"
                      >
                        <span style="width: 10px; background-color: red"
                          >&nbsp;&nbsp;
                        </span>
                        <span style="width: 10px; background-color: green"
                          >&nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                      <mat-icon
                        class="iconsWithLabel text-danger ml-2"
                        *ngIf="item0['errorMatch_status']"
                        >warning</mat-icon
                      >

                      <mat-icon
                        class="iconsWithLabel text-secondary ml-2"
                        *ngIf="item0['ph1DumpStatus'] || item0['ph2DumpStatus']"
                        >receipt</mat-icon
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="apiPathMapped"
                  *ngIf="
                    item0['PH2']['message']['msgData']['MI']['data']['apiPath']
                  "
                >
                  {{
                    item0["PH2"]["message"]["msgData"]["MI"]["data"]["apiPath"]
                  }}
                </div>
              </div>

              <div
                class="transactionName"
                [ngClass]="{ expanded: item0['ExpandInput'] }"
                [ngStyle]="{
                  'font-size': '1.2vw',
                  'line-height': 1.67
                }"
              >
                <pre class="m-0">{{
                  item0["PH1"]["message"]["msgData"]["MI"]["data"]["input"]
                }}</pre>
              </div>

              <div class="passFailArea mt-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    class="passfailswitch"
                    [disabled]="sharedData['view'] == 'Report'"
                    [(ngModel)]="
                      filterableMappedDataNew[subScrtiptIndex][
                        'transaction_status'
                      ]
                    "
                    (change)="
                      updateStatus(
                        item0['PH1']['message']['productHeader']['fileType'],
                        filterableMappedDataNew[subScrtiptIndex][
                          'transaction_status'
                        ]
                      )
                    "
                  />
                  <span
                    class="slider round"
                    [ngClass]="{
                      reportSwitch: sharedData['view'] == 'Report'
                    }"
                  ></span>
                </label>
              </div>
            </div>
            <div
              *ngIf="
                getExpandStatus(
                  item0['PH1']['message']['msgData']['MI']['data']['input']
                )
              "
              class="expandInputButtonArea"
            >
              <button
                class="expandInput"
                (click)="item0['ExpandInput'] = !item0['ExpandInput']"
              >
                <span *ngIf="!item0['ExpandInput']">EXPAND INPUT</span
                ><span *ngIf="item0['ExpandInput']">SHRINK INPUT</span>
              </button>
            </div>

            <ng-container>
              <div class="outputArea">
                <div class="ph1Area">
                  <div class="ph1Data">
                    <ng-container
                      *ngIf="
                        item0['PH1']['message']['msgData']['MO'] ||
                        (item0['PH1']['message']['msgData']['AB'] &&
                          item0['PH1']['message']['msgData']['AB'].length)
                      "
                    >
                      <div class="output">
                        <pre
                          class="outputPre"
                          style="color: #000000"
                          [innerHTML]="item0[currentProperty]?.['PH1']"
                          (scroll)="
                            onScroll(
                              'validationph2' +
                                item0['PH1']['message']['productHeader'][
                                  'fileType'
                                ],
                              $event
                            )
                          "
                          [id]="
                            'validationph1' +
                            item0['PH1']['message']['productHeader']['fileType']
                          "
                        ></pre>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="ph2Area">
                  <div class="ph2Data">
                    <ng-container
                      *ngIf="
                        item0['PH2']['message']['msgData']['MO'] ||
                        (item0['PH2']['message']['msgData']['AB'] &&
                          item0['PH2']['message']['msgData']['AB'].length)
                      "
                    >
                      <div class="output">
                        <pre
                          class="outputPre"
                          style="color: #000000"
                          [innerHTML]="item0[currentProperty]?.['PH2']"
                          (scroll)="
                            onScroll(
                              'validationph1' +
                                item0['PH1']['message']['productHeader'][
                                  'fileType'
                                ],
                              $event
                            )
                          "
                          [id]="
                            'validationph2' +
                            item0['PH1']['message']['productHeader']['fileType']
                          "
                        ></pre>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <h4
        style="text-align: center; color: #273eae"
        *ngIf="
          !validationLoading &&
          subScrtiptIndex == filterableMappedDataNew.length - 1
        "
      >
        {{
          "<------------------------ End of Logging ------------------------>"
        }}
      </h4>
    </ng-container>
  </cdk-virtual-scroll-viewport>

  <div class="selectedSubscripts" *ngIf="noOfSelectedSubscript > 0">
    <div class="selectionTick">
      <mat-icon class="selectionTickIcon">done</mat-icon>
    </div>
    <div class="groupSelectedText">
      <span>{{ noOfSelectedSubscript }} groups selected</span>
    </div>
    <div class="executeWindow">
      <div class="showSelectedText">
        <span class="showText">Show Selected</span>
      </div>
      <div class="execute">
        <button class="executeButton">Execute</button>
      </div>
    </div>
  </div>
</div>
<div
  [ngClass]="{ errorViewContainer: filterableMappedData['error'] }"
  *ngIf="filterableMappedData['error']"
>
  <div class="errorText">
    <span class="message">
      <h1>Nothing to Validate!</h1>
      <h1>Please Execute both the phases.</h1>
    </span>
  </div>
  <div class="imageContainer">
    <img src="assets/Images/error.png" class="errorImage" />
  </div>
</div>

<!-- Fixed Bottom Button -->
<div *ngIf="filterableMappedDataNew.length && scrollButton">
  <mat-button-toggle-group
    name="fontStyle"
    aria-label="Font Style"
    class="fixedButton"
  >
    <button
      class="statusSubmit"
      *ngIf="sharedData['view'] == 'Live'"
      (click)="validationSubmit()"
      aria-label="submit"
    >
      <mat-icon fontIcon="done"></mat-icon>
    </button>
    <div [ngClass]="{ diableClass: !UpArrow }">
      <button
        mat-icon-button
        class="rounded-circle goTop text-center"
        style="z-index: 12"
        (click)="scrollTop()"
      >
        <mat-icon fontIcon="arrow_upward"></mat-icon>
      </button>
    </div>
  </mat-button-toggle-group>

  <!-- goDown Button -->
  <div [ngClass]="{ diableClass: !DownArrow }">
    <button
      mat-icon-button
      style="z-index: 12"
      class="rounded-circle goDown text-center"
      (click)="scrollDown()"
    >
      <mat-icon fontIcon="arrow_downward"></mat-icon>
    </button>
  </div>
</div>
