import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusfilter'
})
export class StatusfilterPipe implements PipeTransform {

  transform(value: any, filter): any {
    console.log(value)
    if (filter != null) {
      var temp = JSON.parse(JSON.stringify(value));
      if (filter=='true') {
        temp.forEach(function (element, scriptIndex) {
          temp[scriptIndex]["subScriptData"] = (JSON.parse(JSON.stringify(element["subScriptData"].filter(function (ele, index) {
            return ele['transaction_status'];
          }))))
        })
      } else {
        temp.forEach(function (element, scriptIndex) {
          temp[scriptIndex]["subScriptData"] = (JSON.parse(JSON.stringify(element["subScriptData"].filter(function (ele, index) {
            return !ele['transaction_status'];
          }))))
        })
      }
      console.log(temp)
      return temp;

    } else {
      return value;
    }

  }

}
