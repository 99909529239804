<form class="general" [formGroup]="GeneralForm">
  <div class="form-row">
    <!-- USER -->
    <div class="col-md-4 mb-3" *ngIf="this.GeneralForm.get('USER')">
      <label class="col-form-label">USER</label>
      <div *ngIf="newKeys.includes('USER')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
      <input
        readonly
        type="text"
        class="form-control"
        formControlName="USER"
        placeholder="USER"
      />
    </div>

    <!-- RUNMODE -->
    <div class="col-md-4 mb-3" *ngIf="this.GeneralForm.get('RUNMODE')">
      <label class="col-form-label">RUNMODE</label>
      <div *ngIf="newKeys.includes('RUNMODE')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
      <div class="d-flex">
        <mat-radio-group formControlName="RUNMODE">
          <mat-radio-button style="margin: 0px" value="SEQUENTIAL">
            <p class="custom-label mb-0">SEQUENTIAL</p>
          </mat-radio-button>
          <mat-radio-button value="PARALLEL">
            <p class="custom-label mb-0">PARALLEL</p>
          </mat-radio-button>
        </mat-radio-group>

        <!-- <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="RUNMODE"
            value="PARALLEL"
          />
          <label class="form-check-label" style="font-size: 15px"
            >PARALLEL</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="RUNMODE"
            value="SEQUENTIAL"
          />
          <label class="form-check-label" style="font-size: 15px"
            >SEQUENTIAL</label
          >
        </div> -->
      </div>
    </div>
    <div class="col-md-4">
      <label class="col-form-label w-30">SUMMARY REPORT</label>
      <div
        *ngIf="
          newKeys.includes('PERF_SUMM_ALL') ||
          newKeys.includes('PERF_SUMM_MAC') ||
          newKeys.includes('PERF_SUMM_PGM') ||
          newKeys.includes('PERF_SUMM_FIL') ||
          newKeys.includes('PERF_SUMM_ECB')
        "
      >
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
      <div class="d-flex w-50" style="display: flex">
        <div
          class="form-check form-check-inline"
          *ngIf="this.GeneralForm.get('PERF_SUMM_ALL')"
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_ALL"
            (change)="onSummaryChange($event)"
            type="checkbox"
          />
          <label class="form-check-label">ALL</label>
        </div>
        <div
          class="form-check form-check-inline"
          *ngIf="
            !GeneralForm.value['PERF_SUMM_ALL'] &&
            this.GeneralForm.get('PERF_SUMM_MAC')
          "
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_MAC"
            type="checkbox"
          />
          <label class="form-check-label">MAC</label>
        </div>
        <div
          class="form-check form-check-inline"
          *ngIf="
            !GeneralForm.value['PERF_SUMM_ALL'] &&
            this.GeneralForm.get('PERF_SUMM_PGM')
          "
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_PGM"
            type="checkbox"
          />
          <label class="form-check-label">PGM</label>
        </div>
        <div
          class="form-check form-check-inline"
          *ngIf="
            !GeneralForm.value['PERF_SUMM_ALL'] &&
            this.GeneralForm.get('PERF_SUMM_FIL')
          "
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_FIL"
            type="checkbox"
          />
          <label class="form-check-label">FILE</label>
        </div>
        <div
          class="form-check form-check-inline"
          *ngIf="
            !GeneralForm.value['PERF_SUMM_ALL'] &&
            this.GeneralForm.get('PERF_SUMM_ECB')
          "
        >
          <input
            class="form-check-input"
            formControlName="PERF_SUMM_ECB"
            type="checkbox"
          />
          <label class="form-check-label">ECB</label>
        </div>
      </div>
    </div>
  </div>

  <!-- SUMMARY REPORT -->
  <div class="form-row">
    <div class="col-md-4 mb-3" *ngIf="this.GeneralForm.get('CMD_TRM_ADDR')">
      <label class="col-form-label">CMD LNIATA</label>
      <div *ngIf="newKeys.includes('CMD_TRM_ADDR')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
      <input
        type="text"
        formControlName="CMD_TRM_ADDR"
        class="form-control"
        placeholder="Command Terminal Lniata"
      />
    </div>
    <div class="col-md-4 mb-3" *ngIf="this.GeneralForm.get('SESSIONWAIT')">
      <label class="col-form-label">SESSIONWAIT</label>
      <div *ngIf="newKeys.includes('SESSIONWAIT')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
      <input
        type="number"
        formControlName="SESSIONWAIT"
        class="form-control"
        placeholder="Time in ms"
      />
    </div>

    <div class="col-md-4 mb-3" *ngIf="this.GeneralForm.get('DOLOAD')">
      <label class="col-form-label">DO LOAD</label>
      <div *ngIf="newKeys.includes('DOLOAD')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          formControlName="DOLOAD"
          type="checkbox"
        />
        <label class="form-check-label" style="font-size: 15px">Yes</label>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4" style="display: flex">
      <div class="col-md-6" *ngIf="this.GeneralForm.get('RUNPH1')">
        <label class="col-form-label">RUN PHASE 1</label>
        <div *ngIf="newKeys.includes('RUNPH1')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
        <div class="col-md-4 mb-3 pl-0" *ngIf="this.GeneralForm.get('RUNPH1')">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              formControlName="RUNPH1"
              type="checkbox"
            />
            <label class="form-check-label" style="font-size: 15px">Yes</label>
          </div>
        </div>
      </div>

      <div class="col-md-6" *ngIf="this.GeneralForm.get('RUNPH2')">
        <label class="col-form-label">RUN PHASE 2</label>
        <div *ngIf="newKeys.includes('RUNPH2')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
        <div class="col-md-4 mb-3 pl-0" *ngIf="this.GeneralForm.get('RUNPH2')">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              formControlName="RUNPH2"
              type="checkbox"
            />
            <label class="form-check-label" style="font-size: 15px">Yes</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4" *ngIf="this.GeneralForm.get('SEND_EMAIL')">
      <label class="col-form-label">SEND EMAIL</label>
      <div *ngIf="newKeys.includes('SEND_EMAIL')">
        <span class="text-center notes"
          ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
          field</span
        >
      </div>
      <div
        class="col-md-4 mb-3 pl-0"
        *ngIf="this.GeneralForm.get('SEND_EMAIL')"
      >
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="SEND_EMAIL"
            type="checkbox"
          />
          <label class="form-check-label" style="font-size: 15px">Yes</label>
        </div>
      </div>
    </div>
    <div class="col-md-4" *ngIf="this.GeneralForm.get('SEND_EMAIL')">
      <div class="col-md-12" *ngIf="GeneralForm.value['SEND_EMAIL']">
        <label class="col-form-label">USER'S EMAIL</label>
        <input
          type="text"
          formControlName="USER_EMAIL"
          class="form-control"
          placeholder="User's Email"
        />
        <div *ngIf="newKeys.includes('USER_EMAIL')">
          <span class="text-center notes"
            ><i class="fa fa-info-circle" aria-hidden="true"></i> Newly added
            field</span
          >
        </div>
      </div>
    </div>
  </div>

  <!-- SEND_EMAIL -->
</form>
