import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: "app-irtf-disp",
  templateUrl: "./irtf-disp.component.html",
  styleUrls: ["./irtf-disp.component.scss"],
})
export class IrtfDispComponent implements OnInit {
  /**
   *
   * @param data |
   * Receives data from iRTF Selection Component
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<String>) {}

  ngOnInit() {}
}
