import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss']
})
export class SnapshotComponent implements OnInit {
  @Input() conf;
  @Input() validator;
  constructor() { }

  ngOnInit() {
  }

}
