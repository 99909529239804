import { environment } from "./../../environments/environment";
import { AbstractControl } from "@angular/forms";

export function ValidatePhases(options) {
  return (control: AbstractControl) => {
    if (!options.includes(control.value)) {
      return { state: true };
    }
    return null;
  };
}
