<div class="wholeConatiner">
  <mat-tab-group [selectedIndex]="0">
    <mat-tab label="Pre Load">
      <ng-template matTabContent>
        <div class="preloadContainer">
          <div class="card col-md-10">
            <div class="col-md-12 row mt-2">
              <h4
                class="col-md-12"
                style="
                  text-align: center;
                  text-decoration: underline;
                  font-family: cursive;
                "
              >
                Load Details
              </h4>
            </div>
            <div class="col-md-12">
              <div class="col-md-12 row row-container">
                <div class="left"><p class="m-0">MAKETPF File Path</p></div>
                <div class="right">
                  <input
                    type="text"
                    class="form-control"
                    (input)="fieldsChanging()"
                    [(ngModel)]="makeTpfFilePath"
                    placeholder="E.g. /home/<Linux_Id>/<Project_Name>/build/maketpf_debug.cfg"
                  />
                </div>
              </div>
              <div class="col-md-12 row row-container">
                <div class="left"><p class="m-0">Load File Path</p></div>
                <div class="right">
                  <input
                    type="text"
                    class="form-control"
                    (input)="fieldsChanging(); loadFilePathChanging($event)"
                    [(ngModel)]="loadFilePath"
                    placeholder="E.g. /home/<Linux_Id>/<Project_Name>/load/<Loadset_Name>.load"
                  />
                </div>
              </div>
              <div class="col-md-12 row row-container">
                <div class="col-md-6 row row-container">
                  <div class="left"><p class="m-0">Loadset Name</p></div>
                  <div class="right">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="loadSetName"
                      (input)="fieldsChanging()"
                      placeholder=""
                      maxlength="8"
                    />
                  </div>
                </div>
                <div class="col-md-6 row row-container">
                  <div class="left"><p class="m-0">OLDR Name</p></div>
                  <div class="right">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="oldrName"
                      (input)="fieldsChanging()"
                      maxlength="13"
                      placeholder="E.g. <Oldr_Name>.oldr"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12 row row-container">
                <div class="col-md-6 row row-container">
                  <div class="left"><p class="m-0">User Name</p></div>
                  <div class="right">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="userName"
                      (input)="fieldsChanging()"
                      placeholder="E.g. eXXXXXX"
                      [autocomplete]="false"
                    />
                  </div>
                </div>
                <div class="col-md-6 row row-container">
                  <div class="left"><p class="m-0">Linux Password</p></div>
                  <div class="right">
                    <!-- <div class="col-md-8"> -->
                    <input
                      matInput
                      [type]="showPassword ? 'text' : 'password'"
                      name="password"
                      class="form-control"
                      [(ngModel)]="password"
                      placeholder="Password"
                      (input)="fieldsChanging()"
                      [autocomplete]="false"
                    />
                    <!-- </div> -->
                    <div class="col-md-2 mt-2">
                      <mat-icon
                        class="addHover"
                        matSuffix
                        (click)="showPassword = !showPassword"
                      >
                        {{ showPassword ? "visibility_off" : "visibility" }}
                      </mat-icon>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-12 row row-container">
                <div class="col-md-6 row row-container">
                  <div class="left"><p class="m-0">Logon Input</p></div>
                  <div class="right">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="logonInput"
                      placeholder="E.g. LOG/A/DL311369/PR"
                      [autocomplete]="false"
                    />
                  </div>
                </div>
                <div class="col-md-6 row row-container">
                  <div class="left"><p class="m-0">Logon Password</p></div>
                  <div class="right">
                    <input
                      matInput
                      [type]="showLogonPassword ? 'text' : 'password'"
                      name="logonPassword"
                      class="form-control"
                      [(ngModel)]="logonPassword"
                      placeholder="KMK*****"
                      [autocomplete]="false"
                    />
                    <div class="col-md-2 mt-2">
                      <mat-icon
                        class="addHover"
                        matSuffix
                        (click)="showLogonPassword = !showLogonPassword"
                      >
                        {{ showLogonPassword ? "visibility_off" : "visibility" }}
                      </mat-icon>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-md-12 row row-container">
                <!-- <div class="col-md-6 row row-container">
                  <div class="left"><p class="m-0">CMD Lniata</p></div>
                  <div class="right">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="cmdLniata"
                      placeholder=""
                      [maxlength]="6"
                    />
                  </div>
                </div> -->
                <!-- <div class="col-md-6 row row-container">
                  <div class="left"><p class="m-0">OLDR Name</p></div>
                  <div class="right">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="oldrName"
                      (input)="fieldsChanging()"
                      maxlength="13"
                      placeholder="E.g. <Oldr_Name>.oldr"
                    />
                  </div>
                </div> -->
              </div>
              <div class="col-md-12 row row-container">
                <ng-container *ngFor="let item of options">
                  <div class="col-md-2 vpar-container">
                    <!-- <input type="radio" [id]="item.name" [(ngModel)]="item.status" /> -->
                    <input
                      (input)="fieldsChanging()"
                      type="checkbox"
                      [(ngModel)]="item.status"
                    />
                    <label class="m-0 ml-1">{{ item.name }}</label>
                  </div>
                </ng-container>
              </div>
              <div class="col-md-12 row mt-4 row-container">
                <div class="col-md-6"></div>
                <div class="col-md-6 row">
                  <div style="width: 30%">
                    <button
                      class="btn btn-info"
                      (click)="validateLoadDetails()"
                      [disabled]="getDisabledStatus() || loadInProgress"
                    >
                      Validate
                    </button>
                  </div>
                  <div style="width: 50%">
                    <button
                      (click)="startLoadingLoadDetails()"
                      class="btn btn-success"
                      [disabled]="
                        getDisabledStatus() ||
                        !validationStatus ||
                        loadInProgress
                      "
                    >
                      Start Load &nbsp;
                      <div
                        *ngIf="loadInProgress"
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="History">
      <ng-template matTabContent>
        <div
          class="container"
          style="height: 90vh; max-height: 90vh; overflow: auto"
        >
          <table
            class="table table-hover table-sticky"
            style="width: 100%; margin-top: 5vh; font-family: cursive"
          >
            <thead class="thead-light" style="position: sticky; top: 0px">
              <th>Sl.No</th>
              <th>Time</th>
              <th>Loadset Name</th>
              <th>Oldr Name</th>
              <th>Action</th>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of preloadLogs; let i = index">
                <tr>
                  <td>{{ i + 1 }}</td>
                  <td>
                    {{ item.loadStartTime | date : "dd-MM-YYYY hh:mm a" }}
                  </td>
                  <td>{{ item.loadSetName }}</td>
                  <td>{{ item.oldrName }}</td>
                  <td>
                    <mat-icon
                      class="addHover"
                      matSuffix
                      style="cursor: pointer; font-size: 22px"
                      title="view Log"
                      (click)="viewLoadHistory(item)"
                    >
                      {{ "visibility" }}
                    </mat-icon>
                    <mat-icon
                      class="text-success ml-2"
                      style="cursor: pointer; font-size: 22px"
                      (click)="openNetworkLog(item.currentRunId)"
                      >network_check</mat-icon
                    >
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
