import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ShareDataService } from "../_services/share-data.service";
import { SocketioClientServices } from "../_services/socketio-client.services";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import * as Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import Drilldown from "highcharts/modules/drilldown";

Exporting(Highcharts);
interface CustomPoint extends Highcharts.Point {
  z: string;
}
Drilldown(Highcharts);
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  constructor(
    public http: HttpClient,
    private _snackBar: MatSnackBar,
    public router: Router,
    private sharedServices: ShareDataService,
    private socket: SocketioClientServices,
    private route: ActivatedRoute,
    public httpAdapter: HttpAdapterService,
    public ref: ChangeDetectorRef
  ) {}
  @ViewChild("chartRef", { static: false })
  chartComponent: Highcharts.Chart;

  public systems: any[] = environment.systems;
  groupNames = ["EFT_QA", "EFT_S2C"];
  selectedGroupName = this.groupNames[0];
  Highcharts: typeof Highcharts = Highcharts;

  userList = [];
  sharedData;
  selectedSystem = environment.systems[0];
  fromDate = moment(new Date().toISOString()).format("YYYY-MM-DD");
  toDate = moment(new Date().toISOString()).format("YYYY-MM-DD");
  ngOnInit() {
    this.getServerUtilization();
    this.getAllScriptsBasedOnUser();
    this.getallConnectedUsers();
    this.getAllUsers();
    this.getScriptsFromPlogByDateRange();
    this.getScriptsNotFromPlogByDateRange();
    this.getAllScriptsByDateRange();
    this.getVPARSList();
    this.getAllScriptsByStatusByDateRange();
  }

  getAllUsers() {
    const params = { url: "admin/getAllUsers" };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.userList = res;
    });
  }
  connectedUsersList;
  getallConnectedUsers() {
    const params = {
      url: "admin/getAllConnectedUsers",
    };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.connectedUsersList = res;
    });
  }
  scriptsFromPlog = [];

  scriptFromPlogPieChartOptions;

  getScriptsFromPlogByDateRange() {
    const params = {
      url: `admin/getAllScriptsRanByDateRangeFromPLOGConfig?fromDate=${this.fromDate}&toDate=${this.toDate}&groupName=${this.selectedGroupName}&plogStatus=true`,
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.scriptsFromPlog = res;
      this.scriptFromPlogPieChartOptions = {
        chart: {
          type: "pie",
        },
        title: {
          text: `Main Run Scripts (${this.scriptsFromPlog.length})`,
          align: "left",
        },
        subtitle: {
          text: "",
          align: "left",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: " times",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            dataLabels: [
              {
                enabled: true,
                distance: 15,
                format: "{point.name} ({point.y})",
              },
            ],
          },
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: ' +
            "<b>{point.y} times</b><br/>",
        },

        series: [
          {
            name: "Scripts",
            colorByPoint: true,
            data: this.scriptsFromPlog.map((plogItem) => ({
              name: plogItem.scriptName,
              y: plogItem.scripts.length,
            })),
          },
        ],
      };
    });
  }
  scriptsNotFromPlog = [];
  scriptNotFromPlogPieChartOptions;
  getScriptsNotFromPlogByDateRange() {
    const params = {
      url: `admin/getAllScriptsRanByDateRangeFromPLOGConfig?fromDate=${this.fromDate}&toDate=${this.toDate}&groupName=${this.selectedGroupName}&plogStatus=false`,
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.scriptsNotFromPlog = res;
      this.scriptNotFromPlogPieChartOptions = {
        chart: {
          type: "pie",
        },
        title: {
          text: `Other Scripts (${this.scriptsNotFromPlog.length})`,
          align: "left",
        },
        subtitle: {
          text: "",
          align: "left",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: " times",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            dataLabels: [
              {
                enabled: true,
                distance: 15,
                format: "{point.name} ({point.y})",
              },
            ],
          },
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: ' +
            "<b>{point.y} times</b><br/>",
        },

        series: [
          {
            name: "Scripts",
            colorByPoint: true,
            data: this.scriptsNotFromPlog.map((plogItem) => ({
              name: plogItem.scriptName,
              y: plogItem.scripts.length,
            })),
          },
        ],
      };
    });
  }
  allScriptsList = [];
  allScriptListChartOptions;
  getAllScriptsByDateRange() {
    const params = {
      url: `admin/getAllScriptsRanByDateRange?fromDate=${this.fromDate}&toDate=${this.toDate}&groupName=${this.selectedGroupName}`,
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.allScriptsList = res;
      this.allScriptListChartOptions = {
        chart: {
          type: "pie",
        },
        title: {
          text: `All Unique Runs (${this.allScriptsList.length})`,
          align: "left",
        },
        subtitle: {
          text: `Total runs from the days are - ${this.allScriptsList.reduce(
            (a, b) => a + b.scripts.length,
            0
          )}`,
          align: "left",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: " times",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            dataLabels: [
              {
                enabled: true,
                distance: 15,
                format: "{point.name} ({point.y})",
              },
            ],
          },
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: ' +
            "<b>{point.y} times</b><br/>",
        },

        series: [
          {
            name: "Scripts",
            colorByPoint: true,
            data: this.allScriptsList.map((plogItem) => ({
              name: plogItem.scriptName,
              y: plogItem.scripts.length,
            })),
          },
        ],
      };
    });
  }

  allScriptsListByStatus = [];
  allScriptListByStatusChartOptions;
  getAllScriptsByStatusByDateRange() {
    const params = {
      url: `admin/getAllScriptsRanByStatus?fromDate=${this.fromDate}&toDate=${this.toDate}&groupName=${this.selectedGroupName}`,
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.allScriptsListByStatus = res;
      this.allScriptListByStatusChartOptions = {
        chart: {
          type: "pie",
        },
        title: {
          text: "All Scripts by status",
          align: "left",
        },
        subtitle: {
          text: "",
          align: "left",
        },

        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "",
          },
        },

        plotOptions: {
          series: {
            borderRadius: 5,
            dataLabels: [
              {
                enabled: true,
                distance: 15,
                format: "{point.name}",
              },
              {
                enabled: true,
                distance: "-30%",
                // filter: {
                //   property: "percentage",
                //   operator: ">",
                //   value: 5,
                // },
                format: "{point.y}",
                style: {
                  fontSize: "0.9em",
                  textOutline: "none",
                },
              },
            ],
          },
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: ' +
            "<b>{point.y} </b><br/>",
        },
        series: [
          {
            name: "Scripts",
            colorByPoint: true,
            data: this.allScriptsListByStatus.map((item) => ({
              name: item.scriptStatus,
              y: item.progressList.length,
              drilldown: item.scriptStatus,
            })),
          },
        ],
        drilldown: {
          series: this.allScriptsListByStatus.map((item) => ({
            name: item.scriptStatus,
            id: item.scriptStatus,
            data: item.progressList.map((scriptItem) => [
              `${scriptItem.progressMsg}`,
              scriptItem.scripts.length,
            ]),
          })),
        },
      };
    });
  }

  allScriptsByUser = [];
  userScriptsChartOption: Highcharts.Options;
  chart: Highcharts.Chart;

  getAllScriptsBasedOnUser() {
    let self = this;
    this.userScriptsChartOption = undefined;
    const params = {
      url: `admin/getScrictsHistoryForAllUsers?fromDate=${this.fromDate}&toDate=${this.toDate}&groupName=${this.selectedGroupName}`,
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.allScriptsByUser = res;

      this.userScriptsChartOption = {
        chart: {
          type: "spline",
          events: {
            load: function () {
              self.socket.listeningFunction("AdminLog").subscribe((data) => {
                if (self.selectedSystem === data["system"]) {
                  if (
                    this.series.some(
                      (item) => item.name == data["script"].user._id
                    )
                  ) {
                    const requireIndex = this.series.findIndex(
                      (item) => item.name == data["script"].user._id
                    );
                    this.series[requireIndex].addPoint(
                      {
                        x: Date.UTC(
                          parseInt(
                            moment(data["script"].startTime).format("YYYY")
                          ),
                          parseInt(
                            moment(data["script"].startTime)
                              .subtract(1, "M")
                              .format("MM")
                          ),
                          parseInt(
                            moment(data["script"].startTime).format("DD")
                          ),
                          parseInt(
                            moment(data["script"].startTime).format("HH")
                          ),
                          parseInt(
                            moment(data["script"].startTime).format("mm")
                          ),
                          parseInt(
                            moment(data["script"].startTime).format("ss")
                          )
                        ),
                        y: this.series[requireIndex].data.length + 1,
                        z: data["script"].name,
                      },
                      true,
                      false
                    );
                  }
                }
              });
            },
          },
        },

        title: {
          text: "",
          align: "left",
        },
        subtitle: {
          text: `Scripts run by users between ${moment(
            this.fromDate,
            "YYYY-MM-DD"
          ).format("DD-MM-YYYY")} to ${moment(this.toDate, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )}`,
          align: "left",
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%e. %b",
            year: "%b",
          },
          title: {
            text: "Date&Time",
          },
        },
        yAxis: {
          title: {
            text: "Scripts Count",
          },
          min: 0,
        },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormatter: function () {
            const point = this as CustomPoint;
            return (
              Highcharts.dateFormat("%l:%M:%S %p", point.x) +
              " - " +
              point.y +
              "<br/> Script Name: " +
              point.z
            );
          },
        },
        plotOptions: {
          series: {
            marker: {
              symbol: "circle",
              fillColor: "#FFFFFF",
              enabled: true,
              radius: 2.5,
              lineWidth: 1,
              lineColor: null,
            },
          },
        },
        series: this.allScriptsByUser.map(
          (item) =>
            ({
              type: "spline",
              name: item.user,
              data: item.scriptInfo.map((scriptItem, i) => ({
                x: Date.UTC(
                  parseInt(moment(scriptItem.startTime).format("YYYY")),
                  parseInt(
                    moment(scriptItem.startTime).subtract(1, "M").format("MM")
                  ),
                  parseInt(moment(scriptItem.startTime).format("DD")),
                  parseInt(moment(scriptItem.startTime).format("HH")),
                  parseInt(moment(scriptItem.startTime).format("mm")),
                  parseInt(moment(scriptItem.startTime).format("ss"))
                ),
                y: i + 1,
                z: scriptItem.name,
              })),
            } as Highcharts.SeriesSplineOptions)
        ),
      };
    });
  }
  serverUtilizationInfo: any;
  dbDocsListchartOptions;
  dbDocsList = [];
  getServerUtilization() {
    this.dbDocsListchartOptions = undefined;
    const params = {
      url: `admin/getSystemUtilizationRecords`,
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.serverUtilizationInfo = res;
      this.dbDocsListchartOptions = {
        chart: {
          type: "column",
          events: {
            load: function () {
              const chart = this;

              // Attach hover events to the xAxis labels after the chart has loaded
              chart.xAxis[0].labelGroup.element.childNodes.forEach(
                (label, index) => {
                  label.addEventListener("mouseover", function () {
                    const point = chart.series[0].data[index];
                    chart.tooltip.refresh(point); // Show tooltip for the corresponding point
                  });

                  label.addEventListener("mouseout", function () {
                    chart.tooltip.hide(); // Hide the tooltip when the mouse leaves the label
                  });
                }
              );
            },
          },
        },
        title: {
          text: "Documents Count from Database",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          type: "category",
          labels: {
            autoRotation: [-45, -90],
            style: {
              fontSize: "13px",
              fontFamily: "Verdana, sans-serif",
            },
            formatter: function () {
              return this.value; // Return label text (category)
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Documents",
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormat: "Documents: <b>{point.y} </b>",
          shared: true,
        },
        plotOptions: {
          series: {
            colorByPoint: true,
          },
        },
        series: [
          {
            name: "Documents",
            data: Object.keys(res.documentCount).map((doc, index) => [
              doc,
              res.documentCount[doc],
            ]),
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: "#FFFFFF",
              inside: true,
              verticalAlign: "top",
              format: "{point.y}", // one decimal
              y: 10, // 10 pixels down from the top
              style: {
                fontSize: "13px",
                fontFamily: "Verdana, sans-serif",
              },
            },
          },
        ],
      };
      this.dbDocsList = Object.keys(res.documentCount).map((doc, index) => ({
        label: doc,
        value: res.documentCount[doc],
      }));
    });
  }
  colorArray = [
    "#003f5c", // Darkest Blue
    "#2f4b7c", // Deep Blue
    "#4a6fa5", // Medium Blue
    "#665191", // Muted Blue
    "#8491b4", // Soft Blue
    "#9bb1d0", // Light Blue
    "#a05195", // Bright Purple-Blue
    "#c0d6e4", // Very Light Blue
    "#606896", // Vivid Pinkish Blue
    "#9f88eb", // Soft Red-Blue
    "#3b4e67", // Slate Blue
    "#6a82c7", // Lavender Blue
    "#567db3", // Royal Blue
    "#7986cc", // Light Lavender
    "#4d92ff", // Bright Sky Blue
    "#2171b5", // Deep Cerulean
    "#5f9bd5", // Air Force Blue
    "#1e5f7f", // Teal Blue
    "#3993dd", // Bold Light Blue
    "#3a539b", // Classic Blue
  ];

  vparsList = [];
  getVPARSList() {
    const params = {
      url: `admin/getVPARSInfo`,
      system: this.selectedSystem,
    };
    this.httpAdapter.httpGetNew(params).subscribe((response) => {
      const res: any = response.body;
      this.vparsList = res;
    });
  }
}
