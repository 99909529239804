<div
  class="container-fluid"
  style="height: 95vh; overflow: scroll; margin-top: 5vh"
  *ngIf="access"
>
  <div class="topSection">
    <mat-grid-list cols="5" rowHeight="13vh">
      <mat-grid-tile class="logsSection" *ngFor="let file of inputFiles">
        <!-- <section class="checkboxLog">
          <input  type="checkbox" [checked]="log.checked" (change)="checkScript($event,log.scriptId)">
        </section> -->
        <div class="card p-2" style="min-width: 98%">
          <div class="container-fluid">
            <div class="text-center logName textContent">
              <h4>
                <b style="color: white">{{ file.fileName }}</b>
              </h4>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="text-left mr-auto px-2 textContent">
                Created At:
                <span class="dateClass">{{
                  file.createdAt
                    ? (file.createdAt | date : "MMM dd, hh:mm")
                    : "Not Available"
                }}</span>
              </div>
              <div
                (click)="showInput(file)"
                href="#"
                style="
                  text-decoration: underline;
                  cursor: pointer;
                  color: #ffffff;
                "
                class="pl-2 pt-1 textContent"
              >
                <mat-icon class="download" fontIcon="visibility"></mat-icon>
              </div>
              <div
                (click)="downloadScript(file)"
                href="#"
                class="pl-2 pt-1 textContent"
              >
                <abbr title="Download File">
                  <mat-icon
                    class="download"
                    fontIcon="cloud_download"
                  ></mat-icon>
                </abbr>
              </div>
            </div>
          </div>
          <!-- <div
            style="
              position: absolute;
              top: 0.2vh;
              right: 0.2vw;
              cursor: pointer;
            "
          >
            <mat-icon fontIcon="more_vert"></mat-icon>
          </div> -->
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
<div *ngIf="!access" class="outerContainer d-flex justify-content-center">
  <div class="errorContainer rounded card">
    <div class="card-body">
      <h2>We are sorry...</h2>
      <br />
      <p class="text-muted">
        The page you're trying to access has resticted access.
      </p>
      <p class="text-muted">Please refer to your system administrator</p>
    </div>
  </div>
</div>
