<!--
  Dialog UI for Showing the list of iRTF Files after clicking on 'Add iRTF' Button in Run View
-->
<div class="row">
  <h2 class="col-12" mat-dialog-title
    [ngClass]="{ 'erroheading': errorMessage != null && errorMessage.length != 0 || successMessage  != null,'heading': errorMessage != null && errorMessage.length == 0 && successMessage  == null }">
    Select the iRTF File</h2>

</div>

<div class="row">

  <div class="col-6" *ngIf="errorMessage != null && errorMessage.length > 0">
    <div *ngFor="let error of errorMessage; let i = index">
      <p class="m-0 errorblink" style="font-size: min(max(8px, 3vw), 12px); padding-bottom: 2px; padding-top: 0px;"
        [innerHTML]="errorMessage[i]"></p>
    </div>
  </div>

  <div class="col-6" *ngIf="successMessage != null && successMessage.length > 0">
    <p *ngIf="successMessage" class="m-0 successblink" [innerHTML]="successMessage"></p>
  </div>

</div>

<mat-dialog-content (click)="CheckDialoug()" class="mat-typography" style="border-top:1px solid black;">

  <mat-selection-list #shoes [(ngModel)]='data["selected"]' [multiple]="false">
    <mat-list-option *ngFor="let trans of data['value']" [value]="trans" [disableRipple]="true">
      <!-- searchPipe -->

      <div class="row  align-items-end justify-content-center">

        <div class="col-6">
          <label>
            <span class="transactionName">{{trans ["irtfFileName"] }}</span>
          </label>
        </div>

        <div class="col-6">
          <button style="float: right;" mat-button color="primary" (click)="openContent($event, trans['_id'])">
            view file
          </button>
        </div>

      </div>
    </mat-list-option>
  </mat-selection-list>

</mat-dialog-content>
<mat-dialog-actions align="end">

  <button mat-button color="warn" (click)="onClick()">
    <mat-icon>file_upload</mat-icon>
    Upload
  </button>
  <input class="form-control" type="file" (click)="fileInput.value = null" (change)="onChange($event)" value="" style="display:none;" #fileInput>
  <button mat-button (click)="CloseDialoug();data['closed']=true;">Close</button>
  <button *ngIf="itemSelected" (click)="CheckDialoug()" mat-button [mat-dialog-close]="data">Select</button>
</mat-dialog-actions>
