<div class="pgmcontainer">
    <!-- <div class="row">
        <div class="col-3">


            <select class="form-control freezeIdList">
                <option>LIVE</option>

            </select>

        </div>
        <div class="col-3">
            <input type="text" class="form-control" placeholder="Enter program name">
        </div>
    </div> -->
    <div id="showPgmVsScript">

    </div>
</div>