<div *ngIf="currentTabIndex == 0" class="DropdownArea text-center">
  <div class="auto-width">
    <form class="example-form">
      <mat-form-field class="example-full-width matDropdown" appearance="fill">
        <mat-label>Filter</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Filter"
          matInput
          [formControl]="myControl"
          (input)="myControl.setValue(myControl.value.toUpperCase())"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            class="nonsubScriptStyle"
            [value]="'ALL'"
            (click)="filterMethod($event.value, false)"
            ><span style="font-weight: 500">ALL</span></mat-option
          >
          <ng-container
            *ngFor="let script of filteredOptions | async; trackBy: trackByFn"
          >
            <mat-option
              *ngIf="script.firstFileNameOccurrence"
              [value]="script.FileName"
              (click)="filterMethod(script, false)"
            >
              <span
                class="nonsubScriptStyle"
                [ngClass]="{
                  nonsubScriptStyle: script.subScript === false,
                  subScriptStyle: script.subScript === true
                }"
                >{{ script.FileName }}</span
              >
            </mat-option>
            <mat-option
              *ngIf="
                script.firstSubScriptNameOccurrence &&
                script.subScript != 'No Subscript'
              "
              [value]="script.subScript"
              (click)="filterMethod(script, true)"
            >
              <span class="subScriptStyle">{{ script.subScript }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </form>
    <!-- <mat-form-field appearance="fill" class="matDropdown">
        <mat-label>Filter Script</mat-label>
        <mat-select [(ngModel)]="selectedValue" (selectionChange)="filterMethod($event.value)" >
          <mat-option [value]="'All'" ><span style="font-weight: 500;" >All</span></mat-option>
          <mat-option *ngFor="let script of scriptDropdownList"

          [value]="script"><span  [ngClass]="{'nonsubScriptStyle': script.subScript === false,'subScriptStyle': script.subScript === true}">{{script.scriptName | uppercase}}</span> </mat-option>
        </mat-select>
      </mat-form-field> -->
  </div>
</div>
<div class="validateViewContainer" *ngIf="executionId && scriptLogData">
  <mat-tab-group
    style="width: 100%"
    [selectedIndex]="currentTabIndex"
    (selectedTabChange)="tabChange($event)"
    *ngIf="currentScript != undefined"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    preserveContent
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="matTabTextArea">
          <!-- <a
            class="btn btn-xs toggBtn"
            style="display: flex; justify-content: center; align-items: center"
            [href]="[
              '/#/scripts/validate/' + currentScriptIndex + '/execution'
            ]"
          > -->
          <span class="matLabel">Execution</span>
          <span
            class="label label-success completedStat"
            *ngIf="
              scriptLogData?.executionData?.executionMsg != null &&
              scriptLogData?.executionData?.executionMsg?.state == 'success'
            "
            >{{
              scriptLogData["executionData"]["executionMsg"]["message"]
            }}</span
          >
          <span
            class="label label-success inprogressStat"
            *ngIf="
              scriptLogData?.executionData?.executionMsg != null &&
              scriptLogData?.executionData?.executionMsg?.state == 'progress'
            "
            >{{
              scriptLogData["executionData"]["executionMsg"]["message"]
            }}</span
          >
          <span
            class="label label-success failedStat"
            *ngIf="
              scriptLogData?.executionData?.executionMsg != null &&
              scriptLogData?.executionData?.executionMsg?.state == 'fail'
            "
            >{{
              scriptLogData["executionData"]["executionMsg"]["message"]
            }}</span
          >
          <!-- <span class="label label-success completedStat"
            *ngIf=" execLog['executionData']['executionMsg'] != null &&   execLog['executionData']['executionMsg']['state'] == 'success'">{{execLog['executionData']['executionMsg']['message']}}</span>
          <span class="label label-success inprogressStat"
            *ngIf=" execLog['executionData']['executionMsg'] != null &&  execLog['executionData']['executionMsg']['state'] == 'progress'">{{execLog['executionData']['executionMsg']['message']}}</span>
          <span class="label label-success failedStat"
            *ngIf=" execLog['executionData']['executionMsg'] != null &&   execLog['executionData']['executionMsg']['state'] == 'fail'">{{execLog['executionData']['executionMsg']['message']}}</span> -->
          <!-- </a> -->
        </div>
      </ng-template>
      <div class="matTabContent" style="width: 100%">
        <app-exec-log
          [executionId]="executionId"
          [(currentScript)]="currentScript"
          (sendData)="getSubScriptData(executionId)"
          [selectedScript]="this.selectedFilter"
          [changing]="changingValue"
          [filterChanging]="filterChanging"
        ></app-exec-log>
        <!-- <router-outlet></router-outlet> -->
      </div>
    </mat-tab>

    <mat-tab [disabled]="currentScript != undefined && !ValidationDataStatus">
      <ng-template mat-tab-label>
        <div class="matTabTextArea">
          <!-- <a
            class="btn btn-xs toggBtn"
            style="display: flex; justify-content: center; align-items: center"
            [href]="[
              '/#/scripts/validate/' + currentScriptIndex + '/validation'
            ]"
          > -->
          <span class="matLabel">Validation</span>
          <span
            class="label label-success completedStat"
            *ngIf="
              ValidationDataMsg != null && ValidationDataMsg['state'] == true
            "
            >{{ ValidationDataMsg["message"] }}</span
          >
          <span
            class="label label-success inprogressStat"
            *ngIf="
              ValidationDataMsg != null &&
              ValidationDataMsg['state'] == 'progress'
            "
            >{{ ValidationDataMsg["message"] }}</span
          >
          <span
            class="label label-success failedStat"
            *ngIf="
              ValidationDataMsg != null && ValidationDataMsg['state'] == false
            "
            >{{ ValidationDataMsg["message"] }}</span
          >
          <!-- </a> -->
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div class="matTabContent" style="width: 100%">
          <app-mapped-view
            [(currentScript)]="currentScript"
            [executionId]="executionId"
            (validationStatusEmitter)="ValidationStatusHandler($event)"
            [(trnxData)]="ValidationData"
          >
          </app-mapped-view>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="!summaryReportReportGenerated">
      <ng-template mat-tab-label>
        <div class="matTabTextArea">
          <!-- <a
            class="btn btn-xs toggBtn"
            style="display: flex; justify-content: center; align-items: center"
            [href]="[
              '/#/scripts/validate/' + currentScriptIndex + '/perfomance'
            ]"
          > -->
          <span class="matLabel">Performance</span>
          <span
            class="label label-success completedStat"
            *ngIf="
              summaryReportStatus != null &&
              summaryReportStatus['state'] == 'success'
            "
            >{{ summaryReportStatus["message"] }}</span
          >
          <span
            class="label label-success inprogressStat"
            *ngIf="
              summaryReportStatus != null &&
              summaryReportStatus['state'] == 'progress'
            "
            >{{ summaryReportStatus["message"] }}</span
          >
          <span
            class="label label-success failedStat"
            *ngIf="
              summaryReportStatus != null &&
              summaryReportStatus['state'] == 'failed'
            "
            >{{ summaryReportStatus["message"] }}</span
          >
          <!-- </a> -->
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div class="matTabContent" style="width: 100%">
          <app-summary-report
            [(summaryReportData)]="summaryReport"
            [(current)]="currentScript"
            [filterScript]="this.scriptDropdownList"
            [subScriptData]="subScriptData"
            [currentScriptIndex]="currentScriptIndex"
          ></app-summary-report>
          <!-- <router-outlet></router-outlet> -->
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div
    style="
      position: absolute;
      top: 5%;
      right: 27%;
      cursor: pointer;
      z-index: 999;
    "
    *ngIf="currentScript && currentScript['progress'] == 'PROGRESS'"
    (click)="refreshLog()"
  >
    <!-- <ng-template mat-tab-label> -->
    <!-- <button class="btn my-fab2" (click)="refreshLog()"> -->
    <mat-icon fontIcon="refresh"></mat-icon>
    <!-- </button> -->
    <!-- </ng-template> -->
  </div>
</div>
