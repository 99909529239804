<mat-card class="example-card col-12 p-0" appearance="outlined">
  <mat-card-header
    style="display: flex; justify-content: center; align-items: center"
    class="p-0"
  >
    <mat-card-title class="cardheader">
      <mat-icon
        data-toggle="tooltip"
        data-placement="top"
        [title]="onlineStatus ? 'Online' : 'Offline'"
        [ngStyle]="onlineStatus ? { color: 'green' } : { color: 'red' }"
        >account_circle</mat-icon
      >
      <span>{{ userData.user | titlecase }}</span></mat-card-title
    >
    <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
  </mat-card-header>
  <mat-card-content class="p-2" style="display: flex; justify-content: center">
    <div class="userCardbody">
      <button
        [disabled]="!userData.configList.length"
        class="text-primary userCardbodyItem btn btn-none"
        (click)="isMoreInformationModalvisible = true"
      >
        <!-- <span>{{ existingTabsSocketIdList.length }}</span> -->
        <!-- <span>{{ userData.configList.length }}</span> -->
        <p class="m-0" style="text-decoration: underline">
          Configs({{ userData.configList.length }})
        </p>
        <!-- <mat-icon class="m-0"> insert_chart_outlined</mat-icon> -->
      </button>
      <h4 class="text-success userCardbodyItem btn btn-none">
        <!-- <span>{{ existingTabsSocketIdList.length }}</span> -->
        <!-- <span>{{ userData.configList.length }}</span> -->
        <p class="m-0">Tabs({{ existingTabsSocketIdList.length }})</p>
        <!-- <mat-icon class="m-0"> insert_chart_outlined</mat-icon> -->
      </h4>
    </div>
  </mat-card-content>
</mat-card>

<nz-modal
  [(nzVisible)]="isMoreInformationModalvisible"
  nzTitle="User Config Information"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  nzWidth="50%"
>
  <ng-container *nzModalContent>
    <div
      class="col-12"
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        border-bottom: 1px solid black;
      "
    >
      <div class="col-2">Sl.No</div>
      <div class="col-3">Config Name</div>
      <div class="col-3">System</div>
      <div class="col-4">Last used</div>
    </div>
    <div
      class="col-12 mt-2"
      style="display: flex; align-items: center; justify-content: space-between"
      *ngFor="let item of userData.configList; let i = index"
    >
      <div class="col-2">{{ i + 1 }}</div>
      <div class="col-3">{{ item.scriptName }}</div>
      <div class="col-3">{{ item.system }}</div>
      <div class="col-4">{{ item.startTime | date : "hh:mm dd-MM-yyyy" }}</div>
    </div>
  </ng-container>
</nz-modal>
