import { ReportsComponent } from "./reports/reports.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ReportComponent } from "./report.component";
import { ListFreezedRunsComponent } from "./list-freezed-runs/list-freezed-runs.component";

const routes: Routes = [
  {
    path: "",
    component: ReportComponent,
    children: [
      { path: "", redirectTo: "scripts", pathMatch: "full" },
      { path: "dashboard", component: ListFreezedRunsComponent },
      { path: "scripts", component: ReportsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportRoutingModule {}
