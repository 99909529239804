<form [formGroup]="conf">
  <!-- TRM ADDRS and LOOP_IT Fields -->
  <div class="form-row mb-4" style="display: flex; align-items: flex-end">
    <!-- TRM ADDRS -->
    <div class="col-md-3" *ngIf="this.conf.get('TRM_ADDR')">
      <label class="col-form-label" for="TRM_ADDR">ALC LNIATA</label>
      <input
        id="TRM_ADDR"
        type="text"
        class="form-control"
        formControlName="TRM_ADDR"
        placeholder="Terminal Address"
      />
      <div *ngIf="newKeys.includes('TRM_ADDR')" class="text-info mt-1">
        <i class="fa fa-info-circle" aria-hidden="true"></i> Newly added field
      </div>
    </div>
    <div class="col-3" style="display: flex; justify-content: center">
      <button class="btn btn-primary btn-block col-8 p-0" (click)="addScript()">
        Select Input File
      </button>
    </div>

    <!-- LOOP_IT -->
    <!-- <div class="col-md-4" *ngIf="this.conf.get('LOOP_IT')">
      <label class="col-form-label" for="LOOP_IT">LOOP SCRIPT</label>
      <input
        id="LOOP_IT"
        type="number"
        class="form-control"
        formControlName="LOOP_IT"
        placeholder="Loop Scripts"
      />
      <small class="form-text text-muted">
        Number of times the Input to be executed.
      </small>
      <div *ngIf="newKeys.includes('LOOP_IT')" class="text-info mt-1">
        <i class="fa fa-info-circle" aria-hidden="true"></i> Newly added field
      </div>
    </div> -->
    <!-- System Date -->
    <div class="col-md-3" *ngIf="this.conf.get('SYS_DATE')">
      <label class="col-form-label" for="SYS_DATE">SYSTEM DATE</label>
      <input
        id="SYS_DATE"
        type="date"
        class="form-control"
        formControlName="SYS_DATE"
      />
      <div *ngIf="newKeys.includes('SYS_DATE')" class="text-info mt-1">
        <i class="fa fa-info-circle" aria-hidden="true"></i> Newly added field
      </div>
    </div>
    <div class="col-3" style="display: flex; justify-content: center">
      <button class="btn btn-primary btn-block col-8 p-0" (click)="addiRTF()">
        Select iRTF File
      </button>
    </div>
  </div>

  <!-- Add Script Button -->
  <!-- <div class="form-row mb-2">
    <div class="col-6">
      <button class="btn btn-primary btn-block col-4" (click)="addScript()">
        Select Script File
      </button>
    </div>
    <div class="col-6">
      <button class="btn btn-primary btn-block col-4" (click)="addiRTF()">
        Select iRTF File
      </button>
    </div>
  </div> -->

  <!-- Script Files List -->
  <div class="form-row mb-4">
    <div class="col-md-6">
      <div
        class="rounded border border-primary mt-1"
        *ngIf="MSG_FILES.controls.length > 0"
        style="height: 100%"
      >
        <label class="font-weight-bold text-muted pl-2"
          >SELECTED SCRIPT FILES ({{ MSG_FILES.controls.length }})</label
        >
        <div formArrayName="MSG_FILES">
          <div
            class="form-row align-items-center mb-2"
            [formGroupName]="i"
            *ngFor="let inputfile of MSG_FILES.controls; let i = index"
          >
            <div class="col-md-8">
              <a
                class="fileNameView ml-3"
                style="font-weight: bold; font-size: 16px"
                (click)="showInput(inputfile)"
              >
                {{ inputfile.value.NAME }}.input &nbsp;
                <span style="font-weight: bold; font-size: 10px"
                  >(Click to view file)</span
                >
              </a>
            </div>
            <div class="col-md-4 text-center">
              <button
                color="warn"
                class="btn btn-danger btn-sm rounded-pill col-6 p-0"
                (click)="removeScript(i)"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="IRTF_FILES.controls.length > 0">
      <div class="rounded border border-primary mt-1" style="height: 100%">
        <label class="font-weight-bold text-muted pl-2"
          >SELECTED IRTF FILES ({{ IRTF_FILES.controls.length }})</label
        >
        <div formArrayName="IRTF_FILES">
          <div
            class="form-row align-items-center mb-2"
            [formGroupName]="i"
            *ngFor="let irtfFile of IRTF_FILES.controls; let i = index"
          >
            <div class="col-md-8">
              <a
                class="fileNameView ml-3"
                (click)="showIrtfFile(irtfFile.value.inputFileRef)"
                style="font-weight: bold; font-size: 16px"
              >
                {{ irtfFile.value.NAME }}.irtf &nbsp;
                <span style="font-weight: bold; font-size: 10px"
                  >(Click to view file)</span
                >
              </a>
            </div>
            <div class="col-md-4 text-center">
              <button
                color="warn"
                class="btn btn-danger btn-sm rounded-pill col-6 p-0"
                (click)="removeiRTF(i)"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- iRTF Files List -->
</form>
