export const environment = {
  production: false,
  apiPath: "http://172.16.2.55:3200/",
  // apiPath: "http://eft.tpfsoftware.com:3200/",
  clientSystem: "TSI",
  systems: ["DEV-R", "DEV-A", "DEV-O"],
  summaryReportKeys: [
    {
      field: "Programs",
      PH1: "overallPgmPH1",
      PH2: "overallPgmPH2",
      keys: [
        "program",
        "PH1",
        "PH2",
        "Difference",
        "Percentage",
        "TransactionDiff",
        "TransactionPercentage",
      ],
    },
    {
      field: "Macro",
      PH1: "overallMacroPH1",
      PH2: "overallMacroPH2",
      keys: [
        "MACRO",
        "PH1",
        "PH2",
        "Difference",
        "Percentage",
        "TransactionDiff",
        "TransactionPercentage",
      ],
    },
  ],
  confTabs: [
    "GENERAL",
    "SCRIPTS",
    "PHASES",
    "TRACEDEF",
    "TRACE_OVERRIDE",
    "DATABASE",
    "SNAPSHOT",
    "LOAD",
  ],
};
