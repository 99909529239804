import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msConvertor'
})

//Pipe for Converting the runTime(in MilliSeconds) to a readable format (HH:MM:SS)
export class MsConvertorPipe implements PipeTransform {

  transform(runTimeDuration: number, ...args: unknown[]): unknown {

    //Logic for Converting the runTimeDuration to seconds/minutes/hours
    let seconds = Math.floor(runTimeDuration / 1000);
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    //Returing the values by converting them to 2 digit string and appending in format of 'HH:MM:SS'
    return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
  }

}
