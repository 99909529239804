import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { ShareDataService } from "./../../_services/share-data.service";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  FormBuilderConfiguration,
  RxFormBuilder,
} from "@rxweb/reactive-form-validators";
import * as _ from "underscore";
// import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ValidatePhases } from "src/app/shared/phases.validator";
import { SocketioClientServices } from "src/app/_services/socketio-client.services";
import { AppComponent } from "src/app/app.component";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-run-view",
  templateUrl: "./run-view.component.html",
  styleUrls: ["./run-view.component.scss"],
  // encapsulation: ViewEncapsulation.None
})
export class RunViewComponent implements OnInit, OnDestroy {
  public confTabs = environment.confTabs;
  public scriptDeatils = {
    userName: "",
    password: "",
    filePath: "",
    fileName: "",
  };
  public scriptConf = {};
  public confChangeStatus = { status: false, newKeys: [] };
  public currentScriptId = "";
  public CrumbDetailsForJenkins = null;
  public sharedData = {};
  public errormessage = false;
  selectedSystem: string = "";
  /**
   * Below variables are sharing the information with other child components
   */

  public GENERALNewKeys = {};
  public SCRIPTNewKeys = {};
  public PHASESNewKeys = {};
  public TRACEDEFNewKeys = {};
  public TRACE_OVERRIDENewKeys = {};
  public LOADNewKeys = {};

  httpAdapter: HttpAdapterService;
  currentScriptIndex = 0;
  public currentSection = "General";
  public InputFileData = [];
  editorOptions: any;
  //The Boolean variable is to check if the configuration has been present or not in the revised data from DB. (PM-131)
  configPresent: boolean = false;
  public CurrentConfForm: FormGroup;
  subscription: any;
  // @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
  constructor(
    private sharedServices: ShareDataService,
    public http: HttpClient,
    public dialog: MatDialog,
    private myElement: ElementRef,
    private fb: RxFormBuilder,
    public socket: SocketioClientServices,
    private _snackBar: MatSnackBar
  ) {
    // this.editorOptions = new JsonEditorOptions()
    // this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.httpAdapter = new HttpAdapterService(this.http);
  }

  ngOnInit() {
    var self = this;
    self.subscription = self.sharedServices.currentData$.subscribe((data) => {
      self.sharedData = { ...data };
      if (
        self.sharedData["selectedSystem"] &&
        self.selectedSystem != self.sharedData["selectedSystem"]
      ) {
        self.selectedSystem = self.sharedData["selectedSystem"];
      }
      // if (self.sharedData['CrumbDetailsForJenkins']) {
      //   self.CrumbDetailsForJenkins = self.sharedData['CrumbDetailsForJenkins']
      // } else {
      //   self.getJenkinsCrumb();
      // }

      // Added New OR (||) condition to know that the new config has been added in listRunScripts ( Mainly to change the currentScriptIndex count). - (PM-112)
      if (
        self.sharedData["listRunScripts"] &&
        self.sharedData["currentRunScript"] &&
        (self.sharedData["currentRunScript"]["_id"] != self.currentScriptId ||
          (self.sharedData["socket"] &&
            self.sharedData["socket"]["NewLogAdded"] == true))
      ) {
        self.currentScriptId = self.sharedData["currentRunScript"]["_id"];
        var scriptDeatilsList = [...self.sharedData["listRunScripts"]];
        self.scriptDeatils = scriptDeatilsList.filter(function (
          element,
          index
        ) {
          if (element._id == self.currentScriptId) {
            self.currentScriptIndex = index;
            self.configPresent = true;
            return element;
          }
        })[0];
        //Once the received data is an empty array, the variable will be undefined. So we changed the configurationPresent status. (PM-131)
        if (self.scriptDeatils == undefined) {
          self.configPresent = false;
        }

        if (self.scriptDeatils != undefined) {
          if (
            self.scriptDeatils["scriptRunStatus"] === "Completed" ||
            self.scriptDeatils["scriptRunStatus"] === "Failed" ||
            (self.scriptDeatils["scriptRunStatus"] === "ABORTED" &&
              self.scriptDeatils["updated"] == false)
          ) {
            self.scriptDeatils["updated"] == true;
            // self.updateRunDetails(self.scriptDeatils);
          }
          // var inputFilegetParam = {
          //   url: "inputFile/viewFile",
          //   data: {
          //     "ObjectId": self.scriptDeatils['inputFileRef']
          //   }
          // }
          // self.httpAdapter.httpPost(inputFilegetParam, function (err, fileResp) {
          //   if (!err) {
          //     // console.log("fileDta===>", fileResp.body)
          //     self.InputFileData = fileResp['body']["transactions"]
          //     // console.log(self.InputFileData)
          //   }
          // })

          // // console.log("Current conf:",self.scriptDeatils['conf'])
          self.scriptConf = self.scriptDeatils["conf"];
          self.confChangeStatus.status = self.scriptDeatils["confChanged"];
          self.confChangeStatus.newKeys = self.scriptDeatils["changedObject"];

          self.CurrentConfForm = null;
          this.buildConfForm();
        }

        //Once the new config added (NewLog_Added will be true in sharedData, If its true blow function will execute normaly). so we changing the status to old  (PM-112)
        if (
          self.sharedData["socket"] &&
          self.sharedData["socket"]["NewLogAdded"] == true
        )
          self.sharedData["socket"]["NewLogAdded"] = false;
        //Added sharedServices to changes the NewLogAdded status into false. - (PM-112)
        this.sharedServices.changeData(this.sharedData);
      }
      if (self.scriptDeatils != undefined) {
        if (
          self.sharedData["listRunScripts"] &&
          self.sharedData["currentRunScript"] &&
          self.sharedData["currentRunScript"]["_id"] == self.currentScriptId
        ) {
          self.scriptDeatils["scriptRunStatus"] =
            self.sharedData["currentRunScript"]["scriptRunStatus"];
        }
      }
    });
  }

  updateConfig(config) {
    var self = this;
    var params = {
      url: "scripts/run/updateconfig",
      data: { ObjectId: config._id },
    };
    self.httpAdapter.httpPost(params, function (err, res) {
      if (err) {
        console.log("errtetsing");
      } else {
        self.scriptDeatils = res.body;
        self.sharedData["currentRunScript"] = res.body;
        self.sharedData["listRunScripts"].map((list, index) => {
          if (list._id === res.body._id) {
            self.sharedData["listRunScripts"][index] = res.body;
          }
        });
        self.scriptConf = self.scriptDeatils["conf"];
        self.confChangeStatus.status = self.scriptDeatils["confChanged"];
        self.confChangeStatus.newKeys = self.scriptDeatils["changedObject"];
        self.buildConfForm();
      }
    });
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo(section) {
    let el = this.myElement.nativeElement.querySelector("#" + section);
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  public runScript() {
    var self = this;
    // console.log("Current Script that is running is --->", self.scriptDeatils);
    self.scriptDeatils["percentage"]["PHASE1"] = 0;
    self.scriptDeatils["percentage"]["PHASE2"] = 0;
    var params = {
      url: "scripts/runScripts",
      data: { objectId: self.currentScriptId, ...self.scriptDeatils },
      system: self.selectedSystem,
    };
    self.httpAdapter.httpPost(params, function (err, res) {
      if (err) {
        // console.log("error running script===>", err)
      } else {
        console.log("resres-->", res);
        if (res["body"]["message"] === "Run Initiated") {
          self.scriptDeatils["scriptRunStatus"] = "inProgress";
        }
        //Added below code to get the current index count in the list of array (PD-112)
        var currentIndex;
        self.sharedData["listRunScripts"].map((value, index) => {
          if (value._id == self.sharedData["currentRunScript"]["_id"]) {
            currentIndex = index;
          }
        });
        self.sharedData["listRunScripts"][self.currentScriptId] =
          self.scriptDeatils;
        self.sharedServices.changeData(self.sharedData);
      }
    });
    //}else{
    // window.alert("Please validate and save the config before running.")
    //
  }

  public abortScript() {
    var self = this;
    // console.log("sending")
    self.sharedServices.changeData(self.sharedData);
    var params = {
      url: "scripts/run/abortRun",
      data: { objectId: self.currentScriptId },
    };
    self.httpAdapter.httpPost(params, function (err, res) {
      if (err) {
        // console.log("error running script===>", err)
      } else {
        // self.scriptDeatils['scriptRunStatus'] = "ABORTED";
        self.sharedData["updateRunList"] = true;
        self.updateRunDetails(self.scriptDeatils);
        // console.log("script run success")
      }
    });
  }

  public deleteScript() {
    const self = this;
    var deletingId = self.currentScriptId;
    var params = {
      url: "scripts/deleteScripts",
      data: { objectId: self.currentScriptId, requested: "run" },
    };

    self.httpAdapter.httpPost(params, function (err, res) {
      if (err) {
        // console.log("error running script===>", err)
      } else {
        const params = {
          system: self.selectedSystem,
          url: "scripts/run/listScripts",
        };
        self.httpAdapter.httpPost(params, function (err, res) {
          if (err) {
            // console.log(err)
          } else {
            self.sharedData["listRunScripts"] = [...res.body];
            self.CurrentConfForm = null;
            self.currentScriptId = null;
            self.sharedServices.changeData(self.sharedData);

            // If the user is trying to delete a config from ui this function will trigger the sharedServices
            if (self.sharedData["listRunScripts"].length >= 1) {
              self.sharedData["socket"]["SocketCheckInfo"] = true;
              self.sharedData["socket"]["SocketlogInfo"][
                "moveToGivenRunScript"
              ] = self.sharedData["listRunScripts"][0];
              self.sharedServices.changeData(self.sharedData);
            }
            new AppComponent(self._snackBar).showSnackBar(
              "Config deleted",
              "success"
            );
            // once the user is deleting the config from an account, Same account loged by different enduser will also notifyedf by this functionEmiting deleteConfig ID
            self.socket.emitingFunction("deleteConfig", {
              deleteStatus: true,
              _id: deletingId,
            });
          }
        });
      }
    });
  }

  public validateConfForRun() {
    var self = this;
    var confData = self.CurrentConfForm.value;
    if (confData["LOAD"] == undefined || _.isEmpty(confData["LOAD"])) {
      return false;
    }

    if (confData["LOAD"]["PORJECTPATH"] === "") {
      return false;
    }

    return false;
  }

  public getJenkinsCrumb() {
    var self = this;
    var crubParams = {
      url: "getJenkinsCrumbDetails",
    };
    self.httpAdapter.httpGet(crubParams, function (err, res) {
      if (err) {
        // console.log(err)
      } else {
        self.sharedData["CrumbDetailsForJenkins"] = res.body;
        self.CrumbDetailsForJenkins = { ...res.body };
        self.sharedServices.changeData(self.sharedData);
      }
    });
  }

  public confChanged(data) {
    // console.log("Changed conf details in run component===>", data)
    this.scriptConf = { ...data };
  }

  public updateRunDetails(script) {
    var self = this;
    var params = {
      url: "scripts/run/updateRunDetails",
      data: script,
    };

    self.httpAdapter.httpPost(params, function (updateErr, updateRes) {
      if (updateErr) {
        console.log(updateErr);
      } else {
        self.sharedData["updateRunList"] = true;
        self.sharedServices.changeData(self.sharedData);
      }
    });
  }

  /**
   *
   * The Function validateSaveAndRunConfig is used to validate the Config, Save it to the Database and then run it.
   *
   * @Params runConfigStatus
   * runConfigStatus Parameter is used as flag to check if the config needs to be trigerred or not.
   * If runConfigStatus is set to true, the function will run the Config else it will just validate and Save the Config
   *
   */
  public validateSaveAndRunConfig(runConfigStatus) {
    var self = this;
    let runStatus = runConfigStatus;
    //Assigning the value of Input File name to the RUN Id at the time of Sending the Conf to the Server
    if (self.confChangeStatus.status) {
      document.getElementById("head").scrollIntoView({ behavior: "smooth" });
      self.errormessage = true;
      setTimeout(() => {
        self.errormessage = false;
      }, 5000);
      return;
    }

    if (self.CurrentConfForm.value["SCRIPT"]["MSG_FILES"].length > 0) {
      self.CurrentConfForm.value["GENERAL"]["RUNID"] =
        self.CurrentConfForm.value["SCRIPT"]["MSG_FILES"][0]["NAME"];
    } else {
      self.CurrentConfForm.value["GENERAL"]["RUNID"] = "";
    }

    if (self.CurrentConfForm.invalid) {
      // window.alert('Check the error while Validating the form')
      self.dialog.open(DialogueComponent, {
        direction: "ltr",
        minWidth: "350px",
        panelClass: "my-alert-message-class",
        position: { top: "10px" },
        data: {
          Title: "Error Message",
          Content: "Check the error while Validating the form",
        },
      });
    } else {
      var params = {
        url: "scripts/run/validateConf",
        system: self.selectedSystem,
        data: {
          _id: self.currentScriptId,

          conf: { ...self.CurrentConfForm.value },
        },
      };

      self.httpAdapter.httpPost(params, function (err, result) {
        if (!err) {
          // {"fileStatus":false,"errors":["instance.LOAD.SYSTEMPASS does not meet minimum length of 1"]}
          console.log("result -->", result);
          if (result.body.isValidFile) {
            var productStatus = result.body.productStatus
              .toString()
              .split(",")
              .join("\n");
            self.scriptDeatils["conf"] = params.data.conf;
            self.sharedData["currentRunScript"]["conf"] = params.data.conf;
            self.sharedData["listRunScripts"][self.currentScriptIndex]["conf"] =
              params.data.conf;
            self.sharedServices.changeData(self.sharedData);
            /**
             *
             * @Changes The Change is made as some users forget to save the Config before running it.
             *
             * Checking the status for runScript
             * If runScript status is set to true while calling the function, along with validation , the execution/run will be trigerred.
             * Validation of the Config is done at the first stage and then based on the runScript Status the Config will be executed
             * If runStatus variable is set to false, the validation alone happens
             */

            if (runStatus) {
              // // console.log('Running the Script---->');
              self.runScript();
            } else {
              // window.alert(`Updation successfull\n${productStatus}`);
              self.dialog.open(DialogueComponent, {
                direction: "ltr",
                minWidth: "350px",
                panelClass: "my-alert-message-class",
                position: { top: "10px" },
                data: {
                  Title: "Updation successfull",
                  Content: `${productStatus}`,
                },
              });
              return true;
            }
          } else {
            var data = result.body.errors.join("\n");
            console.log("data", data);
            const showDialogue = self.dialog.open(DialogueComponent, {
              direction: "ltr",
              minWidth: "350px",
              panelClass: "my-alert-message-class",
              position: { top: "10px" },
              data: {
                Title: "Error Message",
                Content: "Invalid Configuration",
              },
            });
            showDialogue.afterClosed().subscribe((result) => {
              console.log(`Dialog result: ${result}`);
              self.dialog.open(DialogueComponent, {
                direction: "ltr",
                minWidth: "350px",
                position: { top: "10px" },
                panelClass: "my-alert-message-info-class",
                data: { Title: "Error Message", Content: data },
              });
            });

            // window.alert("Invalid Configuration")
            // window.alert(result.body.errors.join("\n"))
            return false;
          }
        } else {
          // console.log("Error while updating config")
          return false;
        }
      });
    }
  }

  messageshare() {
    var self = this;

    self.GENERALNewKeys =
      self.SCRIPTNewKeys =
      self.PHASESNewKeys =
      self.TRACEDEFNewKeys =
      self.TRACE_OVERRIDENewKeys =
      self.LOADNewKeys =
        {};
    if (
      !self.confChangeStatus.status &&
      self.confChangeStatus.newKeys.length > 0
    ) {
      if (self.confChangeStatus.newKeys[0]["added"]) {
        Object.keys(self.confChangeStatus.newKeys[0]["added"]).map((value) => {
          console.log("value", value);

          switch (value) {
            case "GENERAL":
              self.GENERALNewKeys =
                self.confChangeStatus.newKeys[0]["added"][value];
              break;
            case "SCRIPT":
              self.SCRIPTNewKeys =
                self.confChangeStatus.newKeys[0]["added"][value];
              break;
            case "PHASES":
              self.PHASESNewKeys =
                self.confChangeStatus.newKeys[0]["added"][value];
              break;
            case "TRACEDEF":
              self.TRACEDEFNewKeys =
                self.confChangeStatus.newKeys[0]["added"][value];
              break;
            case "TRACE_OVERRIDE":
              self.TRACE_OVERRIDENewKeys =
                self.confChangeStatus.newKeys[0]["added"][value];
              break;
            case "LOAD":
              self.LOADNewKeys =
                self.confChangeStatus.newKeys[0]["added"][value];
              break;

            default:
              break;
          }
        });
      }
    }
  }

  buildConfForm() {
    const self = this;
    self.CurrentConfForm = self.fb.group({ ...self.scriptConf });
    self.messageshare();
    var Phase1Control = self.CurrentConfForm.get("PHASES")
      .get("PHASE1")
      .get("VPARS_NAME");
    var Phase2Control = self.CurrentConfForm.get("PHASES")
      .get("PHASE2")
      .get("VPARS_NAME");
    console.log(
      'self.sharedData["vpars"] buildConfForm',
      self.sharedData["vpars"]
    );
    Phase1Control.setValidators([
      Validators.required,
      ValidatePhases(self.sharedData["vpars"]),
    ]);
    Phase1Control.updateValueAndValidity();
    Phase2Control.setValidators([
      Validators.required,
      ValidatePhases(self.sharedData["vpars"]),
    ]);
    Phase2Control.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    /**
     * unsubscribe Observables that have been subscribed - (PM-147)
     */
    this.subscription.unsubscribe();
  }
}
