<div class="summaryViewContainer" *ngIf="summaryReport != undefined">
  <div
    *ngIf="
      summaryReport != [] &&
      summaryReport['runStatus'] != undefined &&
      summaryReport['runStatus'] != {}
    "
    class="overviewSummary"
  >
    <table style="width: 90%; margin-bottom: 0" class="mt-0">
      <tr class="row">
        <td class="col dataColumn">
          <div
            *ngIf="summaryReport['runStatus']['phase1']"
            class="totalScripts"
          >
            <span style="font-weight: bold">PHASE 1</span>
          </div>
        </td>
        <td class="col dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase1'] &&
              summaryReport['runStatus']['phase1']['count']
            "
            class="phaseOneValue"
          >
            <span
              class="phaseOneValueSpan"
              *ngIf="
                summaryReport['runStatus']['phase2'] &&
                  summaryReport['runStatus']['phase2']['count'];
                else ph1ElseBlockCount
              "
              [ngStyle]="{
                color:
                  summaryReport['runStatus']['phase1']['count'] >
                  summaryReport['runStatus']['phase2']['count']
                    ? '#282A3C'
                    : '#999999'
              }"
            >
              {{ summaryReport["runStatus"]["phase1"]["count"] }}</span
            >
            <ng-template #ph1ElseBlockCount>
              <span class="phaseOneValueSpan" style="color: #999999">
                {{ summaryReport["runStatus"]["phase1"]["count"] }}</span
              >
            </ng-template>
          </div>
        </td>
        <td class="col dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase1'] &&
              summaryReport['runStatus']['phase1']['startTime']
            "
            class="PhaseTimings"
          >
            <span class="phaseOneStartTime"
              >{{
                summaryReport["runStatus"]["phase1"]["startTime"]
                  | date : "HH:mm:ss"
              }}
              {{
                summaryReport["runStatus"]["phase1"]["startTime"]
                  | date : "dd MMM yyyy"
              }}</span
            >
          </div>
        </td>
        <td class="col dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase1'] &&
              summaryReport['runStatus']['phase1']['endTime']
            "
            class="PhaseTimings"
          >
            <span class="phaseOneEndTime"
              >{{
                summaryReport["runStatus"]["phase1"]["endTime"]
                  | date : "HH:mm:ss"
              }}
              {{
                summaryReport["runStatus"]["phase1"]["endTime"]
                  | date : "dd MMM yyyy"
              }}</span
            >
          </div>
        </td>
        <td class="col dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase1'] &&
              summaryReport['runStatus']['phase1']['runTime'] != undefined
            "
            class="PhaseTimings"
          >
            <span
              class="phaseOneRunTimeValue"
              *ngIf="
                summaryReport['runStatus']['phase2'] &&
                  summaryReport['runStatus']['phase2']['runTime'] != undefined;
                else ph1ElseBlockrunTime
              "
              [ngStyle]="{
                color:
                  summaryReport['runStatus']['phase1']['runTime'] >
                  summaryReport['runStatus']['phase2']['runTime']
                    ? '#282A3C'
                    : '#999999'
              }"
            >
              {{
                summaryReport["runStatus"]["phase1"]["runTime"] | msConvertor
              }}</span
            >

            <ng-template #ph1ElseBlockrunTime>
              <span class="phaseOneRunTimeValue" style="color: #999999">
                {{
                  summaryReport["runStatus"]["phase1"]["runTime"] | msConvertor
                }}</span
              >
            </ng-template>
          </div>
        </td>
      </tr>
      <tr class="row">
        <td class="col"></td>
        <td class="col title">
          <div class="totalScripts">
            <span class="mt-1">TOTAL TRANSACTIONS</span>
          </div>
        </td>
        <td class="col title">
          <div class="totalScripts">
            <span class="mt-1">START TIME</span>
          </div>
        </td>
        <td class="col title">
          <div class="totalScripts">
            <span class="mt-1">END TIME</span>
          </div>
        </td>
        <td class="col title">
          <div class="totalScripts">
            <span class="mt-1">RUN TIME</span>
          </div>
        </td>
      </tr>
      <tr class="row">
        <td class="col dataColumn">
          <div
            *ngIf="summaryReport['runStatus']['phase2']"
            class="totalScripts"
          >
            <span style="font-weight: bold">PHASE 2</span>
          </div>
        </td>
        <!-- <td class="col  dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase2'] &&
              summaryReport['runStatus']['phase2']['count']
            "
            class="phaseTwoValue"
          >
            <span
              *ngIf="
                summaryReport['runStatus']['phase1'] &&
                  summaryReport['runStatus']['phase1']['count'];
                else ph2ElseBlockCount
              "
              class="phaseTwoValueSpan"
              [ngStyle]="{
                color:
                  summaryReport['runStatus']['phase1']['count'] <
                  summaryReport['runStatus']['phase2']['count']
                    ? '#282A3C'
                    : '#999999'
              }"
            >
              {{ summaryReport["runStatus"]["phase2"]["count"] }}</span
            >
            <ng-template #ph2ElseBlockCount>
              <span class="phaseTwoValueSpan" style="color: #999999">
                {{ summaryReport["runStatus"]["phase2"]["count"] }}</span
              >
            </ng-template>
          </div>
        </td> -->
        <td class="col dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase2'] &&
              summaryReport['runStatus']['phase2']['count']
            "
            class="phaseOneValue"
          >
            <span
              class="phaseOneValueSpan"
              *ngIf="
                summaryReport['runStatus']['phase2'] &&
                  summaryReport['runStatus']['phase2']['count'];
                else ph1ElseBlockCount
              "
              [ngStyle]="{
                color:
                  summaryReport['runStatus']['phase1'] &&
                  summaryReport['runStatus']['phase1']['count'] >
                    summaryReport['runStatus']['phase2']['count']
                    ? '#282A3C'
                    : '#999999'
              }"
            >
              {{ summaryReport["runStatus"]["phase2"]["count"] }}</span
            >
            <ng-template #ph1ElseBlockCount>
              <span class="phaseOneValueSpan" style="color: #999999">
                {{ summaryReport["runStatus"]["phase2"]["count"] }}</span
              >
            </ng-template>
          </div>
        </td>
        <td class="col dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase2'] &&
              summaryReport['runStatus']['phase2']['startTime']
            "
            class="PhaseTimings"
          >
            <span class="phaseTwoStartTime"
              >{{
                summaryReport["runStatus"]["phase2"]["startTime"]
                  | date : "HH:mm:ss"
              }}
              {{
                summaryReport["runStatus"]["phase2"]["startTime"]
                  | date : "dd MMM yyyy"
              }}</span
            >
          </div>
        </td>
        <td class="col dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase2'] &&
              summaryReport['runStatus']['phase2']['endTime']
            "
            class="PhaseTimings"
          >
            <span class="phaseTwoEndTime"
              >{{
                summaryReport["runStatus"]["phase2"]["endTime"]
                  | date : "HH:mm:ss"
              }}
              {{
                summaryReport["runStatus"]["phase2"]["endTime"]
                  | date : "dd MMM yyyy"
              }}</span
            >
          </div>
        </td>
        <td class="col dataColumn">
          <div
            *ngIf="
              summaryReport['runStatus']['phase2'] &&
              summaryReport['runStatus']['phase2']['runTime'] != undefined
            "
            class="PhaseTimings"
          >
            <span
              class="phaseTwoRunTimeValue"
              *ngIf="
                summaryReport['runStatus']['phase1'] &&
                  summaryReport['runStatus']['phase1']['runTime'] != undefined;
                else ph2ElseBlockrunTime
              "
              [ngStyle]="{
                color:
                  summaryReport['runStatus']['phase1'] &&
                  summaryReport['runStatus']['phase1']['runTime'] <
                    summaryReport['runStatus']['phase2']['runTime']
                    ? '#282A3C'
                    : '#999999'
              }"
            >
              {{
                summaryReport["runStatus"]["phase2"]["runTime"] | msConvertor
              }}</span
            >

            <ng-template #ph2ElseBlockrunTime>
              <span class="phaseOneRunTimeValue" style="color: #999999">
                {{
                  summaryReport["runStatus"]["phase2"]["runTime"] | msConvertor
                }}</span
              >
            </ng-template>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="divSpacing"></div>
  <div class="dropdownOption">
    <div class="show mr-2 float-right">
      <!-- <button class="btn  dropdown-toggle AllButton pl-3 pr-3" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>
                    {{dropDownHeading.name | uppercase}}
                </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" *ngFor="let scriptName of filterScript" (click)="filterMethod(scriptName)"
                    [ngClass]="{'nonsubScriptStyle': scriptName.subScript === false,'subScriptStyle': scriptName.subScript === true}">{{scriptName.scriptName
                    | uppercase}}</a>
                <a class="dropdown-item" *ngIf="dropDownHeading.name !='All'"
                    (click)="filterMethod({scriptName:'All',count:0})"> ALL</a>
            </div> -->
      <form
        class="matDropdown"
        *ngIf="
          subScriptData.length > 0 &&
          summaryReport['summaryReport'] != undefined &&
          summaryReport['summaryReport'].length > 0
        "
      >
        <mat-form-field
          class="example-full-width matDropdown"
          appearance="fill"
        >
          <mat-label>Filter</mat-label>
          <input
            type="text"
            placeholder="Pick one"
            aria-label="Filter"
            matInput
            [formControl]="myControl"
            (input)="myControl.setValue(myControl.value.toUpperCase())"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              class="nonsubScriptStyle"
              [value]="'ALL'"
              (click)="filterMethodNew('ALL', false)"
              ><span style="font-weight: 500">ALL</span></mat-option
            >
            <ng-container
              *ngFor="let script of filteredOptions | async; trackBy: trackByFn"
            >
              <mat-option
                *ngIf="script.firstFileNameOccurrence"
                [value]="script.FileName"
                (click)="filterMethodNew(script, false)"
              >
                <span
                  class="nonsubScriptStyle"
                  [ngClass]="{
                    nonsubScriptStyle: script.subScript === false,
                    subScriptStyle: script.subScript === true
                  }"
                  >{{ script.FileName }}</span
                >
              </mat-option>
              <mat-option
                *ngIf="
                  script.firstSubScriptNameOccurrence &&
                  script.subScript != 'No Subscript'
                "
                [value]="script.subScript"
                (click)="filterMethodNew(script, true)"
              >
                <span class="subScriptStyle">{{ script.subScript }}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div
    class="detailedSummary"
    *ngIf="
      summaryReport['summaryReport'] != undefined &&
      summaryReport['summaryReport'].length > 0
    "
  >
    <div class="reportOverview">
      <ng-container *ngFor="let fields of summaryReportKeys">
        <div
          class="programsDisplay"
          [ngClass]="{ active: fields == currentFeild }"
          (click)="getPGMORMACData(fields)"
        >
          <!--             filterMethod({ scriptName: 'ALL', count: 0 });
 -->
          <div class="overviewMenuName">
            <span>{{ fields.field }}</span>
          </div>
          <div class="pgmPhaseInfo">
            <div class="overviewPh1Details">
              <span class="overviewPh1Name">PH1</span>
              <span class="overviewPh1DisplayValue">{{
                summaryReport["summaryReport"][0][fields["PH1"]]
              }}</span>
            </div>
            <div class="lineDivide"></div>
            <div class="overviewPh2Details">
              <span class="overviewPh2Name">PH2</span>
              <span class="overviewPh2DisplayValue">{{
                summaryReport["summaryReport"][0][fields["PH2"]]
              }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="summaryDetailedReport">
      <table
        mat-table
        #summarySort="matSort"
        matSort
        [dataSource]="detailedReport"
        class="detailedSummaryReport"
        (matSortChange)="sortSummaryData($event)"
      >
        <ng-container matColumnDef="{{ currentFeild['keys'][0] }}">
          <th
            mat-header-cell
            class="menuType"
            mat-sort-header="{{ currentFeild['keys'][0] }}"
            *matHeaderCellDef
          >
            {{ fieldName == "Programs" ? "PROGRAM" : "MACRO" }}
          </th>
          <td id="program" mat-cell class="pgmValue" *matCellDef="let data">
            {{ data[currentFeild["keys"][0]] }}
          </td>
        </ng-container>

        <ng-container matColumnDef="PH1">
          <th
            mat-header-cell
            class="summaryPh1Name"
            mat-sort-header="PH1"
            *matHeaderCellDef
          >
            PHASE 1
          </th>
          <td mat-cell class="summaryPh1Value" *matCellDef="let data">
            {{ data["PH1"] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="PH2">
          <th
            mat-header-cell
            class="summaryPh2Name"
            mat-sort-header="PH2"
            *matHeaderCellDef
          >
            PHASE 2
          </th>
          <td mat-cell class="summaryPh2value" *matCellDef="let data">
            {{ data["PH2"] }}
          </td>
        </ng-container>
        <!-- PD-60 (Changes have been added below)-->
        <ng-container matColumnDef="Difference">
          <th
            mat-header-cell
            class="summaryDiffName"
            mat-sort-header="Difference"
            *matHeaderCellDef
          >
            TOTAL DIFF
          </th>
          <td mat-cell class="summaryDiffValue" *matCellDef="let data">
            {{ data["Difference"] }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Percentage">
          <th
            mat-header-cell
            class="summaryPercentageName"
            mat-sort-header="Percentage"
            *matHeaderCellDef
          >
            TOTAL(%)
          </th>
          <td
            mat-cell
            class="summaryPercentValue"
            *matCellDef="let data; let i = index"
          >
            {{ data["Percentage"] }}%
          </td></ng-container
        >

        <ng-container matColumnDef="TransactionDiff">
          <th
            mat-header-cell
            class="summaryPercentageName"
            mat-sort-header="TransactionDiff"
            *matHeaderCellDef
          >
            TRANS DIFF
          </th>
          <td
            mat-cell
            class="summaryPercentValue"
            *matCellDef="let data; let i = index"
          >
            {{ data["TransactionDiff"] }}
          </td></ng-container
        >

        <ng-container matColumnDef="TransactionPercentage">
          <th
            mat-header-cell
            class="summaryTransactionPercentage"
            mat-sort-header="TransactionPercentage"
            *matHeaderCellDef
          >
            TRANS(%)
          </th>
          <td
            mat-cell
            id="TransactionPercentage"
            class="summaryPercentValue"
            *matCellDef="let data; let i = index"
          >
            {{ data["TransactionPercentage"] }}%<button
              *ngIf="currentRow == data"
              class="detailsButton"
              style="float: right"
              (click)="getTransactionDetails(data, i)"
            >
              DETAILS
            </button>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displaySummaryColumns; sticky: true"
        ></tr>

        <tr
          mat-row
          class="summaryRowDetails"
          *matRowDef="let row; columns: displaySummaryColumns; let i = index"
          [ngClass]="{ highlightSummary: currentRow == row }"
          (click)="highlight(row)"
          id="{{ row.TransactionDiff != 0 ? 'TransactionDiff' : 'TableRow' }}"
          tabindex="999"
          (keydown.arrowdown)="arrowDownEvent(row)"
          (keydown.arrowup)="arrowUpEvent(row)"
        >
          {{
            i
          }}
        </tr>
      </table>
    </div>
  </div>
</div>

<div
  class="transactionOverlay"
  [hidden]="isShow"
  *ngIf="transactionData['data'].length > 0"
>
  <div class="transactions">
    <div class="selectedMenu">
      {{ currentRow[currentFeild["keys"][0]] }}

      <mat-icon
        (click)="close()"
        style="float: right; margin-right: 28px; color: #58595a"
        >clear</mat-icon
      >

      <button
        *ngIf="selectedProgram['TransactionDiff'] != 0"
        mat-mini-fab
        aria-label="Example icon button with a home icon"
        class="download"
        (click)="downloadScriptDiff(transactionData['data'])"
      >
        <mat-icon>download</mat-icon>
      </button>
    </div>
    <div class="transactionView">
      <table
        mat-table
        [dataSource]="transactionData['data']"
        #transactionSort="matSort"
        matSort
        class="transactionTable"
        (matSortChange)="sortTransactionData($event)"
      >
        <ng-container matColumnDef="TRANSACTION">
          <th mat-header-cell class="transName" *matHeaderCellDef>
            TRANSACTION
          </th>
          <td mat-cell class="transValue" *matCellDef="let element">
            <div class="transApiPath" (click)="expandInputView()">
              {{ element.apiPath }}
            </div>
            <div class="viewTransInput">{{ element.TRANSACTION }}</div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="PHASE 1">
          <th mat-header-cell class="transPh1Name" *matHeaderCellDef>
            PHASE 1
          </th>
          <td mat-cell class="transPh1Value" *matCellDef="let element">
            {{ element.PH1 }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="PHASE 2">
          <th mat-header-cell class="transPh2Name" *matHeaderCellDef>
            PHASE 2
          </th>
          <td mat-cell class="transPh2Value" *matCellDef="let element">
            {{ element.PH2 }}
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="DIFF">
          <th mat-header-cell class="transDiff" *matHeaderCellDef>DIFF</th>
          <td mat-cell class="transDiffValue" *matCellDef="let element">
            {{ element.Difference }}
          </td>
        </ng-container>

        <ng-container matColumnDef="PERCENTAGE">
          <th
            mat-header-cell
            class="transPercentage"
            mat-sort-header="Percentage"
            *matHeaderCellDef
          >
            PERCENTAGE
          </th>
          <td mat-cell class="transPercentageValue" *matCellDef="let element">
            {{ element.Percentage }}%
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          style="overflow: auto"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </div>
</div>
