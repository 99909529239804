<h2 mat-dialog-title>iRTF File -- {{ data["value"]["irtfFileName"] }}</h2>

<mat-dialog-content class="mat-typography" style="border-top: 1px solid black">
  <!-- Added normal bootstap container to display transaction input instead of quill-editor (PM-157) -->
  <div class="container irtf-container">
    <div class="row m-0">
      <table
        class="table table-striped mt-3 mb-1 p-3 rounded-0 border border-muted inputContainer"
      >
        <thead class="thead-dark">
          <tr style="position: sticky; top: 0; background-color: white">
            <td scope="col">S.No</td>
            <td scope="col">Identifier Position in MO</td>
            <td scope="col">Replacement String Starting Position in MO</td>
            <td scope="col">Replacement String Length in MO</td>
            <td scope="col">Identifier in MI</td>
            <td scope="col">Replacement of MI Identifier</td>
            <td scope="col">MI Position to be replaced</td>
            <td scope="col">Identifier in MO</td>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let transaction of data['value']['transactions'];
              let i = index
            "
          >
            <!-- {{transaction | json}} -->
            <td scope="row" class="text-muted restrictToCopy">{{ i + 1 }}</td>
            <td>{{ transaction["identifierPos"] }}</td>
            <td>{{ transaction["replacingPos"] }}</td>
            <td>{{ transaction["replacingLength"] }}</td>
            <td>{{ transaction["inputParameter"] }}</td>
            <td style="white-space: pre">
              {{ transaction["replacingInputParameter"] }}
            </td>
            <td>{{ transaction["inputPos"] }}</td>
            <td style="white-space: pre">{{ transaction["identifier"] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
