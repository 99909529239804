import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpAdapterService } from "src/app/_services/http-adapter.service";
import { saveAs } from "file-saver";
import { DialogueComponent } from "src/app/errorPages/dialogue/dialogue.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-connect-linux",
  templateUrl: "./connect-linux.component.html",
  styleUrls: ["./connect-linux.component.scss"],
})
export class ConnectLinuxComponent {
  @Input() logs;
  @Input() selectedSystem;
  myForm: FormGroup;
  hide = true;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private httpAdapter: HttpAdapterService
  ) {
    this.myForm = this.fb.group({
      loadFilePath: ["", Validators.required],
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  togglePasswordVisibility() {
    this.hide = !this.hide;
  }

  get isButtonDisabled() {
    return this.myForm.invalid;
  }

  get buttonClasses() {
    return {
      submitButton: !this.isButtonDisabled,
      submitButtonDisabled: this.isButtonDisabled,
    };
  }

  onSubmit() {
    const self = this;
    if (self.myForm.valid) {
      const formData = self.myForm.value;
      const params = {
        url: `reports/validateLinux`,
        data: {
          linuxDetails: formData,
        },
        system: self.selectedSystem,
      };

      self.httpAdapter.httpPostNew(params).subscribe(
        (res) => {
          if (res.error) {
            self.dialog.open(DialogueComponent, {
              direction: "ltr",
              minWidth: "350px",
              position: { top: "10px" },
              data: {
                Title: "Error",
                Content: res.msg,
              },
            });
          } else {
            self.dialog.open(DialogueComponent, {
              direction: "ltr",
              minWidth: "350px",
              position: { top: "10px" },
              data: {
                Title: "Success",
                Content:
                  "Connected to Linux, Please wait for File to Download!",
              },
            });
            self.downloadTestPercent(res.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      console.log("Form is invalid");
    }
  }

  downloadTestPercent(loadedPrograms) {
    const self = this;
    const scriptIds = self.logs.map((log) => log.scriptId);

    const params = {
      url: `reports/plog`,
      data: {
        ids: scriptIds,
        details: "COMPLETED",
        isPlog: false,
        loadedPrograms: loadedPrograms,
      },
      system: self.selectedSystem,
    };

    self.httpAdapter.httpPostFileGet(params, (err, result) => {
      if (!err) {
        const today = new Date();
        const blob = new Blob([result], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(
          blob,
          `TestPercent__${today.getDate()}--${today.toLocaleString("default", {
            month: "long",
          })}--${today.getFullYear()}--${today.getHours()}HH--${today.getMinutes()}`
        );
      }
    });
  }
}
