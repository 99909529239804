import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-traceoverride',
  templateUrl: './traceoverride.component.html',
  styleUrls: ['./traceoverride.component.scss']
})
export class TraceoverrideComponent implements OnInit {
  // public _conf ={};
  @Input() conf
  @Input() validator;
  // @Output() changedConf = new EventEmitter();
  // public conf: FormGroup
  public newKeys = [];
  /**
    * Below function is to collect the newly added forms in an arry
    */
  @Input() public set newKeysAdded(val: string) {
    if (val) {
      this.newKeys = [];
      Object.keys(val).map(value => {
        this.newKeys.push(value)
      })
    }
  }
  constructor(private fb: FormBuilder) {
    // this.changedConf = new EventEmitter();
    // this.TRACE_OVERRIDE = this.fb.group({
    //   "MACRO": this.fb.array([this.createTraceObj()]),
    //   "SO":this.fb.array([this.createTraceObj()])
    // })
  }

  ngOnInit() {
    // this.onChanges();
  }
  ngOnChanges(changes) {
    if (this.conf.get("MACRONUM").value == 0) {
      this.MACRO.reset()
      // this.emptyMACROS();
    }
    if (this.conf.get("SONUM") == 0) {
      this.SO.reset()
    }
  }

  // ngOnChanges(changes) {
  //   if(JSON.stringify(changes.conf.previousValue)!=JSON.stringify(this._conf)){
  //     var trcOvr = this._conf["TRACE_OVERRIDE"];

  //     this.emptyMACROS();
  //     this.emptySO();
  //     console.log( trcOvr["MACRO"] )
  //     this.TRACE_OVERRIDE.patchValue(trcOvr)
  //     if(trcOvr["MACRO"]){
  //       for (const item of trcOvr.MACRO) {
  //         this.MACRO.push(this.createTraceObjWithValue(item));
  //       }
  //     }
  //     if(trcOvr["SO"]){
  //       for (const item of trcOvr.SO) {
  //         this.SO.push(this.createTraceObjWithValue(item));
  //       }
  //     }
  //   } 

  //   // console.log(this.TRACE_OVERRIDE)
  // }

  // onChanges(){
  //   this.TRACE_OVERRIDE.valueChanges
  //   .pipe(
  //     debounceTime(1000),
  //     distinctUntilChanged(),
  //   )
  //   .subscribe(changedValue=>{
  //     // console.log("changed in trace overRide",changedValue)
  //     this._conf["TRACE_OVERRIDE"] = {...this._conf["TRACE_OVERRIDE"],...this.TRACE_OVERRIDE.value}
  //     this._conf["TRACE_OVERRIDE"]["MACRONUM"] = this.MACRO.length;
  //     this._conf["TRACE_OVERRIDE"]["SONUM"] = this.SO.length;
  //     // this.changedConf.emit(this._conf)
  //   })

  // }

  createTraceObj() {
    return this.fb.group({
      "NAME": this.fb.control(''),
      "OPTION": this.fb.control(true)
    })
  }
  createTraceObjWithValue(value) {
    return this.fb.group({
      "NAME": this.fb.control(value.NAME),
      "OPTION": this.fb.control(value.OPTION)
    })
  }
  get MACRO() {
    return this.conf.get('MACRO') as FormArray;
  }

  addTrcMacro() {
    this.MACRO.push(this.createTraceObj())
  }
  get SO() {
    return this.conf.get('SO') as FormArray;
  }
  emptyMACROS() {
    while (this.MACRO.controls.length > 0) {
      this.MACRO.removeAt(0);
    }

  }
  emptySO() {
    while (this.SO.controls.length > 0) {
      this.SO.removeAt(0);
    }

  }
  addTrcSO() {
    this.SO.push(this.createTraceObj())
  }

  removeMacro(index) {
    this.MACRO.removeAt(index)
  }
  removeSO(index) {
    this.SO.removeAt(index)
  }
}
