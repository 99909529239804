import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ScriptsComponent } from './scripts.component';
import { SharedModule } from '../_services/shared.module';
import { ShareDataService } from '../_services/share-data.service';



const routes: Routes = [{
  path: '', component: ScriptsComponent, children: [
    { path: '', redirectTo: "runScripts", pathMatch: 'full' },
    { path: 'validate/:id', loadChildren: () => import('./../validate-view/validate-view.module').then(m => m.ValidateViewModule) },
    { path: 'runScripts', loadChildren: () => import('./../run-view/run-view.module').then(m => m.RunViewModule) },
    // { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
    // { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule) },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScriptsRoutingModule { }
