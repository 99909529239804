<div class="maincontainer">
  <div class="aside">
    <div class="logo">
      <div
        class="logoDetails"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <img src="assets/Images/logo.png" class="logo-Img" />

        <span class="logotxt">EFT</span>
      </div>
    </div>
    <div
      style="
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <select
        class="platformDetail"
        [(ngModel)]="selectedSystem"
        (ngModelChange)="onSystemChange()"
      >
        <option value="" disabled selected>Select System</option>
        <option *ngFor="let system of systems" [value]="system">
          {{ system }}
        </option>
      </select>
    </div>
    <div class="gridNav">
      <ul class="mainNav">
        <!-- <li class="navRoutes">
          <a>
            <p>Dashboard</p>
          </a>
        </li> -->
        <!-- <li class="navRoutes"><a style="font-weight: bold; font-family: UniNeue;">DASHBOARD</a></li>
                <li class="navRoutes active" routerLinkActive="active"
                    routerLink="/scripts"><a><span style="font-weight: bold; font-family: UniNeue;"> Live</span></a>
                </li>
                <li class="navRoutes" routerLinkActive="active"
                    routerLink="/report"><a><span style="font-weight: bold; font-family: UniNeue;"> Reports</span></a>
                </li> -->
        <a
          *ngFor="let menuItem of menuItems"
          [routerLinkActive]=""
          [ngClass]="rla.isActive ? 'active' : ''"
          #rla="routerLinkActive"
          [routerLink]="[menuItem.path]"
          class="navRoutes"
        >
          <!-- <i class="material-icons">{{menuItem.icon}}</i> -->
          <p class="p-2 m-0">{{ menuItem.title }}</p>
        </a>
      </ul>
      <!-- <nav class="navbar">
                    <ul class="navbar-nav">
                        <li class="nav-item active"> <a class="nav-link" href="#">Dashboard</a></li>
                        <li class="nav-item"><a class="nav-link" href="#">Script</a></li>
                        <li class="nav-item"><a class="nav-link" href="#">Report</a></li>
                    </ul>
                </nav> -->
    </div>

    <!-- <button class="logout" mat-flat-button (click)="logout()">
            <mat-icon>power_settings_new</mat-icon><span>Logout</span>
        </button> -->
    <div class="profileArea">
      <div class="logoutView" (click)="logout()">
        <div class="userDetails">
          <span class="userName">
            <mat-icon class="userIcon">person</mat-icon>
          </span>
        </div>
        <span class="logoutTitle">LOGOUT</span>
      </div>
    </div>
  </div>
  <div class="innerContainer">
    <router-outlet></router-outlet>
  </div>
</div>
