import { HttpClient } from "@angular/common/http";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpAdapterService } from "../_services/http-adapter.service";
import { ShareDataService } from "../_services/share-data.service";
import { SocketioClientServices } from "../_services/socketio-client.services";
import { AppComponent } from "../app.component";
@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit, OnDestroy {
  public sharedData: {};
  public subscription: any;
  selectedSystem: any;
  logs: any;
  constructor(
    private sharedServices: ShareDataService,
    private httpAdapter: HttpAdapterService
  ) { }

  ngOnInit() {
    this.socketForSystem();
  }

  socketForSystem() {
    this.subscription = this.sharedServices.currentData$.subscribe((data) => {
      if (
        data["selectedSystem"] &&
        this.selectedSystem != data["selectedSystem"]
      ) {
        this.selectedSystem = data["selectedSystem"];
        this.getLogs();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getLogs() {
    const params = {
      system: this.selectedSystem,
      url: "reports/showLogs",
      data: {},
    };
    this.httpAdapter.httpPost(params, (err, result) => {
      if (!err) {
        this.logs = result.body.data.map((item) => ({
          ...item,
          checked: false,
        }));
      } else {
        console.error("Error in executing API", err);
      }
    });
  }
}
