<mat-tab-group preserveContent>
  <mat-tab label="System">
    <div
      *ngIf="serverUtilizationInfo"
      class="container-fluid"
      style="height: 90vh; overflow: auto"
    >
      <div class="card m-2 px-2 py-4 row">
        <div class="col-12 row m-0 p-0">
          <div class="col-3">
            <app-utilization-card
              [value]="serverUtilizationInfo.memoryFree"
              [total]="serverUtilizationInfo.memoryTotal"
              icon="memory"
              label="Memory"
              brand="#e6e1b7"
              brand_color="white"
              socketInfo="memory"
              [GraphStatus]="true"
            ></app-utilization-card>
          </div>
          <div class="col-3">
            <app-utilization-card
              [value]="serverUtilizationInfo.storageFree"
              [total]="serverUtilizationInfo.storageTotal"
              label="Storage"
              icon="sd_storage"
              brand="#b669ea87"
              brand_color="white"
              socketInfo="storage"
              [GraphStatus]="true"
            ></app-utilization-card>
          </div>
          <div class="col-6 row m-0">
            <div class="col-6 mb-1">
              <app-utilization-card
                [value]="serverUtilizationInfo.process1"
                icon="import_export"
                label="RES Runs"
                brand="#e6e1b7"
                brand_color="black"
                [processesStatus]="true"
                socketInfo="childProcesssInitiated1"
                iconColor="#b669ea87"
              ></app-utilization-card>
            </div>
            <div class="col-6">
              <app-utilization-card
                [value]="serverUtilizationInfo.process2"
                icon="import_export"
                label="AIR4 Runs"
                brand="#b669ea87"
                brand_color="black"
                [processesStatus]="true"
                socketInfo="childProcesssInitiated2"
                iconColor="#e6e1b7"
              ></app-utilization-card>
            </div>
            <div class="col-6">
              <app-utilization-card
                [value]="serverUtilizationInfo.process3"
                icon="import_export"
                label="OSS Runs"
                brand="#b669ea87"
                brand_color="black"
                [processesStatus]="true"
                socketInfo="childProcesssInitiated3"
                iconColor="#e6e1b7"
              ></app-utilization-card>
            </div>
            <div class="col-6">
              <app-utilization-card
                [value]="serverUtilizationInfo.uptime"
                icon="access_time"
                label="Up Time"
                brand="#e6e1b7"
                brand_color="black"
                [processesStatus]="true"
                socketInfo="uptime"
                iconColor="#b669ea87"
              ></app-utilization-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <!-- <h5>Documents from the database</h5> -->
        <div class="card mb-2 col-12" *ngIf="dbDocsListchartOptions">
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="dbDocsListchartOptions"
            style="width: 100%; height: 50vh; display: block"
          ></highcharts-chart>
        </div>
      </div>
      <h4>
        Documents count in List view
        <button class="btn btn-none btn-sm" (click)="getServerUtilization()">
          <mat-icon class="mt-1">refresh</mat-icon>
        </button>
      </h4>
      <div class="col-12 row m-0">
        <ng-container *ngFor="let item of dbDocsList; let i = index">
          <div class="col-3 mb-2">
            <app-utilization-card
              [value]="item.value | numberFormatter"
              icon="storage"
              [label]="item.label | titlecase"
              [brand]="colorArray[i]"
              brand_color="white"
            ></app-utilization-card>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Scripts">
    <div class="container-fluid" style="height: 90vh; overflow: auto">
      <div class="card m-2 p-2 row">
        <div class="col-12 card-title row m-0">
          <h4 class="col-2" style="text-decoration: underline">User Group:</h4>
          <h4 class="col-2" style="text-decoration: underline">System:</h4>
          <h4 class="col-8" style="text-decoration: underline">Date Range:</h4>
          <div class="col-12 row m-0 mt-2">
            <div class="col-2">
              <select [(ngModel)]="selectedGroupName" class="form-control">
                <option value="" disabled selected>Select Group</option>
                <option *ngFor="let system of groupNames" [value]="system">
                  <h6 style="font-weight: bold">{{ system }}</h6>
                </option>
              </select>
            </div>
            <div class="col-2">
              <select [(ngModel)]="selectedSystem" class="form-control">
                <option value="" disabled selected>Select System</option>
                <option *ngFor="let system of systems" [value]="system">
                  <h6 style="font-weight: bold">{{ system }}</h6>
                </option>
              </select>
            </div>
            <div class="col-3">
              <input type="date" class="form-control" [(ngModel)]="fromDate" />
            </div>
            <div class="col-3">
              <input type="date" class="form-control" [(ngModel)]="toDate" />
            </div>
            <div class="col-2">
              <button
                [disabled]="!selectedSystem || !fromDate || !toDate"
                (click)="
                  this.getAllScriptsBasedOnUser();
                  this.getScriptsFromPlogByDateRange();
                  this.getScriptsNotFromPlogByDateRange();
                  this.getAllScriptsByDateRange();
                  this.getAllScriptsByStatusByDateRange()
                "
                class="btn btn-primary"
              >
                Generate
              </button>
            </div>
          </div>
        </div>

        <div class="row card-body">
          <div class="card col-12" *ngIf="userScriptsChartOption">
            <highcharts-chart
              [Highcharts]="Highcharts"
              #chartContainer
              [options]="userScriptsChartOption"
              style="width: 98%; height: 400px"
            ></highcharts-chart>
          </div>
          <div class="col-12 mt-2 row">
            <div class="card col-6">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="allScriptListChartOptions"
                style="width: 100%; height: 400px; display: block"
              ></highcharts-chart>
            </div>
            <div class="card col-6">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="allScriptListByStatusChartOptions"
                style="width: 100%; height: 400px; display: block"
              ></highcharts-chart>
            </div>
            <div class="card col-6">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="scriptFromPlogPieChartOptions"
                style="width: 100%; height: 400px; display: block"
              ></highcharts-chart>
            </div>
            <div class="card col-6">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="scriptNotFromPlogPieChartOptions"
                style="width: 100%; height: 400px; display: block"
              ></highcharts-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Users">
    <div class="container-fluid" style="height: 90vh; overflow: auto">
      <div class="card m-2 p-2 row">
        <div class="col-12 row m-0">
          <div
            class="col-12 row m-0"
            *ngFor="let item of userList; let i = index"
            [ngClass]="{ 'col-12': i == 0, 'col-6': i != 0 }"
          >
            <div class="m-2 col-12 card py-2">
              <div
                class="card-title bg-info rounded p-2 m-0"
                style="display: flex; align-items: center; color: white"
              >
                <mat-icon class="mr-2">account_circle</mat-icon>
                {{ item.groupName }} ({{ item.userList.length }})
              </div>
              <div class="row card-body p-2">
                <ng-container *ngFor="let user of item.userList">
                  <div
                    [ngClass]="{ 'col-2': i == 0, 'col-3': i != 0 }"
                    class="mt-2"
                  >
                    <app-usercard
                      [connectedUsersList]="connectedUsersList"
                      [userData]="user"
                    ></app-usercard>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h4>
            VPARS List and their runs
            <button class="btn btn-none btn-sm" (click)="getVPARSList()">
              <mat-icon class="mt-1">refresh</mat-icon>
            </button>
          </h4>
          <div class="col-12">
            <ng-container *ngFor="let item of vparsList">
              <div class="card p-3 mb-2">
                <h6>{{ item.systemName }}</h6>
                <div class="col-12 row m-0">
                  <ng-container
                    *ngFor="let vparsItem of item.vparsList; let i = index"
                  >
                    <div class="col-3 mb-2">
                      <!-- <p>{{ vparsItem.vparsName }}</p>
                  <p>{{ vparsItem.vparsName }}</p> -->
                      <app-utilization-card
                        [value]="
                          vparsItem.LniataTypes.length
                            ? vparsItem.LniataTypes[0].Lniatas.length
                            : 0
                        "
                        icon="desktop_windows"
                        [label]="vparsItem.vparsName | uppercase"
                        [brand]="colorArray[i]"
                        brand_color="white"
                      ></app-utilization-card>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
