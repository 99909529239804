import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";
import { MatTabsModule } from "@angular/material/tabs";
import { CommonModule } from "@angular/common";
import { UsercardComponent } from "../components/usercard/usercard.component";
import { MatCardModule } from "@angular/material/card";
import { NzModalModule } from "ng-zorro-antd/modal";
import { FormsModule } from "@angular/forms";
import { HighchartsChartModule } from "highcharts-angular";
import { UtilizationCardComponent } from "../components/utilization-card/utilization-card.component";
import { NumberFormatterPipe } from './number-formatter.pipe';

@NgModule({
  declarations: [
    DashboardComponent,
    UsercardComponent,
    UtilizationCardComponent,
    NumberFormatterPipe,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatTabsModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatCardModule,
    NzModalModule,
    FormsModule,
    HighchartsChartModule,
  ],
})
export class DashboardModule {}
