<div style="display: flex; justify-content: space-between; align-items: center">
  <h2 mat-dialog-title>Pre Load Log</h2>
</div>
<mat-dialog-content class="mat-typography" style="border-top: 1px solid black">
  <div class="mb-1 p-1 rounded-0 border border-muted">
    <cdk-virtual-scroll-viewport
      style="
        width: 40vw;
        max-width: 40vw;
        min-height: 55vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
      "
      [itemSize]=""
    >
      <div
        *cdkVirtualFor="
          let item of this.data['value'];
          let i = index;
          trackBy: trackByFn
        "
        class="text-dark m-0"
        style="
          font-weight: 500;
          font-size: 16px;
          /* white-space: nowrap; */
          overflow: visible;
        "
      >
        <span class="text-muted restrictTOcopy">{{ i + 1 }} </span>
        <span class="restrictTOcopy pl-1 pr-2"> | </span>

        {{ item }}
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
